import {
  Box,
  Center,
  Group,
  Navbar,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';

import { IconCircleDashed, IconPlus, IconStar } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../../../../shared/components/UI/Button/Button';
import { Loader } from '../../../../shared/components/UI/Loader/Loader';

import { useActiveUser } from '../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import { useStreamsContext } from '../../contexts/Streams.provider';
import { StreamSectionAccordion } from './StreamSectionAccordion';

type StreamsSidebarProps = {
  width: number;
};

export default function Sidebar({ width }: StreamsSidebarProps) {
  const { activeUser } = useActiveUser();
  const streamsContext = useStreamsContext();
  const { t } = useTranslation();

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const { sidebarContent } = streamsContext.data;
  return (
    <Navbar
      w={`${width}px`}
      py="02"
      height="100%"
      sx={theme => ({
        zIndex: 1,
        borderRight: `1px solid ${theme.colors.gray[1]}`,
      })}
    >
      <Stack h="94%" spacing="04">
        <Navbar.Section px="03" py="02">
          <Stack spacing="04">
            <Group noWrap spacing="03">
              <Box
                miw="06"
                mih="06"
                sx={theme => ({
                  borderRadius: theme.radius.md,
                  background:
                    'linear-gradient(180deg, rgba(33, 133, 213, 0.24) 0%, rgba(33, 133, 213, 0.02) 100%)',
                  boxShadow: `${theme.shadows.xs} inset`,
                })}
              >
                <Center pt="01">
                  <Text>👋</Text>
                </Center>
              </Box>
              <Text variant="sm" fw="400" c="gray.7">
                Bonjour <b>{activeUser.firstName}</b>, prêt(e) à cueillir les
                opportunités du jour ?
              </Text>
            </Group>
            <Link to="/flux/create">
              <Button leftIcon={<IconPlus />} w="100%">
                Nouveau Flux
              </Button>
            </Link>
          </Stack>
        </Navbar.Section>

        <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
          <Stack h="400px" spacing="04">
            {sidebarContent.favorites.length > 0 && (
              <StreamSectionAccordion
                title={t('favorite')}
                streams={sidebarContent.favorites}
                chevron={<IconStar stroke={2} size={16} />}
                fullChevronRotation
              />
            )}
            {sidebarContent.sections.map(section => (
              <StreamSectionAccordion
                title={section.title}
                streamSectionId={section.id}
                streams={section.streams}
                key={section.title}
              />
            ))}
            {sidebarContent.streamsWithoutSection.length > 0 && (
              <StreamSectionAccordion
                title="Aucune section"
                streams={sidebarContent.streamsWithoutSection}
                chevron={<IconCircleDashed stroke={2} size={16} />}
                fullChevronRotation
              />
            )}
          </Stack>
        </Navbar.Section>
      </Stack>
    </Navbar>
  );
}
