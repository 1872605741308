import { Stack, Text } from '@mantine/core';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';

import { useNoticeContext } from '../../contexts/Notice.provider';

export default function DescriptionCard() {
  const { tender } = useNoticeContext();

  return (
    <BrandedCard title="Résumé de l'opportunité" w="100%" h="100%">
      <Stack spacing="05">
        <Stack w="100%" spacing="05">
          <Stack w="100%" align="flex-start">
            <Text variant="sm" color="gray.5">
              {tender?.description}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </BrandedCard>
  );
}
