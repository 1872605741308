import { Box, Image, Stack, Text, Title } from '@mantine/core';

import { Button } from '../../../../../shared/components/UI/Button/Button';

import EmptyBuyerListIllustration from '../../../assets/empty_list.svg';

type EmptyBuyerListProps = {
  onFilterReset: () => void;
};

export const EmptyBuyerList = ({
  onFilterReset: resetFilter,
}: EmptyBuyerListProps) => {
  const title = 'Aucune opportunité';
  const subtitle =
    "Les opportunités futures pour cet acheteur s'afficheront dans cette section";

  return (
    <Box
      h="100%"
      mx="05"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md })}
    >
      <Stack align="center" spacing="05" m="10%">
        <Image
          src={EmptyBuyerListIllustration}
          alt="Illustration de liste d'appels d'offre vide"
          maw="116px"
          mah="116px"
        />
        <Stack spacing="02" align="center">
          <Title align="center" order={4} c="gray.9" maw="480px">
            {title}
          </Title>
          <Text variant="sm" fw={400} c="gray.5" align="center">
            {subtitle}
          </Text>
        </Stack>
        <Button
          variant="filled"
          color="primary"
          size="lg"
          onClick={resetFilter}
        >
          Réinitialiser les filtres
        </Button>
      </Stack>
    </Box>
  );
};
