import { useSuspenseQuery } from '@tanstack/react-query';

import { getStream } from '../../../../../shared/api/magellan/stream';

export function useStreamSettings(id: number) {
  const queryKey = [getStream.name, id];
  const queryFn = () => getStream(id);
  const { data } = useSuspenseQuery({
    queryKey,
    queryFn,
    gcTime: 0,
  });

  return data;
}
