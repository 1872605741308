import { Divider, Group, Text } from '@mantine/core';

import { IconFilter } from '@tabler/icons-react';

import { DatePicker } from '../../../../../shared/components/UI/DatePicker/DatePicker';
import {
  MultiCheckbox,
  type MultiCheckboxOption,
} from '../../../../../shared/components/UI/MultiCheckbox/MultiCheckbox';

import * as dateUtils from '../../../../../shared/utils/dates';
import type { TableFilter } from '../../../../../app/pages/Pipeline.page';
import { useTabsStatus } from '../../../hooks/useTabs.hook';
import type { TableColumnWithFilter } from '../utils/columnDefinitions';
import { TableColumn } from '../utils/columnDefinitions';
import type { FilterConfig } from './PipelineFilters';

type FilterInputsProps = {
  rowCount: number | undefined;
  filters: TableFilter[];
  filterConfig: Record<TableColumnWithFilter, FilterConfig>;
  onFilterChange: (columnId: TableColumnWithFilter, value: string[]) => void;
  resetFilters: () => void;
  isRenewal?: boolean;
};
export const FilterInputs = ({
  rowCount,
  filters,
  filterConfig,
  onFilterChange,
  resetFilters,
  isRenewal = false,
}: FilterInputsProps) => {
  const { activeTabStatus } = useTabsStatus();
  const statusConfig = filterConfig[TableColumn.STATUS];
  const ownerConfig = filterConfig[TableColumn.OWNER];
  const dateConfig = filterConfig[TableColumn.RESPONSE_DEADLINE];

  const getSelectedColumnFilters = (
    columnId: TableColumnWithFilter,
  ): MultiCheckboxOption[] => {
    const values = filters.find(filter => filter.id === columnId)?.value ?? [];
    const options = filterConfig[columnId].options;
    const selected = options?.filter(option => values.includes(option.value));
    return selected || [];
  };

  const dateValue = filters.find(
    filter => filter.id === TableColumn.RESPONSE_DEADLINE,
  )?.value[0];
  const dateFilter = dateValue
    ? dateUtils.parseFrLocaleDateStringToDate(dateValue)
    : undefined;

  return (
    <Group spacing="02">
      <Text variant="sm" fw="400" c="gray.9" miw="110px">
        {rowCount} opportunités
      </Text>
      <Divider orientation="vertical" h="04" my="auto" />
      <Group spacing="01" c="gray.6">
        <IconFilter size={20} />
        <Text variant="sm" fw="400" c="gray.6">
          Filtrer
        </Text>
      </Group>
      {!isRenewal && (
        <MultiCheckbox
          label={statusConfig.filterLabel}
          icon={statusConfig.icon}
          values={getSelectedColumnFilters(TableColumn.STATUS)}
          options={statusConfig.options}
          onChange={newVal =>
            onFilterChange(
              TableColumn.STATUS,
              newVal.map(({ value }) => value),
            )
          }
        />
      )}
      {!isRenewal &&
        dateConfig.displayFilterOnlyFor?.includes(activeTabStatus) && (
          <DatePicker
            label={dateConfig.filterLabel}
            icon={dateConfig.icon}
            value={dateFilter}
            onChange={newVal =>
              onFilterChange(
                TableColumn.RESPONSE_DEADLINE,
                newVal ? [dateUtils.parseDateToFrLocaleDateString(newVal)] : [],
              )
            }
          />
        )}
      <MultiCheckbox
        label={ownerConfig.filterLabel}
        icon={ownerConfig.icon}
        h={256}
        values={getSelectedColumnFilters(TableColumn.OWNER)}
        options={ownerConfig.options}
        popoverProps={{ middlewares: { flip: false, shift: false } }}
        onChange={newVal =>
          onFilterChange(
            TableColumn.OWNER,
            newVal.map(({ value }) => value),
          )
        }
      />
      {filters.length && <ResetButton onClick={resetFilters} />}
    </Group>
  );
};
type ResetButtonProps = {
  onClick: () => void;
};
const ResetButton = ({ onClick: resetFilters }: ResetButtonProps) => {
  return (
    <Text
      variant="sm"
      fw={500}
      c="gray.6"
      onClick={resetFilters}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      Réinitialiser
    </Text>
  );
};
