import { useEffect, useState } from 'react';

import { Box, Group, Stack } from '@mantine/core';

import { IconAlignJustified, IconFile, IconWand } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import { Loader } from '../../shared/components/UI/Loader/Loader';
import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import type { NoticeSidePanelTabValues } from '../../features/collaboration/components/Header';
import { SidePanel } from '../../features/collaboration/components/SidePanel';
import { Header } from '../../features/notice/components/Header';
import DocumentsTab from '../../features/notice/components/tabs/DocumentsTab';
import InfoTab from '../../features/notice/components/tabs/InfoTab';
import InstantAnalysisTab from '../../features/notice/components/tabs/InstantAnalysisTab';
import { NoticeProvider } from '../../features/notice/contexts/Notice.provider';
import { ScrollToElementProvider } from '../../features/notice/contexts/ScrollToElement.provider';
import { getAssessmentsRecap } from '../../shared/api/magellan/instantAnalysis/answers';
import { getTender } from '../../shared/api/magellan/tender';
import { HeaderActionBar } from '../../shared/components/HeaderActionBar';
import { useUrlHash } from '../../shared/contexts/UrlHash.provider';
import { DceRequestStatus } from '../../shared/entities/DceRequestStatus';
import { FeatureFlag } from '../../shared/entities/FeatureFlags';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';
import { useUsers } from '../../shared/hooks/useUsers.hook';
import { UrlHashTypes } from '../../shared/utils/navigation/UrlHashBuilder';

export const NoticeTabValues = {
  INFORMATIONS: 'informations',
  DOCUMENTS: 'documents',
  ANALYZE: 'analyse',
} as const;

export type NoticeTabValues =
  (typeof NoticeTabValues)[keyof typeof NoticeTabValues];

const Tabs = TabsComponents<NoticeTabValues>();
export type PageParams = {
  id: string;
  tabParam?: NoticeTabValues;
};
export default function NoticePage() {
  const initialSidePanelTab = useUrlHash().getReceivedUrlHashParam(
    UrlHashTypes.SIDEPANEL_ACTION,
  );
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = queryParams.get('tab') as NoticeTabValues | null;
  const defaultTab = NoticeTabValues.INFORMATIONS;
  const [noticeTab, setNoticeTab] = useState<NoticeTabValues>(
    urlTabsValue || defaultTab,
  );
  const usersQuery = useUsers();
  const [sidePanelOpen, setSidePanelOpen] =
    useState<boolean>(!!initialSidePanelTab);
  const [activeSidePanelTab, setActiveSidePanelTab] =
    useState<NoticeSidePanelTabValues | null>(initialSidePanelTab);
  const { id } = useParams<PageParams>() as PageParams; // Casting to enforce type. See https://github.com/remix-run/react-router/issues/8498
  const [dceRequestStatus, setDceRequestStatus] = useState<DceRequestStatus>(
    DceRequestStatus.WAITING,
  );

  useEffect(() => {
    setNoticeTab(urlTabsValue || defaultTab);
  }, [defaultTab, urlTabsValue]);

  useEffect(() => {
    setActiveSidePanelTab(initialSidePanelTab);
    setSidePanelOpen(!!initialSidePanelTab);
  }, [initialSidePanelTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [noticeTab]);

  const toggleSidePanel = () => setSidePanelOpen(prev => !prev);
  const setActiveSidebarTab = (tab: NoticeSidePanelTabValues | null) => {
    setActiveSidePanelTab(tab);
    if (tab === null) {
      setSidePanelOpen(false);
    }
    if (!sidePanelOpen) {
      toggleSidePanel();
    }
  };

  const tenderQuery = useQuery({
    queryKey: [getTender.name, Number(id)],
    queryFn: async () => {
      const tender = await getTender(id);
      setDceRequestStatus(tender.dceRequestStatus);
      return tender;
    },
  });

  const assessmentRecapQuery = useQuery({
    queryKey: [getAssessmentsRecap.name, Number(id)],
    queryFn: () => getAssessmentsRecap(Number(id)),
  });

  const tender = tenderQuery.data;
  useSetPageTitle(tender?.title ? `AO - ${tender?.title}` : 'Tengo');

  if (
    tenderQuery.isPending ||
    tenderQuery.isLoading ||
    !tender ||
    usersQuery.isLoading
  ) {
    return <Loader title="Chargement" />;
  }

  if (tenderQuery.isError) {
    throw tenderQuery.error;
  }

  let content;
  switch (noticeTab) {
    case NoticeTabValues.INFORMATIONS:
      content = <InfoTab />;
      break;
    case NoticeTabValues.ANALYZE:
      content = (
        <InstantAnalysisTab
          dceRequestStatus={dceRequestStatus}
          setDceRequestStatus={setDceRequestStatus}
        />
      );
      break;
    case NoticeTabValues.DOCUMENTS:
      content = (
        <DocumentsTab
          dceRequestStatus={dceRequestStatus}
          setDceRequestStatus={setDceRequestStatus}
        />
      );
      break;
    default:
      throw new Error(`Unknown tab value: ${noticeTab}`);
  }

  return (
    <NoticeProvider tender={tender}>
      <ScrollToElementProvider>
        <Stack spacing="05">
          <Stack
            spacing={0}
            pos="sticky"
            top={NAVBAR_HEIGHT}
            px="07"
            sx={theme => ({
              background: theme.other.gradients.backgroundMedium,
              zIndex: 10,
            })}
          >
            <HeaderActionBar
              owner={tender.interaction?.owner || null}
              users={usersQuery.users}
              setActiveTab={setActiveSidebarTab}
              isSidePanelOppenned={sidePanelOpen}
              tender={tender}
            />
            <Box
              pos="relative"
              sx={theme => ({
                '::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 16,
                  height: '20%',
                  width: '50%',
                  backgroundColor: theme.colors.primary[1],
                  zIndex: 0,
                  transform: 'rotate(1.2deg)',
                  borderRadius: theme.radius.md,
                },
                '::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  right: 16,
                  height: '20%',
                  width: '50%',
                  backgroundColor: theme.colors.primary[1],
                  zIndex: 0,
                  transform: 'rotate(1.2deg)',
                  borderRadius: theme.radius.md,
                },
              })}
            >
              <Tabs
                h="fit-content"
                value={noticeTab}
                onTabChange={(tab: NoticeTabValues) => setNoticeTab(tab)}
                header={<Header />}
                defaultTab="informations"
                tabListProps={{ sx: { borderBottom: 'none', zIndex: 10 } }}
                sx={theme => ({
                  borderRadius: theme.radius.md,
                  border: `1px solid ${theme.colors.gray[1]}`,
                  zIndex: 2,
                  position: 'relative',
                  boxShadow: theme.shadows.md,
                })}
              >
                <Tabs.Button
                  value="informations"
                  icon={<IconAlignJustified size={14} stroke={2} />}
                  label="Informations"
                />

                <Tabs.Button
                  value="analyse"
                  featureFlag={FeatureFlag.INSTANT_ANALYSIS}
                  icon={<IconWand size={16} stroke={2} />}
                  label={
                    <Group spacing="xxs" noWrap>
                      Analyse instantanée
                    </Group>
                  }
                  counter={
                    tender.interaction?.instantAnalysisStatus !== 'FAILED' &&
                    tender.interaction?.instantAnalysisStatus !==
                      'NO_SUPPORTED_DOCUMENTS'
                      ? assessmentRecapQuery.data?.assessmentRecap.unAssessed
                      : undefined
                  }
                />

                <Tabs.Button
                  value="documents"
                  icon={<IconFile size={14} stroke={2} />}
                  label="Documents"
                />
              </Tabs>
            </Box>
          </Stack>
          <Box px="07" m={0} w="100%" h="100%">
            {content}
          </Box>
        </Stack>
        <SidePanel
          oppened={sidePanelOpen}
          setActiveTab={setActiveSidebarTab}
          activeTab={activeSidePanelTab}
        />
      </ScrollToElementProvider>
    </NoticeProvider>
  );
}
