import { Accordion, Box, Group, Text } from '@mantine/core';

import { DocumentType } from '../../../../../shared/entities/Document';
import type { Document } from '../../../../../shared/entities/Document';
import { naturalCompare } from '../../../../../shared/utils/strings';
import { DocumentComponent } from './DocumentComponent';

type DocumentsAccordionProps = {
  documents: Document[];
  title: string;
};

export function DocumentsAccordion({
  documents,
  title,
}: DocumentsAccordionProps) {
  const sortedDocuments = sortDocuments(documents);

  return (
    <Accordion
      unstyled
      chevronPosition="left"
      defaultValue={title}
      styles={theme => ({
        chevron: {
          color: theme.colors.gray[6],
          display: 'flex',
          alignItems: 'center',
          marginRight: theme.spacing['01'],
          transform: 'rotate(180deg)',
          '&[data-rotate]': {
            transform: 'rotate(360deg)',
          },
          transition: 'transform 300ms ease',
        },
        label: {
          paddingTop: theme.spacing['00'],
          paddingBottom: theme.spacing['00'],
        },
        control: {
          borderRadius: theme.radius.md,
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: theme.colors.gray[0],
            cursor: 'pointer',
          },
        },
      })}
    >
      <Accordion.Item value={title}>
        <Accordion.Control mb="03" py={0} sx={{}}>
          <Group noWrap spacing="02" px="02">
            <Text variant="sm" fw="500" color="gray.9">
              {title}
            </Text>
            <Box
              w="01"
              h="01"
              bg="gray.2"
              sx={theme => ({ borderRadius: theme.radius.lg })}
            />
            <Text variant="sm" fw="400" c="gray.6">
              {documents.length} documents
            </Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel pl="03">
          {sortedDocuments.map(document => (
            <DocumentComponent key={document.id} document={document} />
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

const sortDocuments = (documents: Document[]): Document[] => {
  const typeOrder = {
    [DocumentType.RC]: 1,
    [DocumentType.AVIS]: 2,
    [DocumentType.DCE]: 3,
    [DocumentType.COMPLEMENT]: 4,
    [DocumentType.DUME]: 5,
  };

  return documents.sort((a, b) => {
    const orderA = typeOrder[a.type as DocumentType] ?? Infinity;
    const orderB = typeOrder[b.type as DocumentType] ?? Infinity;

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    return naturalCompare(a.prettyName, b.prettyName);
  });
};
