import type { ReactElement } from 'react';
import { useState } from 'react';

import { Stack } from '@mantine/core';

import type { Thread } from '../../../../../shared/entities/Thread.ts';
import { CommentComponent } from './CommentComponent.tsx';
import { CommentUnfoldButton } from './CommentUnfoldButton.tsx';
import { NewCommentInput } from './NewCommentInput.tsx';
import { InstantAnalysisPreviewCard } from './instantAnalysisPreviewCard.tsx';

type ThreadComponentProps = {
  thread: Thread;
  showBorderOnFocus?: boolean;
  px?: string;
};

export function ThreadComponent({
  thread,
  showBorderOnFocus,
  px = '04',
}: ThreadComponentProps) {
  const { comments } = thread;
  const [isFolded, setIsFolded] = useState(comments.length > 3 ? true : false);
  const instantAnalysisAnswerPreviewCard = thread.instantAnalysisAnswer && (
    <InstantAnalysisPreviewCard
      question={thread.instantAnalysisAnswer.userQuestion}
      instantAnalysisAnswerId={thread.instantAnalysisAnswer.id}
    />
  );

  const unfoldedComments = comments.map((comment, index) => (
    <CommentComponent
      key={comment.id}
      comment={comment}
      PreviewCard={index === 0 ? instantAnalysisAnswerPreviewCard : undefined}
    />
  ));

  const foldedComments: ReactElement[] = [
    <CommentComponent
      key={comments[0].id}
      comment={thread.comments[0]}
      PreviewCard={instantAnalysisAnswerPreviewCard}
    />,
    <CommentUnfoldButton
      key={`unfold-${thread.id}`}
      number={comments.length - 2}
      unfold={() => setIsFolded(false)}
    />,
    <CommentComponent
      key={comments[comments.length - 1].id}
      comment={thread.comments[comments.length - 1]}
    />,
  ].map(element => element);

  const content = isFolded ? foldedComments : unfoldedComments;

  return (
    <Stack
      spacing="01"
      px={px}
      h="fit-content"
      sx={{ transition: 'heigth 1s' }}
    >
      {content}
      <NewCommentInput
        threadId={thread.id}
        instantAnalysisAnswerId={thread.instantAnalysisAnswerId}
        showBorderOnFocus={showBorderOnFocus}
      />
    </Stack>
  );
}
