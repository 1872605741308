import { useState } from 'react';

import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import type { FileRejection } from '@mantine/dropzone';
import { Dropzone, type FileWithPath } from '@mantine/dropzone';

import { IconAsterisk, IconCheck } from '@tabler/icons-react';

type DropZoneInputProps = {
  files: FileWithPath[];
  error?: string | null;
  onFileChange: (files: FileWithPath[]) => void;
  label?: string;
  subtitle?: string;
  required?: boolean;
  children: React.ReactNode;
};

export const DropZoneInput = ({
  files,
  error,
  onFileChange,
  label,
  subtitle,
  children,
  required = false,
}: DropZoneInputProps) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  return (
    <Stack spacing="03">
      <Stack spacing="01">
        <Group noWrap spacing="02">
          <Text weight={500} size="sm" lh="20px">
            {label}
          </Text>
          {required && (
            <ThemeIcon
              radius="100%"
              size="03"
              variant="light"
              sx={theme => ({
                backgroundColor: theme.colors.primary[1],
                color: theme.colors.primary[6],
              })}
            >
              <IconAsterisk size={8} stroke={3} />
            </ThemeIcon>
          )}
        </Group>
        <Text variant="xs" weight={400} c="gray.5">
          {subtitle}
        </Text>
      </Stack>
      <Dropzone
        px="05"
        py="04"
        onReject={files => setRejectedFiles(files)}
        onDrop={onFileChange}
        accept={['.docx', '.pdf']}
        sx={theme => ({
          borderColor: error ? theme.colors.red[5] : theme.colors.gray[3],
          borderRadius: theme.radius.md,
        })}
      >
        {children}
      </Dropzone>
      {error && (
        <Text weight={500} size="xs" c="red.5" lh="20px" mt={-4}>
          {error}
        </Text>
      )}
      {rejectedFiles.length !== 0 &&
        rejectedFiles.map((file, index) => (
          <Text key={index} weight={500} variant="sm" c="red.5">
            {file.file.name} - type de fichier non supporté
          </Text>
        ))}
      {files.map((file, index) => (
        <Group key={file.name + index} noWrap spacing="00" c="green.6">
          <IconCheck />
          <Text weight={500} variant="sm" c="gray.8">
            {file.name}
          </Text>
        </Group>
      ))}
    </Stack>
  );
};
