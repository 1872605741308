import { useEffect } from 'react';

import { Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import { IconPlayerPlayFilled, IconPlus } from '@tabler/icons-react';

import { Button } from '../UI/Button/Button';

import { softDeleteQuestionsSet } from '../../api/magellan/instantAnalysis/questionSets';
import type { QuestionsSet } from '../../entities/QuestionsSet';
import { NewQuestionsSetsModal } from './NewQuestionsSetsModal';
import CheckboxQuestionsSetsGroup from './questionsSetsInputs/CheckboxQuestionsSetsGroup';

const userGuideUrl =
  'https://tengo-cc.notion.site/Guide-d-utilisation-de-l-Analyse-Instantan-e-83d4952a49b04f5e8e89bb87f03d631e';

type SelectQuestionSetsFormValues = {
  questionsSetIds: number[];
};

type SelectQuestionsSetsModalProps = {
  onValidation: (questionsSetsIds: number[]) => void;
  questionsSets: QuestionsSet[];
  setQuestionsSets: (questionSets: QuestionsSet[]) => void;
  onClose: () => void;
};

export function SelectQuestionsSetsModal({
  onValidation,
  questionsSets,
  setQuestionsSets,
  onClose,
}: SelectQuestionsSetsModalProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const form = useForm<SelectQuestionSetsFormValues>({
    initialValues: {
      questionsSetIds: [],
    },
    validate: {
      questionsSetIds: value =>
        value.length > 0
          ? undefined
          : "Veuillez sélectionner au moins 1 jeu de questions pour lancer l'analyse.",
    },
  });

  const addQuestionsSet = (questionSet: QuestionsSet) => {
    const newQuestionsSets = [...questionsSets, questionSet];
    setQuestionsSets(newQuestionsSets);
    close();
  };

  const deleteQuestionsSet = async (questionsSetId: number) => {
    await softDeleteQuestionsSet(questionsSetId);
    const newQuestionsSets = questionsSets.filter(
      questionsSet => questionsSet.id !== questionsSetId,
    );
    form.setFieldValue(
      'questionsSetIds',
      newQuestionsSets.map(questionsSet => questionsSet.id),
    );
    setQuestionsSets(newQuestionsSets);
  };

  const updateQuestionsSet = (questionsSet: QuestionsSet) => {
    const newQuestionsSets = questionsSets.map(qs => {
      if (qs.id === questionsSet.id) {
        return questionsSet;
      } else {
        return qs;
      }
    });
    setQuestionsSets(newQuestionsSets);
  };

  useEffect(() => {
    if (questionsSets.length === 0) {
      open();
    }
  }, [open, questionsSets.length]);

  const handleSubmit = async (values: SelectQuestionSetsFormValues) => {
    onValidation(values.questionsSetIds);
    onClose();
  };

  const handleNewQuestionSetModalClose = () => {
    if (questionsSets.length === 0) {
      onClose();
    }
    close();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Modal
        opened={opened}
        onClose={handleNewQuestionSetModalClose}
        size="lg"
        withCloseButton={false}
        yOffset="24vh"
      >
        <NewQuestionsSetsModal
          onSubmit={addQuestionsSet}
          close={handleNewQuestionSetModalClose}
        />
      </Modal>
      <Stack py={16} px={21} spacing="md">
        <Header />
        <CheckboxQuestionsSetsGroup
          questionsSets={questionsSets}
          deleteQuestionsSet={deleteQuestionsSet}
          updateQuestionsSet={updateQuestionsSet}
          {...form.getInputProps('questionsSetIds')}
        />
        <Button
          w={300}
          variant="subtle"
          leftIcon={<IconPlus size={16} />}
          onClick={open}
        >
          Créer un nouveau jeu de questions
        </Button>
        <Group position="right" mt="lg">
          <Button variant="subtle" onClick={onClose}>
            Fermer
          </Button>
          <Button type="submit" leftIcon={<IconPlayerPlayFilled />}>
            Lancer l'analyse
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

const Header = () => {
  return (
    <Stack spacing={0}>
      <Title order={4}>Sélection du jeu de questions</Title>
      <Text variant="sm" fw="400" c="gray.6">
        Sélectionnez le(s) jeu(x) de questions que vous souhaitez utiliser pour
        analyser cet appel d'offre.
        <a href={userGuideUrl} target="_blank">
          Découvrez notre guide utilisateur
        </a>
        pour tirer le meilleur parti de l'analyse instantanée.
      </Text>
    </Stack>
  );
};
