import { Box, Group } from '@mantine/core';

import { useParams } from 'react-router-dom';

import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';

import { useRedirectToFirstStream } from '../../features/streams/components/forms/hooks/useRedirectToFirstStream.hook';
import { EmptyStreamPlaceholder } from '../../features/streams/components/listOfResults/EmptyStreamPlaceholder';
import { ListOfResults } from '../../features/streams/components/listOfResults/ListOfResults';
import Sidebar from '../../features/streams/components/sidebar/Sidebar';
import { useStreamsContext } from '../../features/streams/contexts/Streams.provider';

const SIDEBAR_WIDTH = 284;

type StreamListOfResultsPageParams = {
  id: string;
};

export default function StreamPage() {
  const { id } = useParams<StreamListOfResultsPageParams>();
  useRedirectToFirstStream();
  const {
    status: streamsStatus,
    data: { isStreamListEmpty },
  } = useStreamsContext();

  if (!id || streamsStatus === 'loading') {
    return isStreamListEmpty ? <EmptyStreamPlaceholder /> : null;
  }

  return (
    <Group
      h="100%"
      mah={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      sx={{ overflow: 'hidden' }}
    >
      <Sidebar width={SIDEBAR_WIDTH} />
      <Box
        h="100%"
        w={`calc(100% - ${SIDEBAR_WIDTH}px)`}
        ml={`${SIDEBAR_WIDTH}px`}
      >
        <ListOfResults streamId={Number(id)} />
      </Box>
    </Group>
  );
}
