import { Box, Group, Stack, Text } from '@mantine/core';

import { IconAt, IconMapPin, IconPhone } from '@tabler/icons-react';

import { useBuyerContacts } from '../hooks/useBuyerContacts.hook';

type ContactsTabContentProps = {
  buyerId: number;
};

export function ContactsTabContent({ buyerId }: ContactsTabContentProps) {
  const contacts = useBuyerContacts(buyerId);

  return (
    <Box
      h="100%"
      sx={{
        overflowY: `auto`,
      }}
    >
      <Group spacing={24} mx="06" py="05">
        {contacts.map((contact, key) => {
          const name = contact?.contact || contact?.interlocutor;
          if (!name) {
            return;
          }

          return (
            <Box
              p="16px 16px 24px 16px"
              w="250px"
              bg="white"
              mih="200px"
              key={`${name}-${key}`}
              sx={theme => ({
                border: `1px solid ${theme.colors.gray[1]}`,
                borderRadius: '8px',
              })}
            >
              <Stack spacing={8}>
                <Text fz="14px" fw="700" lh="21.7px" color="dark.9">
                  {name}
                </Text>
                {/* location */}
                {contact.location && (
                  <Group spacing={0} align="flex-start" c="gray.9">
                    <IconMapPin
                      size={16}
                      stroke={1}
                      style={{ paddingTop: '2px', marginRight: '4px' }}
                    />
                    <Stack w="80%" spacing={0}>
                      {contact.location.address && (
                        <Text variant="xs" color="dark.9">
                          {`${contact.location.address},`}
                        </Text>
                      )}
                      <Text variant="xs" color="dark.9">
                        {`${contact.location.postalCode || ''} ${contact.location.city}`}
                      </Text>
                    </Stack>
                  </Group>
                )}
                {/* email */}
                {contact.email && (
                  <Group spacing={0} align="flex-start" c="gray.9">
                    <IconAt
                      size={16}
                      stroke={1}
                      style={{ paddingTop: '2px', marginRight: '4px' }}
                    />
                    <Stack w="80%" spacing={0}>
                      <Text variant="xs" color="dark.9">
                        {`${contact.email}`}
                      </Text>
                    </Stack>
                  </Group>
                )}
                {/* phone */}
                {contact.phone && (
                  <Group spacing={0} align="flex-start" c="gray.9">
                    <IconPhone
                      size={16}
                      stroke={1}
                      style={{ paddingTop: '2px', marginRight: '4px' }}
                    />
                    <Stack w="80%" spacing={0}>
                      <Text variant="xs" color="dark.9">
                        {`${contact.phone}`}
                      </Text>
                    </Stack>
                  </Group>
                )}
              </Stack>
            </Box>
          );
        })}
      </Group>
    </Box>
  );
}
