/// <reference types="vite-plugin-svgr/client" />
import { useState } from 'react';

import { ActionIcon, Divider, Group, Title } from '@mantine/core';

import {
  IconPencil,
  IconStar,
  IconStarFilled,
  IconStarOff,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../shared/components/UI/Button/Button';

// adding ?react to the import path will make Vite treat the SVG as a React component
import StartFilledIcon from '../../assets/star_filled.svg?react';
import { useFavorites } from '../forms/hooks/useFavorites.hook';

export const STREAM_LIST_OF_RESULTS_HEADER_HEIGHT = 68;

type HeaderProps = {
  streamId?: number;
  streamTitle?: string;
};

export const Header = ({ streamId, streamTitle }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Group
      position="apart"
      p="05"
      pb="02"
      sx={{
        background: 'linear-gradient(180deg, #EFF8FF -12.4%, #FFFFFF 65.97%)',
      }}
    >
      <Title order={3} c="gray.9">
        {streamTitle}
      </Title>
      <Group spacing="03" ta="end">
        <Button
          variant="default"
          leftIcon={<IconPencil />}
          onClick={() => {
            navigate(`/flux/${streamId}/edit`);
          }}
        >
          Éditer le flux
        </Button>
        <Divider orientation="vertical" h="04" my="auto" color="gray.2" />
        {streamId && <FavoriteButton streamId={streamId} />}
      </Group>
    </Group>
  );
};

type FavoriteButtonProps = { streamId: number };

const FavoriteButton = ({ streamId }: FavoriteButtonProps) => {
  const { isFavorite, toggleFavorite } = useFavorites();
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <ActionIcon
      c="gray.4"
      onClick={() => {
        setIsClicked(true);
        setIsHovered(false);
        toggleFavorite(streamId);
      }}
      onMouseEnter={() => {
        if (!isClicked) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsClicked(false);
      }}
      sx={{ ':hover': { background: 'transparent' } }}
    >
      {isFavorite(streamId) ? (
        isHovered ? (
          <IconStarOff stroke={1.5} size={20} />
        ) : (
          <StartFilledIcon style={{ width: '20px' }} />
        )
      ) : isHovered ? (
        <IconStarFilled style={{ width: '20px' }} />
      ) : (
        <IconStar stroke={1.5} size={20} />
      )}
    </ActionIcon>
  );
};
