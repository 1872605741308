import * as Sentry from '@sentry/react';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { downloadAllDocumentsAsZip } from '../../../../../shared/api/magellan/documents';
import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';
import { downloadFile } from '../../../../../shared/utils/download';

export const useDownloadAllDocumentsAsZip = (
  tenderTitle: string,
  tenderBuyerName?: string,
): UseMutationResult<Blob, Error, number> => {
  return useMutation({
    mutationFn: downloadAllDocumentsAsZip,
    onSuccess: response => {
      handleDownload(response, tenderTitle, tenderBuyerName);
    },
    onError: error => {
      Sentry.captureException(error);
      displayErrorNotification(
        'Erreur',
        "Nous n'avons pas réussi à télécharger les documents",
      );

      throw Error('Error while downloading documents');
    },
  });
};

// Creates an object URL for the Blob data
const handleDownload = (
  data: Blob,
  tenderTitle: string,
  tenderBuyerName?: string,
) => {
  if (data) {
    const url = window.URL.createObjectURL(data);
    const fileName = createFileName(tenderTitle, tenderBuyerName);

    downloadFile(url, fileName);

    window.URL.revokeObjectURL(url);
  }
};

const createFileName = (tenderTitle: string, tenderBuyerName = '') => {
  const date = new Date();
  const fileNameSegments = [
    `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`,
    tenderBuyerName,
    tenderTitle,
  ];
  const fileName = `${fileNameSegments.join('-')}.zip`;

  return fileName;
};
