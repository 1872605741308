import { Avatar } from '../../../../../shared/components/UI/Avatar/Avatar';

import { useActiveUser } from '../../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import type {
  Comment,
  RichTextJson,
} from '../../../../../shared/entities/Comment';
import { useNoticeContext } from '../../../../notice/contexts/Notice.provider';
import { useThreads } from '../hooks/useThreads.hook';
import { CommentInput } from './CommentInput';

type EditCommentProps = {
  comment: Comment;
  onEdit: () => void;
};

export function EditComment({ comment, onEdit }: EditCommentProps) {
  const { activeUser } = useActiveUser();
  const { tender } = useNoticeContext();
  const { updateComment } = useThreads(tender.id);

  const handleSave = (content: RichTextJson) => {
    updateComment(comment.id, content);
    onEdit();
  };

  return (
    <CommentInput
      avatar={
        <Avatar color={activeUser.avatarColor}>
          {activeUser.firstName[0].toUpperCase() +
            activeUser.lastName[0].toUpperCase()}
        </Avatar>
      }
      placeholder="répondre..."
      initialContent={comment.content}
      saveComment={handleSave}
      variant="subtle"
      showBorderOnFocus
    />
  );
}
