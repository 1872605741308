import { useState } from 'react';

import { Badge, Button, Text, useMantineTheme } from '@mantine/core';

import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import { AssessmentBadge } from './AssessmentBadge';

type AssessmentButtonProps = {
  assessment: AssessmentStatus | null;
  count?: number;
  onClick?: (assessment: AssessmentStatus | null) => void;
  selected?: boolean;
  withFilledBadge?: boolean;
};

export function AssessmentButton({
  assessment: assessment,
  count,
  onClick: handleClick,
  selected = false,
  withFilledBadge = false,
}: AssessmentButtonProps) {
  const [isHovered, setIsHovered] = useState(false);
  let text: string;

  switch (assessment) {
    case AssessmentStatus.BLOCKING_POINT:
      text = 'Point bloquant';
      break;
    case AssessmentStatus.ATTENTION_POINT:
      text = "Point d'attention";
      break;
    case AssessmentStatus.POSITIVE_POINT:
      text = 'Point positif';
      break;
    case AssessmentStatus.NEUTRAL_POINT:
      text = 'Point neutre';
      break;
    case AssessmentStatus.UNASSESSED:
    default:
      text = 'Point à traiter';
      break;
  }

  return (
    <Button
      variant="outline"
      radius="md"
      c="gray.9"
      disabled={!handleClick}
      leftIcon={
        <AssessmentBadge
          assessment={assessment}
          isSelected={withFilledBadge || isHovered || selected}
        />
      }
      rightIcon={
        count && (
          <AmountBadge count={count} isSelected={isHovered || selected} />
        )
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleClick && handleClick(assessment)}
      styles={{
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      sx={theme => ({
        alignContent: 'flex-start',
        border: selected
          ? `2px solid ${theme.colors.primary[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: selected ? theme.colors.primary[0] : 'white',
        ':hover': {
          backgroundColor: theme.colors.primary[0],
        },
        ':disabled': {
          border: `1px solid ${theme.colors.gray[3]}`,
          color: theme.colors.gray[9],
          backgroundColor: 'white',
        },
      })}
    >
      <Text variant="sm" fw="500">
        {text}
      </Text>
    </Button>
  );
}

const AmountBadge = ({
  count,
  isSelected = false,
}: {
  count: number;
  isSelected?: boolean;
}) => {
  const theme = useMantineTheme();
  const color = isSelected ? theme.colors.primary[2] : theme.colors.gray[1];

  return (
    <Badge c="gray.9" px={6} sx={{ backgroundColor: color }}>
      {count}
    </Badge>
  );
};
