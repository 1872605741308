import { useEffect, useState } from 'react';

import { Box, Stack } from '@mantine/core';

import {
  Icon3dCubeSphere,
  IconBlockquote,
  IconChecklist,
  IconEyeglass,
} from '@tabler/icons-react';
import { useLocation, useParams } from 'react-router-dom';

import { EmptyPlaceholder } from '../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';
import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import { Header } from '../../features/buyerSimulation/components/Header';
import { ResponseProjectTab } from '../../features/buyerSimulation/components/ResponseProjectTab/ResponseProjectTab';
import { SimulationTab } from '../../features/buyerSimulation/components/SimulationTab/SimulationTab';
import { useBidRespone } from '../../features/buyerSimulation/hooks/useBidResponse.hook';
import { HeaderActionBar } from '../../shared/components/HeaderActionBar';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';

const BuyerSimTabValues = {
  RESPONSE: 'responseDocs',
  BUYER_SIM: 'buyerSimulation',
  LEXICAL_FIELD: 'lexicalField',
  DUPLICATE: 'duplicate',
} as const;

type BuyerSimTabValues =
  (typeof BuyerSimTabValues)[keyof typeof BuyerSimTabValues];

const Tabs = TabsComponents<BuyerSimTabValues>();

type PageParams = {
  id: string;
  tabParam?: BuyerSimTabValues;
};

export const BuyerSimulationPage = () => {
  useSetPageTitle('Simulation acheteur');

  const { id } = useParams<PageParams>() as PageParams; // Casting to enforce type. See https://github.com/remix-run/react-router/issues/8498
  const { bidResponse } = useBidRespone(Number(id));
  const lastProposalVersionId = bidResponse.proposalVersions?.length
    ? bidResponse.proposalVersions[bidResponse.proposalVersions.length - 1].id
    : null;
  const [selectedProposalVersion, setSelectedProposalVersion] = useState<
    number | null
  >(lastProposalVersionId);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const defaultTab = BuyerSimTabValues.RESPONSE;
  const urlTabsValue = queryParams.get('tab') as BuyerSimTabValues | null;
  const [noticeTab, setNoticeTab] = useState<BuyerSimTabValues>(
    urlTabsValue || defaultTab,
  );

  useEffect(() => {
    if (urlTabsValue) {
      setNoticeTab(urlTabsValue);
    }
  }, [urlTabsValue]);

  return (
    <Stack
      spacing="05"
      h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      w="100%"
      pt="04"
      pb="05"
      px="06"
      pos="relative"
      sx={{
        background: `linear-gradient(180deg, #EFF8FF -5.79%, #FFFFFF 19.49%)`,
      }}
    >
      <HeaderActionBar />
      <Box
        pos="relative"
        sx={theme => ({
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 16,
            height: '20px',
            width: '50%',
            backgroundColor: theme.colors.primary[2],
            zIndex: 0,
            transform: 'rotate(-2deg)',
            borderTopRightRadius: theme.radius.md,
          },
        })}
      >
        <Tabs
          h="fit-content"
          value={noticeTab}
          onTabChange={(tab: BuyerSimTabValues) => setNoticeTab(tab)}
          defaultTab="responseDocs"
          header={
            <Header
              buyerOriginalName={bidResponse.buyerName}
              tenderTitle={bidResponse.title}
            />
          }
          sx={theme => ({
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.gray[1]}`,
            zIndex: 2,
            position: 'relative',
            boxShadow: theme.shadows.md,
          })}
        >
          <Tabs.Button
            value="responseDocs"
            icon={<Icon3dCubeSphere size={14} stroke={2} />}
            label="Projet de réponse"
          />

          <Tabs.Button
            value="buyerSimulation"
            icon={<IconEyeglass size={16} stroke={2} />}
            label="Simulation acheteur"
          />

          <Tabs.Button
            value="lexicalField"
            icon={<IconBlockquote size={14} stroke={2} />}
            label="Champs lexical"
            disabled
          />
          <Tabs.Button
            value="duplicate"
            icon={<IconChecklist size={14} stroke={2} />}
            label="Doublons"
            disabled
          />
          <Tabs.Content value="responseDocs">
            <ResponseProjectTab
              {...bidResponse}
              selectedProposalVersionId={selectedProposalVersion}
              setSelectedProposalVersion={setSelectedProposalVersion}
            />
          </Tabs.Content>
          <Tabs.Content value="buyerSimulation">
            {bidResponse.proposalVersions?.length ? (
              <SimulationTab
                bidResponseId={Number(id)}
                proposalVersionId={
                  selectedProposalVersion ||
                  bidResponse.proposalVersions[
                    bidResponse.proposalVersions.length - 1
                  ].id
                }
                criteria={bidResponse.judgementCriteria}
              />
            ) : (
              <EmptyPlaceholder title="Aucune version de proposition" />
            )}
          </Tabs.Content>
        </Tabs>
      </Box>
    </Stack>
  );
};
