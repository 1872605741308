import { useState } from 'react';

import { Grid, Stack } from '@mantine/core';

import { useMutation } from '@tanstack/react-query';

import {
  updateAiRelevanceFeedback,
  updateManualIaAnswer,
} from '../../../../shared/api/magellan/instantAnalysis/answers';
import type { AssessmentStatus } from '../../../../shared/entities/AssessmentStatus';
import type {
  AiRelevanceFeedbackStatus,
  Answer,
} from '../../../../shared/entities/InstantAnalysisAnswer';
import { AssessmentContent } from '../AssessmentContent';
import { EditAnswer } from './EditAnswer';
import { InstantAnalysisComments } from './InstantAnalysisComments';
import { LlmAnswer } from './LlmAnswer';
import { UserAnswer } from './UserAnswer';

type AnswerElementProps = {
  answer: Answer;
  updateAnswer: (answer: Answer) => void;
  setAssessment: (assessment: AssessmentStatus | null) => void;
  setAiRelevanceFeedback: (feedback: AiRelevanceFeedbackStatus | null) => void;
};

export const AnswerElement = ({
  answer,
  updateAnswer,
  setAssessment,
  setAiRelevanceFeedback,
}: AnswerElementProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [feedback, setFeedback] = useState<AiRelevanceFeedbackStatus | null>(
    answer.aiRelevanceFeedback || null,
  );

  const feedbackMutation = useMutation({
    mutationFn: ({
      feedback,
    }: {
      feedback: AiRelevanceFeedbackStatus | null;
    }) => updateAiRelevanceFeedback(answer.id, feedback),
  });

  const manualAnswerMutation = useMutation({
    mutationFn: ({ manualAnswer }: { manualAnswer: string }) =>
      updateManualIaAnswer(answer.id, manualAnswer),
    onSuccess: updatedAnswer => updateAnswer(updatedAnswer),
  });

  const handleManualAnswer = (manualAnswer: string) => {
    manualAnswerMutation.mutate({ manualAnswer });
  };

  const handleAiRelevanceFeedback = (
    selectedFeedback: AiRelevanceFeedbackStatus,
  ) => {
    const newFeedback: AiRelevanceFeedbackStatus | null =
      feedback === selectedFeedback ? null : selectedFeedback;

    feedbackMutation.mutate({ feedback: newFeedback });
    setFeedback(newFeedback);
    setAiRelevanceFeedback(newFeedback);
  };

  const answerText = answer.llmAnswer || answer.userAnswer;

  if (!answerText || isEditing) {
    return (
      <EditAnswer
        handleManualAnswer={handleManualAnswer}
        initialManualAnswer={answerText}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }
  const answerComponent = answer.llmAnswer ? (
    <LlmAnswer
      answer={answerText}
      feedback={feedback}
      handleAiRelevanceFeedback={handleAiRelevanceFeedback}
      sources={answer.sources}
    />
  ) : (
    <UserAnswer
      answer={answerText}
      answeredBy={answer.userAnswerCreatedBy}
      answeredAt={answer.userAnswerCreatedAt}
      onEdit={() => setIsEditing(true)}
    />
  );

  return (
    <Grid px="05">
      <Grid.Col span={10} pr="08">
        <Stack spacing="04">
          {answerComponent}
          <InstantAnalysisComments answer={answer} />
        </Stack>
      </Grid.Col>
      <Grid.Col span={2}>
        <AssessmentContent
          answerId={answer.id}
          assessment={answer.assessment}
          assesedAt={answer.assessedAt}
          assessorId={answer.assessorId}
          onAssessmentUpdate={setAssessment}
        />
      </Grid.Col>
    </Grid>
  );
};
