import type { MantineTheme } from '@mantine/core';
import { Divider, Group, Text, useMantineTheme } from '@mantine/core';

import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { Button } from './UI/Button/Button';

import {
  Header,
  type NoticeSidePanelTabValues,
} from '../../features/collaboration/components/Header';
import { useUrlHash } from '../contexts/UrlHash.provider';
import type Tender from '../entities/Tender';
import type { User } from '../entities/User';
import { UrlHashTypes } from '../utils/navigation/UrlHashBuilder';
import { OwnerSelect } from './OwnerSelect';

export type PreviousPageData = {
  pathname: string;
  title: string;
};

type HeaderActionBarProps = {
  owner?: User | null;
  users?: User[];
  setActiveTab?: (tab: NoticeSidePanelTabValues | null) => void;
  isSidePanelOppenned?: boolean;
  tender?: Tender;
};

export function HeaderActionBar({
  owner,
  users,
  setActiveTab,
  isSidePanelOppenned,
  tender,
}: HeaderActionBarProps) {
  return (
    <Group
      noWrap
      position="apart"
      h="08"
      sx={theme => ({
        flexDirection: 'row-reverse',
        paddingRight: isSidePanelOppenned
          ? theme.spacing['04']
          : theme.spacing['03'],
      })}
    >
      <ActionGroup
        users={users || []}
        owner={owner}
        setActiveTab={setActiveTab}
        isSidePanelOppenned={isSidePanelOppenned}
        tender={tender}
      />
      <ReturnButton />
    </Group>
  );
}

const ReturnButton = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { getReceivedUrlHashParam } = useUrlHash();
  const locationData = getReceivedUrlHashParam(UrlHashTypes.LOCATION_DATA);

  if (!locationData) {
    return;
  }

  const { pathname: previousPathname, title: previousTitle } = locationData;

  return (
    <Button
      variant="white"
      maw="25%"
      c="gray"
      leftIcon={<IconArrowLeft size={16} />}
      onClick={() => {
        if (previousPathname) {
          navigate(previousPathname);
        }
      }}
      sx={(theme: MantineTheme) => ({
        ':hover': {
          color: theme.colors.gray[8],
        },
      })}
    >
      <Text
        variant="sm"
        fw={400}
        c="gray.6"
        truncate
        sx={{
          ':hover': {
            color: theme.colors.gray[8],
          },
        }}
      >
        {previousTitle}
      </Text>
    </Button>
  );
};

type ActionGroupProps = {
  users: User[];
  owner: User | null | undefined;
  setActiveTab?: (tab: NoticeSidePanelTabValues | null) => void;
  isSidePanelOppenned?: boolean;
  tender?: Tender;
};

const ActionGroup = ({
  users,
  owner,
  setActiveTab,
  isSidePanelOppenned,
  tender,
}: ActionGroupProps) => {
  const withOwner = owner && users;
  const displayDivider = withOwner && !isSidePanelOppenned;
  const displaySidePanelHeader = !isSidePanelOppenned && setActiveTab && tender;

  return (
    <Group noWrap spacing="02">
      {users.length && <OwnerSelect users={users} owner={owner || null} />}
      {displayDivider && (
        <Divider orientation="vertical" h="04" my="auto" color="gray.2" />
      )}
      {displaySidePanelHeader && (
        <Header setActiveTab={setActiveTab} oppened={false} />
      )}
    </Group>
  );
};
