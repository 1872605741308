import type { TenderWithListData } from '../../../../app/pages/Pipeline.page';
import {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../entities/Interaction';
import type { Interaction } from '../../../entities/Interaction';
import { magellanClient } from '../../../infra/axios';
import type { GetDecisionCountsResponseDTO } from './dto';

export async function upsertInteractionWithDecisionStatus(
  tenderId: number,
  status: DecisionStatus,
  streamId?: number,
): Promise<Interaction> {
  const payload = streamId ? { status, streamId } : { status };

  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionStatus`,
    payload,
  );

  return response.data;
}

export async function upsertInteractionWithRenewalDecisionStatus(
  tenderId: number,
  renewalStatus: DecisionRenewalStatus,
): Promise<Interaction> {
  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionRenewalStatus`,
    { status: renewalStatus },
  );

  return response.data;
}

export async function getTendersWithInteraction(
  status?: DecisionStatus,
): Promise<TenderWithListData[]> {
  const response = await magellanClient.get<TenderWithListData[]>(
    `/interactions`,
    {
      params: {
        status: status,
        orderBy: status === DecisionStatus.ANSWERED ? 'answeredAt' : undefined,
      },
    },
  );

  return response.data;
}

export async function getRenewalTendersWithInteraction(
  status?: DecisionRenewalStatus,
): Promise<TenderWithListData[]> {
  const response = await magellanClient.get<TenderWithListData[]>(
    `/interactions/renewals`,
    {
      params: {
        status: status,
        orderBy:
          status === DecisionRenewalStatus.TO_ANALYZE
            ? 'answeredAt'
            : undefined,
      },
    },
  );

  return response.data;
}

export async function getDecisionCounts(): Promise<GetDecisionCountsResponseDTO> {
  const response =
    await magellanClient.get<GetDecisionCountsResponseDTO>(
      `/interactions/counts`,
    );

  return response.data;
}
