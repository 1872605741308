import { Button, Paper, Stack, Text, Title } from '@mantine/core';

import { IconExternalLink } from '@tabler/icons-react';

type SelfSearchCardProps = {
  participationURL: string;
};

export const SelfSearchCard = ({ participationURL }: SelfSearchCardProps) => {
  return (
    <Paper
      p="xl"
      shadow="lg"
      radius="sm"
      sx={theme => ({ border: `1px solid ${theme.colors.gray[0]}` })}
    >
      <Stack align="center">
        <Title order={4} c="gray.5">
          Chercher moi-même
        </Title>
        <Text variant="sm" fw="400" c="gray.4">
          Les documents sont présents sur la plateforme Profil Acheteur
        </Text>
        <Button
          variant="light"
          radius="md"
          size="sm"
          leftIcon={<IconExternalLink size={16} />}
          onClick={() => window.open(participationURL, '_blank')}
        >
          Accéder à la plateforme Profil Acheteur
        </Button>
      </Stack>
    </Paper>
  );
};
