import { useState } from 'react';

import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import type { FileWithPath } from '@mantine/dropzone';

import {
  IconAsterisk,
  IconCheck,
  IconInfoSquareRounded,
  IconPaperclip,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../shared/components/UI/Button/Button';

import { ResponseDocType } from '../../../../shared/entities/BidResponse';
import { DropZoneInput } from '../../../bidResponse/components/DropZoneInput';
import { useBidRespone } from '../../hooks/useBidResponse.hook';
import { useCreateProposalVersion } from './useCreateProposalVersion.hook';

type VersionInputsProps = {
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};
export const VersionInputs = ({
  bidResponseId,
  setSelectedProposalVersion,
}: VersionInputsProps) => {
  const { refetchBidResponse } = useBidRespone(bidResponseId);
  const { creaProposalVersion } = useCreateProposalVersion();
  const navigate = useNavigate();
  const [proposalFiles, setProposalFiles] = useState<FileWithPath[]>([]);
  const [complementaryFiles, setComplementaryFiles] = useState<FileWithPath[]>(
    [],
  );

  const handleDrop = (newFile: FileWithPath[], type: ResponseDocType) => {
    if (type === ResponseDocType.PROPOSAL_TECHNICAL_SUBMISSION) {
      setProposalFiles(prev => [...prev, ...newFile]);
    } else {
      setComplementaryFiles(prev => [...prev, ...newFile]);
    }
  };

  const createNewProposalVersion = () => {
    creaProposalVersion({
      bidResponseId,
      proposalFiles,
      complementaryFiles,
      onSuccessCallback: data => {
        refetchBidResponse();
        setSelectedProposalVersion(data.id);
        navigate(`/reponse/${bidResponseId}?tab=buyerSimulation`);
      },
    });
  };

  return (
    <Stack spacing="05">
      <Group noWrap spacing="02" align="flex-end" miw="960px">
        <DropZoneInput
          files={[]}
          onFileChange={files =>
            handleDrop(files, ResponseDocType.PROPOSAL_TECHNICAL_SUBMISSION)
          }
          label="Chargez une version de votre réponse"
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              {proposalFiles.length ? (
                <ThemeIcon color="teal.6" size="sm">
                  <IconCheck />
                </ThemeIcon>
              ) : (
                <IconPaperclip />
              )}
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  votre mémoire technique
                </Text>
                <ThemeIcon
                  size="xs"
                  variant="light"
                  sx={theme => ({
                    backgroundColor: theme.colors.primary[1],
                    color: theme.colors.primary[6],
                    borderRadius: theme.radius.xl,
                  })}
                >
                  <IconAsterisk size={8} stroke={3} />
                </ThemeIcon>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
        <DropZoneInput
          files={[]}
          onFileChange={files =>
            handleDrop(files, ResponseDocType.PROPOSAL_APPENDICES)
          }
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              {complementaryFiles.length ? (
                <ThemeIcon color="teal.6" size="sm">
                  <IconCheck />
                </ThemeIcon>
              ) : (
                <IconPaperclip />
              )}
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  vos annexes
                </Text>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
      </Group>
      <Button
        w="fit-content"
        disabled={!proposalFiles.length}
        onClick={createNewProposalVersion}
      >
        Lancer la simulation acheteur
      </Button>
    </Stack>
  );
};
