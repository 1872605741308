import { useMutation } from '@tanstack/react-query';

import { deleteBidRequirement } from '../../../../shared/api/magellan/bidResponses';

type DeleteBidRequirementDto = {
  bidResponseId: number;
  bidRequirementId: number;
  deletionReason?: string;
  onSuccessCallback?: (data: any) => void;
};

export const useBidRequirement = () => {
  const deleteMutation = useMutation({
    mutationFn: async ({
      bidRequirementId,
      bidResponseId,
      deletionReason,
    }: DeleteBidRequirementDto) => {
      const res = await deleteBidRequirement(
        bidResponseId,
        bidRequirementId,
        deletionReason,
      );
      return res;
    },
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
  });

  return {
    deleteBidRequirement: deleteMutation.mutate,
  };
};
