import {
  Box,
  Flex,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';

import { IconExternalLink, IconFileZip } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../shared/components/UI/Button/Button';
import { Spinner } from '../../../../shared/components/UI/Spinner/Spinner';

import { requestDCE } from '../../../../shared/api/magellan/tender';
import { DceRequestStatus } from '../../../../shared/entities/DceRequestStatus';
import type { Document } from '../../../../shared/entities/Document';
import { naturalCompare } from '../../../../shared/utils/strings';
import { useNoticeContext } from '../../contexts/Notice.provider';
import { DocumentsAccordion } from '../documents/components/DocumentsAccordion';
import { EmptyDocumentContainer } from '../documents/components/EmptyDocumentContainer';
import { useDownloadAllDocumentsAsZip } from '../documents/hooks/useDownloadAllDocumentsAsZip';

type DocumentsTabProps = {
  dceRequestStatus: DceRequestStatus;
  setDceRequestStatus: (dceRequestStatus: DceRequestStatus) => void;
};

export default function DocumentsTab(props: DocumentsTabProps) {
  const { tender } = useNoticeContext();
  const { documents } = tender;

  return (
    <Stack
      spacing="05"
      p="05"
      mb="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      {!!documents?.length && (
        <DocumentTabHeader numberOfDocument={documents.length} />
      )}
      <DocumentsTabContent {...props} documents={documents} />
    </Stack>
  );
}

type DocumentsTabHeaderProps = {
  numberOfDocument: number;
};

const DocumentTabHeader = ({ numberOfDocument }: DocumentsTabHeaderProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { tender } = useNoticeContext();

  const { mutate: downloadAllDocuments, status } = useDownloadAllDocumentsAsZip(
    tender.title,
    tender.buyerName,
  );

  const disabled = status === 'pending' || status === 'error';

  return (
    <Stack spacing="01">
      <Flex align="center" justify="space-between" w="100%">
        <Stack spacing="01">
          <Group noWrap spacing="02">
            <Title order={5} color="gray.9">
              {t('documents.title')}
            </Title>
            <Box
              w="01"
              h="01"
              bg="gray.2"
              sx={theme => ({ borderRadius: theme.radius.lg })}
            />
            <Text variant="sm" fw="400" c="gray.6">
              {numberOfDocument}
            </Text>
          </Group>
          <Text variant="sm" fw="400" c="gray.6">
            {t('documents.subtitle')}
          </Text>
        </Stack>
        <Button
          variant="default"
          color="primary"
          size="sm"
          leftIcon={
            status === 'pending' ? (
              <Spinner />
            ) : (
              <IconFileZip
                color={
                  disabled ? theme.colors.gray[3] : theme.colors.primary[7]
                }
              />
            )
          }
          disabled={disabled}
          onClick={() => downloadAllDocuments(tender.id)}
        >
          {t('downloadAll')}
        </Button>
      </Flex>
    </Stack>
  );
};

type DocumentsTabContentProps = {
  documents: Document[] | undefined;
  dceRequestStatus: DceRequestStatus;
  setDceRequestStatus: (dceRequestStatus: DceRequestStatus) => void;
};

const DocumentsTabContent = ({
  documents,
  dceRequestStatus,
  setDceRequestStatus,
}: DocumentsTabContentProps) => {
  const { t } = useTranslation();
  const { tender } = useNoticeContext();
  const { buyerProfileParticipationURL } = tender;
  const sendDCERequest = () => {
    requestDCE(tender.id);
    setDceRequestStatus(DceRequestStatus.IN_SEARCH);
  };

  if (documents?.length) {
    const sortedDocuments = sortDocumentsPerLot(documents);
    return (
      <Stack>
        {Object.entries(sortedDocuments).map(([section, documents]) => {
          return (
            <DocumentsAccordion
              key={section}
              documents={documents}
              title={section}
            />
          );
        })}
        {buyerProfileParticipationURL && (
          <Button
            w="fit-content"
            radius="md"
            variant="light"
            size="sm"
            rightIcon={<IconExternalLink size={16} />}
            onClick={() => window.open(buyerProfileParticipationURL, '_blank')}
          >
            {t('documents.accessBuyerPlatform')}
          </Button>
        )}
      </Stack>
    );
  }
  return (
    <EmptyDocumentContainer
      dceRequestStatus={dceRequestStatus}
      sendDCERequest={sendDCERequest}
    />
  );
};

const sortDocumentsPerLot = (documents: Document[]) => {
  const sections: { [key in Document['section']]: Document[] } = {
    Général: [],
  };
  const sortedDocuments = documents.sort((a, b) =>
    naturalCompare(a.section, b.section),
  );
  sortedDocuments.forEach(document => {
    sections[document.section] ||= [];
    sections[document.section].push(document);
  });

  return sections;
};
