import { useEffect, useMemo, useState } from 'react';

import {
  type MRT_ColumnDef,
  type MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';

import type {
  TableFilter,
  TenderWithListData,
} from '../../../../../app/pages/Pipeline.page';
import { useUrlHash } from '../../../../../shared/contexts/UrlHash.provider';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import { UrlHashBuilder } from '../../../../../shared/utils/navigation/UrlHashBuilder';
import { normalizeString } from '../../../../../shared/utils/strings';
import type { DecisionStatusTab } from '../../../hooks/useTabs.hook';
import { exitAnimationDuration } from '../../../hooks/useTenderDecisionsList.hook';
import { EmptyDecisionList } from '../../EmptyPipeline';
import {
  answeredAtColumn,
  assessmentRecapColumn,
  getActionsColumn,
  getContributionsColumn,
  lossColumn,
  nogoColumn,
  opportunityColumn,
  ownerColumn,
  responseDeadlineColumn,
  statusColumn,
  winColumn,
} from '../utils/columnDefinitions';

type DecisionTableProps = {
  decisionStatus: DecisionStatusTab;
  initialTenders: TenderWithListData[];
  onTenderDecision: (
    index: number,
    status: DecisionStatus,
    reason?: string,
  ) => void;
  onNoteChange: (note: string, tenderId: number) => void;
  onTableChange: (rowCount: number) => void;
  filters: TableFilter[];
  globalFilter?: string;
};

export function DecisionTables({
  decisionStatus,
  initialTenders,
  onTenderDecision,
  onNoteChange,
  onTableChange,
  filters,
  globalFilter,
}: DecisionTableProps) {
  const { redirectTo } = useUrlHash();
  const [tenders, setTenders] = useState<TenderWithListData[]>(initialTenders);

  useEffect(() => {
    setTenders(initialTenders);
  }, [initialTenders]);

  const tableConfig: Omit<MRT_TableOptions<TenderWithListData>, 'columns'> = {
    data: tenders,
    memoMode: 'cells',
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    mantinePaperProps: {
      sx: theme => ({
        height: '100%',
        flexGrow: 1,
        overflowY: `auto`,
        border: 'none !important',
        '& thead': {
          position: 'sticky',
          top: `0px`,
          zIndex: 10,
        },
        '& thead > tr > th': {
          border: 'none !important',
          paddingLeft: theme.spacing['03'],
          paddingRight: theme.spacing['03'],
          paddingTop: 0,
          paddingBottom: 0,
          height: theme.spacing['06'],
          justifyContent: 'center',
        },
        '& thead > tr > th > div ': {
          color: theme.colors.gray[6],
          fontWeight: 500,
          fontSize: theme.fontSizes.xs,
        },
        '& tbody > tr': {
          ':hover': {
            backgroundColor: 'white!important',
          },
        },
        '& tbody > tr > td': {
          padding: theme.spacing['03'],
          borderTop: 'none !important',
          borderBottom: 'none !important',
        },
      }),
    },
    mantineTableContainerProps: {
      sx: theme => ({
        paddingRight: theme.spacing['05'],
        paddingLeft: theme.spacing['05'],
      }),
    },
    mantineTableHeadRowProps: {
      sx: theme => ({
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
        position: 'sticky',
      }),
    },
    layoutMode: 'grid',
    renderEmptyRowsFallback: () => (
      <EmptyDecisionList decisionStatus={decisionStatus} />
    ),
    mantineTableBodyRowProps: ({ row }) => {
      const tender = row.original;

      let transform = 'none';
      switch (tender.transitionStyle) {
        case 'slide-right':
          transform = 'translateX(-100%)';
          break;
        case 'slide-left':
          transform = 'translateX(100%)';
          break;
      }

      return {
        onMouseDown: e => {
          redirectTo(
            `/notices/${tender.id}`,
            new UrlHashBuilder().addLocationData().build(),
            undefined,
            e,
          );
        },
        sx: theme => ({
          textDecoration: 'none',
          color: 'inherit',
          ':hover': {
            cursor: 'pointer',
          },
          ':hover > td': {
            backgroundColor: theme.colors.gray[0],
          },
          borderTop: `1px solid ${theme.colors.gray[1]}`,
          transition:
            tender.mounted === false
              ? `all ${exitAnimationDuration}ms ease`
              : 'none',
          transform: tender.mounted === false ? transform : 'none',
        }),
      };
    },
    initialState: {
      density: 'xs',
    },
    filterFns: {
      customGlobalFilter: (row: any, _columnId, filterValue: string) => {
        const { title, buyer, interaction, status } =
          row.original as TenderWithListData;
        const concatenatedValues = `${interaction?.note ?? ''} ${interaction?.nogoReason ?? ''} ${
          interaction?.resultReason ?? ''
        } ${interaction?.owner?.firstName ?? ''} ${
          interaction?.owner?.lastName ?? ''
        } ${tenderStatusToLabel(status)} ${buyer.originalName} ${title}`;
        const normalizeSearch = normalizeString(filterValue);
        const normalizeConcatenatedValues = normalizeString(concatenatedValues);

        return normalizeConcatenatedValues.includes(normalizeSearch);
      },
    },
    // global filters need at least one column to be a string to trigger
    globalFilterFn: 'customGlobalFilter',

    state: { columnFilters: filters, globalFilter },
  };

  switch (decisionStatus) {
    case DecisionStatus.TO_ANALYZE:
      return (
        <ToAnalyzeTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    case DecisionStatus.GO:
      return (
        <GoTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    case DecisionStatus.NOGO:
      return (
        <NogoTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    case DecisionStatus.ANSWERED:
      return (
        <AnsweredTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    case DecisionStatus.WIN:
      return (
        <WinTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    case DecisionStatus.LOSS:
      return (
        <LossTable
          onNoteChange={onNoteChange}
          onTenderDecision={onTenderDecision}
          tableConfig={tableConfig}
          updateRowCount={onTableChange}
        />
      );
    default:
      throw new Error(
        `Unknown status when initializing decision list table for ${decisionStatus} decitions`,
      );
  }
}

type DecisionListTablesProps = {
  onNoteChange: (note: string, tenderId: number) => void;
  onTenderDecision: (
    index: number,
    status: DecisionStatus,
    reason?: string,
  ) => void;
  updateRowCount: (rowCount: number) => void;
  tableConfig: Omit<MRT_TableOptions<TenderWithListData>, 'columns'>;
};

const ToAnalyzeTable = ({
  onNoteChange,
  onTenderDecision,
  updateRowCount,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      assessmentRecapColumn,
      opportunityColumn,
      statusColumn,
      responseDeadlineColumn,
      ownerColumn,
      getContributionsColumn(onNoteChange),
      getActionsColumn(onTenderDecision),
    ],
    [onNoteChange, onTenderDecision],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);

  return <MantineReactTable table={table} />;
};

const GoTable = ({
  onNoteChange,
  onTenderDecision,
  updateRowCount,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      assessmentRecapColumn,
      opportunityColumn,
      statusColumn,
      responseDeadlineColumn,
      ownerColumn,
      getContributionsColumn(onNoteChange),
      getActionsColumn(onTenderDecision),
    ],
    [onNoteChange, onTenderDecision],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);
  return <MantineReactTable table={table} />;
};

const NogoTable = ({
  updateRowCount,
  onNoteChange,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      assessmentRecapColumn,
      opportunityColumn,
      statusColumn,
      ownerColumn,
      nogoColumn,
      getContributionsColumn(onNoteChange),
    ],
    [onNoteChange],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);
  return <MantineReactTable table={table} />;
};

const AnsweredTable = ({
  updateRowCount,
  onNoteChange,
  onTenderDecision,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      opportunityColumn,
      statusColumn,
      answeredAtColumn,
      ownerColumn,
      getContributionsColumn(onNoteChange),
      getActionsColumn(onTenderDecision),
    ],
    [onNoteChange, onTenderDecision],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);
  return <MantineReactTable table={table} />;
};

const WinTable = ({
  updateRowCount,
  onNoteChange,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      opportunityColumn,
      statusColumn,
      ownerColumn,
      winColumn,
      getContributionsColumn(onNoteChange),
    ],
    [onNoteChange],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);
  return <MantineReactTable table={table} />;
};

const LossTable = ({
  updateRowCount,
  onNoteChange,
  tableConfig,
}: DecisionListTablesProps) => {
  const columns = useMemo<MRT_ColumnDef<TenderWithListData>[]>(
    () => [
      opportunityColumn,
      statusColumn,
      ownerColumn,
      lossColumn,
      getContributionsColumn(onNoteChange),
    ],
    [onNoteChange],
  );
  const table = useMantineReactTable({
    columns,
    ...tableConfig,
  });
  const rowCount = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    updateRowCount(rowCount);
  }, [rowCount, updateRowCount]);
  return <MantineReactTable table={table} />;
};

const tenderStatusToLabel = (status: TenderStatus) => {
  switch (status) {
    case TenderStatus.OPEN:
      return 'Ouvert';
    case TenderStatus.CLOSED:
      return 'Fermé';
  }
};
