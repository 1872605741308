import type {
  BidResponse,
  MinimalBidResponse,
  ProposalVersion,
} from '../../../entities/BidResponse';
import { magellanClient } from '../../../infra/axios';
import type { GetBidResponseListResponseDTO } from './dto';

export async function getBidResponseList(): Promise<MinimalBidResponse[]> {
  const response =
    await magellanClient.get<GetBidResponseListResponseDTO>(`/bidResponses`);
  return response.data.results;
}

export async function getBidResponse(id: number): Promise<BidResponse> {
  const response = await magellanClient.get<BidResponse>(`/bidResponses/${id}`);
  return response.data;
}

export async function createBidResponse(
  formData: FormData,
): Promise<BidResponse> {
  {
    const response = await magellanClient.post<BidResponse>(
      `/bidResponses`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data as BidResponse;
  }
}

export async function updateBidResponse(
  bidResponseId: number,
  additionalContext?: string,
  companyRelationWithBuyer?: string,
  companyResponseStrategy?: string,
): Promise<BidResponse> {
  const response = await magellanClient.put(`/bidResponses/${bidResponseId}`, {
    additionalContext,
    companyRelationWithBuyer,
    companyResponseStrategy,
  });
  return response.data;
}
export async function createProposalVersion(
  bidResponseId: number,
  formData: FormData,
): Promise<ProposalVersion> {
  const response = await magellanClient.post(
    `/bidResponses/${bidResponseId}/proposalVersions`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
}

export async function getProposalVersion(
  bidResponseId: number,
  proposalVersionId: number,
): Promise<ProposalVersion> {
  const response = await magellanClient.get<ProposalVersion>(
    `/bidResponses/${bidResponseId}/proposalVersions/${proposalVersionId}`,
  );
  return response.data;
}

export async function deleteBidRequirement(
  bidResponseId: number,
  requirementId: number,
  deletionReason?: string,
) {
  await magellanClient.post(
    `/bidResponses/${bidResponseId}/bidRequirements/${requirementId}/delete`,
    {
      deletionReason,
    },
  );
}
