import { useState } from 'react';

import { Box, Group, Stack, Text } from '@mantine/core';

import { IconPlus } from '@tabler/icons-react';

import { Button } from '../../../../shared/components/UI/Button/Button';

import AIIconSparkle from '../../../../shared/assets/ai_icons/sparkle.svg?react';
import type { ProposalVersion } from '../../../../shared/entities/BidResponse';
import { VersionCardHeader } from './VersionCardHeader';
import { VersionInputs } from './VersionInputs';
import { VersionList } from './VersionList';

type DoneVersionCardProps = {
  selectedProposalVersionId: number | null;
  proposalVersions?: ProposalVersion[];
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};
export const DoneVersionCard = ({
  selectedProposalVersionId,
  proposalVersions,
  bidResponseId,
  setSelectedProposalVersion,
}: DoneVersionCardProps) => {
  return (
    <Stack
      px="06"
      py="05"
      spacing="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      })}
    >
      <VersionCardHeader
        title="Évaluer votre mémoire technique"
        subtitle="Evaluation de vos mémoires techniques par notre intelligence artificielle entrainée sur les meilleures pratiques du marché"
      />
      <SuccessBadge />
      {proposalVersions?.length ? (
        <Stack>
          {selectedProposalVersionId && (
            <VersionList
              selectedProposalVersionId={selectedProposalVersionId}
              proposalVersions={proposalVersions}
              setSelectedProposalVersion={setSelectedProposalVersion}
            />
          )}
          <AdditionalVersionButton
            bidResponseId={bidResponseId}
            setSelectedProposalVersion={setSelectedProposalVersion}
          />
        </Stack>
      ) : (
        <VersionInputs
          bidResponseId={bidResponseId}
          setSelectedProposalVersion={setSelectedProposalVersion}
        />
      )}
    </Stack>
  );
};
const SuccessBadge = () => {
  return (
    <Box
      w="fit-content"
      p="2px"
      sx={theme => ({
        borderRadius: theme.radius.xl,
        background: `linear-gradient(93.26deg, rgba(255, 255, 255, 0.4) -10.36%, rgba(112, 114, 135, 0.4) 142.1%)`,
      })}
    >
      <Group
        noWrap
        w="fit-content"
        px="04"
        py="03"
        spacing="02"
        sx={theme => ({
          borderRadius: '22px',
          borderImageSlice: 1,
          boxShadow: theme.shadows.xs,
          background: 'white',
        })}
      >
        <AIIconSparkle style={{ width: '30px' }} />
        <Text variant="xs" fw="400" c="gray.4">
          Le DCE a été analysé avec succès !
        </Text>
      </Group>
    </Box>
  );
};

type AdditionalVersionButtonProps = {
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

const AdditionalVersionButton = ({
  bidResponseId,
  setSelectedProposalVersion,
}: AdditionalVersionButtonProps) => {
  const [isCreating, setIsCreating] = useState(false);

  if (!isCreating) {
    return (
      <Button
        variant="light"
        color="gray"
        leftIcon={<IconPlus />}
        size="sm"
        w="fit-content"
        onClick={() => setIsCreating(true)}
      >
        Nouvelle version
      </Button>
    );
  }

  return (
    <VersionInputs
      bidResponseId={bidResponseId}
      setSelectedProposalVersion={setSelectedProposalVersion}
    />
  );
};
