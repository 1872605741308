import { useSuspenseQuery } from '@tanstack/react-query';

import { getDecisionLogs } from '../../../../../shared/api/magellan/tender';
import { queryClient } from '../../../../../shared/infra/queryClient';

export function useActivities(tenderId: number) {
  const queryKey = [getDecisionLogs.name, tenderId];
  const queryFn = async () => getDecisionLogs(tenderId);
  const { data: activities } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  return {
    activities,
    refetch,
  };
}
