export class Document {
  id: number;
  originalName: string;
  prettyName: string;
  fileURL: string;
  type: DocumentType;
  section: 'Général' | `Lot ${number}`;
  originalFilePath: string;
}

export enum DocumentType {
  DCE = 'DCE',
  RC = 'RC',
  COMPLEMENT = 'COMPLEMENT',
  AVIS = 'AVIS',
  DUME = 'DUME',
}
