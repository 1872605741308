import { useQuery } from '@tanstack/react-query';

import { getUsers } from '../api/magellan/users';
import type { User } from '../entities/User';

export function useUsers() {
  const queryKey = [getUsers.name];
  const queryFn = async () => getUsers();
  const {
    data: users,
    isError,
    error,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn,
  });

  if (isError) {
    throw error;
  }

  return { users: isLoading ? [] : (users as User[]), isLoading };
}
