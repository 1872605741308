import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  type MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';

import { useUrlHash } from '../../../../../shared/contexts/UrlHash.provider';
import type Tender from '../../../../../shared/entities/Tender';
import { UrlHashBuilder } from '../../../../../shared/utils/navigation/UrlHashBuilder';
import { EmptyBuyerList } from './EmptyBuyerList';
import {
  amountColumn,
  datesColumn,
  decisionStatusColumn,
  durationColumn,
  getActionsColumn,
  oppotunityColumn,
  statusColumn,
} from './columnDefinitions';

type BuyerTenderTableProps = {
  initialTenders: Tender[];
  isFetching: boolean;
  buyerInfiniteQueryKey: string;
  isLoading: boolean;
  onScrollPointReached: () => void;
  onFilterReset: () => void;
};

export function BuyerTenderTable({
  initialTenders,
  isFetching,
  isLoading,
  buyerInfiniteQueryKey,
  onScrollPointReached: fetchNextPage,
  onFilterReset: resetFilters,
}: BuyerTenderTableProps) {
  const { redirectTo } = useUrlHash();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tenders, setTenders] = useState<Tender[]>(initialTenders);

  useEffect(() => {
    setTenders(initialTenders);
  }, [initialTenders]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching],
  );

  const columns = useMemo<MRT_ColumnDef<Tender>[]>(
    () => [
      oppotunityColumn,
      statusColumn,
      datesColumn,
      amountColumn,
      durationColumn,
      decisionStatusColumn,
      getActionsColumn(buyerInfiniteQueryKey),
    ],
    [buyerInfiniteQueryKey],
  );

  const table = useMantineReactTable({
    columns,
    data: tenders,
    memoMode: 'cells',
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    mantinePaperProps: {
      sx: theme => ({
        height: '100%',
        border: 'none !important',
        '& thead': {
          position: 'sticky',
          top: `0px`,
          zIndex: 10,
        },
        '& thead > tr > th': {
          border: 'none !important',
          paddingLeft: theme.spacing['03'],
          paddingRight: theme.spacing['03'],
          paddingTop: 0,
          paddingBottom: 0,
          height: theme.spacing['06'],
          justifyContent: 'center',
        },
        '& thead > tr > th > div ': {
          color: theme.colors.gray[6],
          fontWeight: 500,
          fontSize: theme.fontSizes.xs,
        },
        '& tbody > tr': {
          ':hover': {
            backgroundColor: 'white!important',
          },
        },
        '& tbody > tr > td': {
          padding: theme.spacing['03'],
          borderTop: 'none !important',
          borderBottom: 'none !important',
        },
      }),
    },
    mantineTableContainerProps: {
      ref: tableContainerRef,
      onScroll: event =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
      sx: theme => ({
        height: '90%',
        overflowY: 'auto',
        paddingRight: theme.spacing['05'],
        paddingLeft: theme.spacing['05'],
      }),
    },
    mantineTableHeadRowProps: {
      sx: theme => ({
        display: tenders.length === 0 ? 'none' : 'flex',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
        position: 'sticky',
      }),
    },
    layoutMode: 'grid',
    renderEmptyRowsFallback: () => {
      return isLoading ? null : <EmptyBuyerList onFilterReset={resetFilters} />;
    },
    mantineTableBodyRowProps: ({ row }) => {
      const tender = row.original;

      return {
        onMouseDown: e => {
          redirectTo(
            `/notices/${tender.id}`,
            new UrlHashBuilder().addLocationData().build(),
            undefined,
            e,
          );
        },
        sx: theme => ({
          ':hover': {
            cursor: 'pointer',
          },
          ':hover > td': {
            backgroundColor: theme.colors.gray[0],
          },
          borderTop: `1px solid ${theme.colors.gray[1]}`,
        }),
      };
    },
    initialState: {
      density: 'xs',
    },
    state: {
      showSkeletons: isLoading,
      showProgressBars: isLoading,
    },
  });

  return <MantineReactTable table={table} />;
}
