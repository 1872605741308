import * as Sentry from '@sentry/react';
import axios from 'axios';

const magellanClient = axios.create({
  baseURL: import.meta.env.VITE_MAGELLAN_BASE_URL,
});

magellanClient.interceptors.response.use(
  response => {
    if (response.status === 400 || response.status === 404) {
      throw new Error('Not found');
    }
    return response;
  },
  error => {
    Sentry.captureException(error, {
      tags: {
        section: error.config ? error.config.url : 'unknown', // Using the URL as a tag to be searchable in Sentry
      },
      extra: {
        request: {
          url: error.config?.url,
          method: error.config?.method,
          data: error.config?.data,
          headers: error.config?.headers,
        },
        response: {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
        },
      },
    });

    return Promise.reject(error);
  },
);

export { magellanClient };
