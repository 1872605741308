import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getDecisionCounts } from '../../../shared/api/magellan/interaction';
import { DecisionStatus } from '../../../shared/entities/Interaction';

export type DecisionCounts = {
  [K in DecisionStatus]: number;
};

export function useDecisionCount() {
  const [decisionCounts, setDecisionCounts] = useState<DecisionCounts>();

  useQuery({
    queryKey: [getDecisionCounts.name],
    queryFn: async () => {
      const decisionCounts = await getDecisionCounts();
      setDecisionCounts(decisionCounts.counts);
      return decisionCounts;
    },
  });

  const updateDecisionCounts = (status: DecisionStatus) => {
    setDecisionCounts(decisionCounts => {
      if (!decisionCounts) return;
      const newDecisionCounts = { ...decisionCounts };
      switch (status) {
        case DecisionStatus.GO:
          newDecisionCounts[DecisionStatus.TO_ANALYZE]--;
          newDecisionCounts[DecisionStatus.GO]++;
          break;
        case DecisionStatus.NOGO:
          newDecisionCounts[DecisionStatus.TO_ANALYZE]--;
          newDecisionCounts[DecisionStatus.NOGO]++;
          break;
        case DecisionStatus.ANSWERED:
          newDecisionCounts[DecisionStatus.GO]--;
          newDecisionCounts[DecisionStatus.ANSWERED]++;
          break;
        case DecisionStatus.WIN:
          newDecisionCounts[DecisionStatus.ANSWERED]--;
          newDecisionCounts[DecisionStatus.WIN]++;
          break;
        case DecisionStatus.LOSS:
          newDecisionCounts[DecisionStatus.ANSWERED]--;
          newDecisionCounts[DecisionStatus.LOSS]++;
          break;
      }
      return newDecisionCounts;
    });
  };

  return {
    decisionCounts,
    updateDecisionCounts,
  };
}
