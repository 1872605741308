import { useState } from 'react';

import { ActionIcon, Group, Menu, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { UserWithAvatar } from '../../../../../shared/components/UI/UserWithAvatar/UserWithAvatar';

import { useActiveUser } from '../../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import type { Comment } from '../../../../../shared/entities/Comment';
import { useNoticeContext } from '../../../../notice/contexts/Notice.provider';
import { useThreads } from '../hooks/useThreads.hook';
import { CommentContent } from './CommentContent';
import { EditComment } from './EditComment';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.locale('fr');

type CommentComponentProps = {
  comment: Comment;
  PreviewCard?: React.ReactNode;
};

export function CommentComponent({
  comment,
  PreviewCard,
}: CommentComponentProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const formatedDate = dayjs(comment.updatedAt).fromNow();
  const hasBeenEdited = comment.updatedAt !== comment.createdAt;

  if (isEditing) {
    return (
      <EditComment
        comment={comment}
        onEdit={() => {
          setIsEditing(false);
          setIsHovered(false);
        }}
      />
    );
  }

  return (
    <Stack spacing="01">
      <Group
        noWrap
        h="26px"
        position="apart"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Group noWrap spacing="02" maw="320px">
          <UserWithAvatar
            avatarColor={comment.author.avatarColor}
            firstName={comment.author.firstName}
            lastName={comment.author.lastName}
            isDisabled={!!comment.author.disabledAt}
            fw={500}
            py={0}
            w="fit-content"
          />
          <Text
            variant="xs"
            fw={400}
            c="gray.4"
            sx={{ whiteSpace: 'nowrap' }}
            truncate
          >
            {formatedDate + (hasBeenEdited ? ' (modifié)' : '')}
          </Text>
        </Group>
        <CommentMenu
          isParentHovered={isHovered}
          comment={comment}
          onEdit={() => setIsEditing(true)}
        />
      </Group>
      <CommentContent content={comment.content} PreviewCard={PreviewCard} />
    </Stack>
  );
}

type CommentMenuProps = {
  isParentHovered: boolean;
  comment: Comment;
  onEdit: () => void;
};

const CommentMenu = ({
  isParentHovered,
  comment,
  onEdit,
}: CommentMenuProps) => {
  const { activeUser } = useActiveUser();
  const { tender } = useNoticeContext();
  const { deleteComment } = useThreads(tender.id);
  const [opened, setOpened] = useState(false);

  const isActiveUserCommentAuthor = activeUser.id === comment.author.id;
  if ((!isParentHovered && !opened) || !isActiveUserCommentAuthor) {
    return null;
  }

  const openModal = () =>
    modals.openContextModal({
      modal: 'commentDelete',
      innerProps: { onDelete: () => deleteComment(comment.id) },
    });

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    openModal();
  };
  const handleEdit = () => {
    onEdit();
    setOpened(false);
  };

  return (
    <Menu
      shadow="md"
      width={220}
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      withinPortal
    >
      <Menu.Target>
        {
          <ActionIcon onClick={() => setOpened(!opened)}>
            <IconDotsVertical size={16} />
          </ActionIcon>
        }
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          c="dark.7"
          icon={<IconPencil size={14} stroke={1.5} />}
          onClick={handleEdit}
        >
          Éditer le commentaire
        </Menu.Item>
        <Menu.Item
          c="red.6"
          icon={<IconTrash size={14} stroke={1.5} />}
          onClick={handleDeleteClick}
        >
          Supprimer
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
