import { useCallback } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { getOnTheFlyInstantAnalysis } from '../api/magellan/instantAnalysis/answers';
import { triggerOnTheFlyQuestionAnalysis } from '../api/magellan/tender';
import { queryClient } from '../infra/queryClient';
import { useInstantAnalysis } from './useInstantAnalysis.hook';

export function useOnTheFlyQuestionsSetsAnalysis(tenderId: number) {
  const queryKey = [getOnTheFlyInstantAnalysis.name, tenderId];
  const queryFn = async () => getOnTheFlyInstantAnalysis(tenderId);
  const { data: otfAnalysis } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  const { status, retry, onTriggerInstantAnalysis } = useInstantAnalysis(
    refetch,
    otfAnalysis.status,
  );

  const triggerOnTheFlyQuestionAnalysisCallback = useCallback(
    async (question: string) => {
      const res = await triggerOnTheFlyQuestionAnalysis(tenderId, question);
      if (res === 201) {
        onTriggerInstantAnalysis();
      }
    },
    [onTriggerInstantAnalysis, tenderId],
  );

  return {
    status,
    answers: otfAnalysis.answers,
    triggerOnTheFlyQuestionAnalysis: triggerOnTheFlyQuestionAnalysisCallback,
    retry,
    refetch,
  };
}
