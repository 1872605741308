import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import frCommon from '../locales/fr/common.json';
import frExample from '../locales/fr/example.json';

const resources = {
  fr: {
    common: frCommon,
    example: frExample,
  },
};

export function initI18next() {
  i18n.use(initReactI18next).init({
    resources,
    ns: ['common', 'example'],
    fallbackLng: 'fr',
    lng: 'fr',
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
}
