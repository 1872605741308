import { Group, Skeleton } from '@mantine/core';

import AIIconSparkle from '../../../../../shared/assets/ai_icons/sparkle.svg?react';

export const CellLoader = () => {
  return (
    <Group noWrap spacing="02">
      <AIIconSparkle />
      <Skeleton height={10} maw={200} />
    </Group>
  );
};
