import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { getTenderForBuyer } from '../../../shared/api/magellan/buyer';
import type { BuyerTableFilter } from '../components/TendersTabContent';
import { BuyerTableFilterIds } from '../components/tenderTable/components/BuyerTenderFilters';

export function useBuyerTenders(buyerId: number, fitlers: BuyerTableFilter[]) {
  const streamIds =
    fitlers.find(filter => filter.id === BuyerTableFilterIds.STREAM)?.value ??
    [];
  const queryKey = [getTenderForBuyer.name, buyerId, streamIds];

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam: { skip: number; take: number } }) =>
      getTenderForBuyer(buyerId, pageParam.skip, pageParam.take, streamIds),
    initialPageParam: { skip: 0, take: 50 },
    getNextPageParam: lastPage => {
      return {
        skip: lastPage.pagination.skip + lastPage.pagination.take,
        take: 50,
      };
    },
  });

  const tenders = useMemo(
    () => data?.pages.flatMap(page => page.results) ?? [],
    [data],
  );
  const totalFetched = tenders.length;

  return {
    // returning the key as a string to enable memoization by stringifying the array to enbable deep comparison instead of shallow reference comparison
    buyerInfiniteQueryKey: JSON.stringify(queryKey),
    tenders,
    totalFetched,
    totalResults: data?.pages[0].pagination.totalResults,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    status,
  };
}
