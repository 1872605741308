import { useEffect, useState } from 'react';

import { Accordion, Divider, Text } from '@mantine/core';

import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import type {
  AiRelevanceFeedbackStatus,
  Answer,
} from '../../../shared/entities/InstantAnalysisAnswer';
import { useScrollToElement } from '../../notice/contexts/ScrollToElement.provider';
import { AnswerAccordion } from './AnswerAccordion';

type QuestionsSetAccordionProps = {
  questionsSetTitle: string;
  initialAnswers: Answer[];
  filter: AssessmentStatus | null;
};

export function QuestionsSetAccordion({
  questionsSetTitle,
  initialAnswers,
  filter,
}: QuestionsSetAccordionProps) {
  const [answers, setAnswers] = useState<Answer[]>(initialAnswers);
  const { elementRefs } = useScrollToElement();

  useEffect(() => {
    setAnswers(initialAnswers);
  }, [initialAnswers]);

  const onNewAnswer = (newAnswer: Answer) => {
    const newAnswers = answers.map(answer => {
      if (answer.id === newAnswer.id) {
        return newAnswer;
      }
      return answer;
    });
    setAnswers(newAnswers);
  };

  const onNewAssessment = (
    newAssessment: AssessmentStatus | null,
    answerId: number,
  ) => {
    const newAnswers = answers.map(answer => {
      if (answer.id === answerId) {
        return { ...answer, assessment: newAssessment };
      }
      return answer;
    });
    setAnswers(newAnswers);
  };

  const onNewAiRelevanceFeedback = (
    newAiRelevanceFeedback: AiRelevanceFeedbackStatus | null,
    answerId: number,
  ) => {
    const newAnswers = answers.map(answer => {
      if (answer.id === answerId) {
        return { ...answer, aiRelevanceFeedback: newAiRelevanceFeedback };
      }
      return answer;
    });
    setAnswers(newAnswers);
  };

  const filteredAnswers =
    filter &&
    answers.filter(answer => {
      if (filter === AssessmentStatus.UNASSESSED) {
        return !answer.assessment;
      }
      return answer.assessment === filter;
    });

  const answersList = filteredAnswers || answers;

  return (
    <Accordion
      unstyled
      chevronPosition="right"
      defaultValue={`${questionsSetTitle}`}
      styles={theme => ({
        chevron: {
          display: 'flex',
          alignItems: 'center',
          marginLeft: theme.spacing['02'],
          '&[data-rotate]': { transform: 'rotate(180deg)' },
        },
        label: {
          paddingTop: theme.spacing['00'],
          paddingBottom: theme.spacing['00'],
        },
        control: {
          borderRadius: theme.radius.md,
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          border: 'none',
        },
      })}
    >
      <Accordion.Item value={`${questionsSetTitle}`} sx={{ border: 'none' }}>
        <Accordion.Control px="02" py={0} bg="gray.1">
          <Text variant="sm" fw={500} c="gray.6">
            {questionsSetTitle}
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          {answersList.map(answer => (
            <AnswerAccordion
              key={answer.id}
              ref={el => (elementRefs.current[answer.id] = el)}
              answer={answer}
              onNewAnswer={onNewAnswer}
              onNewAssessment={onNewAssessment}
              onNewAiRelevanceFeedback={onNewAiRelevanceFeedback}
            />
          ))}
          <Divider color="gray.1" mt="lg" />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
