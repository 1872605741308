import { Accordion, Divider, Group, Image, Stack, Text } from '@mantine/core';

import { IconExternalLink, IconLink } from '@tabler/icons-react';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';
import { Counter } from '../../../../shared/components/UI/Counter/Counter';
import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import { CPVTooltipContent } from '../../../../shared/components/CPVRow';
import { TenderCategoryMap } from '../../../../shared/entities/Category';
import EuropeTedSvg from '../../assets/europe_ted.svg?react';
import MarianneImg from '../../assets/marianne.jpeg';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function ComplementaryDataCard() {
  return (
    <BrandedCard title="Informations complémentaires" w="100%">
      <Stack spacing={0}>
        <Stack spacing="03" pb="03">
          <TenderCategory />
          <InternalBuyerRef />
        </Stack>
        <CpvSection />
        <Stack spacing="03">
          <BoampLink />
          <OjeuLink />
          <BuyerProfileLink />
        </Stack>
      </Stack>
    </BrandedCard>
  );
}

const TenderCategory = () => {
  const { tender } = useNoticeContext();
  if (!tender?.category) {
    return;
  }
  return (
    <Group noWrap spacing="01" position="apart">
      <Text variant="sm" fw={400} c="gray.6">
        Type de marché
      </Text>
      <Text variant="sm" fw={400} color="gray.9">
        {TenderCategoryMap.get(tender?.category)}
      </Text>
    </Group>
  );
};

const CpvSection = () => {
  const { tender } = useNoticeContext();
  const { cpvs } = tender;
  if (!cpvs || cpvs.length === 0) {
    return;
  }

  return (
    <Stack spacing={0}>
      <Divider orientation="horizontal" color="gray.1" />
      <Accordion
        styles={theme => ({
          chevron: {
            color: theme.colors.gray[4],
            marginLeft: 0,
            marginRight: '-6px',
            transform: 'rotate(-90deg)',
            '&[data-rotate]': {
              transform: 'rotate(0deg)',
            },
            transition: 'transform 300ms ease',
          },
          content: {
            padding: '0',
            marginTop: theme.spacing['02'],
            marginBottom: theme.spacing['03'],
          },
          label: {
            padding: '0',
            paddingTop: theme.spacing['03'],
            paddingBottom: theme.spacing['03'],
          },
        })}
      >
        <Accordion.Item value="cpv" sx={{ border: 'none' }}>
          <Accordion.Control p={0}>
            <Group noWrap spacing="01" position="apart">
              <Text variant="sm" fw={400} c="gray.6">
                CPV
              </Text>
              <Counter
                value={tender?.cpvs?.length}
                color="gray"
                variant="subtle"
                size="xs"
              />
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Tooltip
              content={<CPVTooltipContent cpvs={tender.cpvs} />}
              dropDownProps={{
                bg: 'white',
                p: '02',
              }}
            >
              <Text variant="sm" fw={400} c="gray.9">
                {tender?.cpvs?.map(cpv => cpv.title).join(', ')}
              </Text>
            </Tooltip>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
};

const BoampLink = () => {
  const { tender } = useNoticeContext();
  if (!tender?.boampId) {
    return;
  }
  return (
    <Stack spacing="03">
      <Divider orientation="horizontal" color="gray.1" />
      <Group
        c="primary.6"
        w="fit-content"
        spacing="02"
        onClick={() =>
          window.open(
            `https://www.boamp.fr/pages/avis/?q=idweb:"${tender.boampId}"`,
            '_blank',
          )
        }
        sx={{
          ':hover': { cursor: 'pointer' },
        }}
      >
        <Image
          src={MarianneImg}
          radius="sm"
          width={18}
          height={18}
          color="white"
        />
        <Text variant="sm" fw={500} c="primary.6">
          Avis BOAMP (N°{tender.boampId})
        </Text>
        <IconExternalLink size={16} />
      </Group>
    </Stack>
  );
};

const OjeuLink = () => {
  const { tender } = useNoticeContext();

  if (!tender?.ojeuId) {
    return;
  }

  // Ojeu IDS are sometimes in the format "N°2023/S 186-582241" and sometimes "582241-2023"
  // By default we consider the latter format, but if the former is detected, we build it to the expected URL format
  let ojeuId = tender.ojeuId;

  if (!ojeuId.match(/^[-0-9]*$/)?.at(0)) {
    const year = ojeuId.match(/([0-9]{4})/)?.at(0);
    const id = ojeuId.match(/([0-9]*$)/)?.at(0);

    if (!year || !id) {
      return null;
    }
    ojeuId = `${id}-${year}`;
  }

  return (
    <Stack spacing="03">
      <Divider orientation="horizontal" color="gray.1" />
      <Group
        c="primary.6"
        w="fit-content"
        spacing="02"
        onClick={() =>
          window.open(
            `https://ted.europa.eu/fr/notice/-/detail/${ojeuId}`,
            '_blank',
          )
        }
        sx={{
          ':hover': { cursor: 'pointer' },
        }}
      >
        <EuropeTedSvg
          style={{ width: '17px', height: '19px', borderRadius: '40px' }}
        />
        <Text variant="sm" fw={500} c="primary.6">
          Avis JOUE (N°{tender.ojeuId})
        </Text>
        <IconExternalLink size={16} />
      </Group>
    </Stack>
  );
};

const InternalBuyerRef = () => {
  const { tender } = useNoticeContext();
  if (!tender?.buyerInternalReference) {
    return;
  }
  return (
    <Stack spacing="03">
      <Divider orientation="horizontal" color="gray.1" />
      <Group noWrap spacing="01" position="apart">
        <Text variant="sm" fw={400} c="gray.6">
          Référence acheteur
        </Text>
        <Text variant="sm" fw={400} color="gray.9">
          {tender.buyerInternalReference}
        </Text>
      </Group>
    </Stack>
  );
};

const BuyerProfileLink = () => {
  const { tender } = useNoticeContext();
  if (!tender?.buyerProfileParticipationURL) {
    return;
  }

  const buyerProfileParticipationURL =
    tender.buyerProfileParticipationURL.startsWith('http')
      ? tender.buyerProfileParticipationURL
      : `https://${tender.buyerProfileParticipationURL}`;

  return (
    <Stack spacing="03">
      <Divider orientation="horizontal" color="gray.1" />
      <Group
        c="primary.6"
        w="fit-content"
        spacing="02"
        onClick={() => window.open(buyerProfileParticipationURL, '_blank')}
        sx={{
          ':hover': { cursor: 'pointer' },
        }}
      >
        <IconLink size={16} />
        <Text variant="sm" fw={500} c="primary.6">
          Plateforme question/réponses
        </Text>
        <IconExternalLink size={16} />
      </Group>
    </Stack>
  );
};
