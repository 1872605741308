import type { Thread, ThreadElementType } from '../../../entities/Thread';
import { magellanClient } from '../../../infra/axios';

export async function getThreads(
  tenderId: number,
  type: ThreadElementType,
): Promise<Thread[]> {
  const response = await magellanClient.get<Thread[]>(
    `/threads?tenderId=${tenderId}&type=${type}`,
  );

  return response.data;
}
