import { useState } from 'react';

import { Group, Stack, Text, TextInput } from '@mantine/core';

import { IconSearch } from '@tabler/icons-react';

import { Button } from '../../../shared/components/UI/Button/Button';

type OnTheFlyQuestionInputProps = {
  triggerOnTheFlyQuestionAnalysis: (question: string) => void;
};

export function OnTheFlyQuestionInput({
  triggerOnTheFlyQuestionAnalysis,
}: OnTheFlyQuestionInputProps) {
  const [question, setQuestion] = useState<string>('');

  const handleNewOTFQuestion = () => {
    triggerOnTheFlyQuestionAnalysis(question);
    setQuestion('');
  };

  return (
    <Stack spacing={8}>
      <Stack spacing={4}>
        <Text variant="md" fw={500} c="dark.9">
          Poser une nouvelle question
        </Text>
        <Text variant="sm" fw={400} color="dark.2" mt={2}>
          Cette question ne sera pas ajoutée au jeu de questions
        </Text>
      </Stack>
      <Group
        p="4px"
        noWrap
        w="100%"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <TextInput
          w="100%"
          value={question}
          onChange={event => setQuestion(event.currentTarget.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              handleNewOTFQuestion();
            }
          }}
          placeholder="Est-ce qu'il y a des pénalités ? Quels sont les critères de choix de l'acheteur ? ..."
          icon={<IconSearch size={16} />}
          styles={theme => ({
            input: {
              border: 'none',
              '&::placeholder': {
                color: theme.colors.gray[3],
              },
            },
          })}
        />

        <Button
          w={166}
          disabled={question.length === 0}
          size="sm"
          onClick={handleNewOTFQuestion}
        >
          Poser ma question
        </Button>
      </Group>
    </Stack>
  );
}
