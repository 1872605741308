import { Group, Stack, Text, Title } from '@mantine/core';
import type { ContextModalProps } from '@mantine/modals';

import { IconCheck, IconTrash } from '@tabler/icons-react';

import { Button } from '../UI/Button/Button';

type StreamDiscardModalProps = {
  isFormValid: boolean;
  onSubmit: () => void;
  onDiscard: () => void;
};

export const StreamDiscardModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<StreamDiscardModalProps>) => {
  const { isFormValid, onSubmit, onDiscard } = innerProps;

  return (
    <Stack py="03" px="04" spacing="01">
      <Title order={5}>Sauvegarder les modifications ?</Title>
      <Text variant="sm" fw="400" c="gray.6">
        Souhaitez-vous conserver les modifications apportées ?
      </Text>
      <Group noWrap position="right" mt="lg">
        <Button
          color="red"
          leftIcon={<IconTrash size={18} />}
          onClick={() => {
            onDiscard();
            context.closeModal(id);
          }}
        >
          Annuler les modifications
        </Button>
        {isFormValid ? (
          <Button
            leftIcon={<IconCheck size={18} />}
            onClick={() => {
              onSubmit();
              context.closeModal(id);
            }}
            color="primary"
          >
            Sauvegarder les modifications
          </Button>
        ) : (
          <Button
            leftIcon={<IconCheck size={18} />}
            onClick={() => {
              context.closeModal(id);
            }}
            color="primary"
          >
            Rester sur la page
          </Button>
        )}
      </Group>
    </Stack>
  );
};
