import * as Sentry from '@sentry/react';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import {
  createComment,
  createThread,
  deleteComment,
  updateComment,
} from '../../../../../shared/api/magellan/comments';
import { getThreads } from '../../../../../shared/api/magellan/threads';
import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';
import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { ThreadElementType } from '../../../../../shared/entities/Thread';
import { useOnTheFlyQuestionsSetsAnalysis } from '../../../../../shared/hooks/useOnTheFlyQuestionAnalysis.hook';
import { useQuestionsSetsAnalysis } from '../../../../../shared/hooks/useQuestionsSetsAnalysis.hook';
import { queryClient } from '../../../../../shared/infra/queryClient';

type CreateThreadType = {
  content: RichTextJson;
  threadId?: number;
  instantAnalysisAnswerId?: number;
};

type DeleteCommentType = {
  commentId: number;
};

type UpdateCommentType = {
  commentId: number;
  content: RichTextJson;
};

export function useThreads(tenderId: number) {
  const queryKey = [getThreads.name, tenderId];
  const queryFn = async () =>
    getThreads(tenderId, ThreadElementType.INTERACTION);
  const { refetch: refetchAnalysis } = useQuestionsSetsAnalysis(tenderId);
  const { refetch: refetchOnTheFlyAnalysis } =
    useOnTheFlyQuestionsSetsAnalysis(tenderId);
  const { data: threads } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const handleError = (error: Error, message: string) => {
    Sentry.captureException(error);
    displayErrorNotification('Erreur', message);

    throw Error(`Error on threads: ${message}`);
  };

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  const threadMutation = useMutation({
    mutationFn: ({
      content,
      threadId,
      instantAnalysisAnswerId,
    }: CreateThreadType) => {
      if (threadId) {
        return createComment(
          tenderId,
          threadId,
          content,
          instantAnalysisAnswerId,
        );
      }
      return createThread(tenderId, content, instantAnalysisAnswerId);
    },
    onSuccess: (_data, variable) => {
      refetch();
      if (variable.instantAnalysisAnswerId) {
        refetchAnalysis();
        refetchOnTheFlyAnalysis();
      }
    },
    onError: error => {
      handleError(
        error,
        'Une erreur a eu lieu lors de la creation de votre commentaire',
      );
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: ({ commentId }: DeleteCommentType) => deleteComment(commentId),
    onSuccess: refetch,
    onError: error => {
      handleError(
        error,
        'Une erreur a eu lieu lors de la suppression de votre commentaire',
      );
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: ({ commentId, content }: UpdateCommentType) =>
      updateComment(commentId, content),
    onSuccess: refetch,
    onError: error => {
      handleError(
        error,
        "Une erreur a eu lieu lors de l'edition de votre commentaire",
      );
    },
  });

  const commentCounter =
    threads?.reduce((acc, thread) => acc + thread.comments.length, 0) || 0;

  return {
    threads,
    createThread: (props: Omit<CreateThreadType, 'threadId'>) =>
      threadMutation.mutate(props),
    createComment: (props: CreateThreadType) => threadMutation.mutate(props),
    deleteComment: (commentId: number) =>
      deleteCommentMutation.mutate({ commentId }),
    updateComment: (commentId: number, content: RichTextJson) =>
      updateCommentMutation.mutate({ commentId, content }),
    commentCounter,
  };
}
