import { useQuery } from '@tanstack/react-query';

import { getBuyersByName } from '../api/magellan/buyer';
import type { MinimalBuyerDTO } from '../api/magellan/buyer/dto';

export function useBuyerSearch(searchQuery: string) {
  const queryKey = [getBuyersByName.name, searchQuery];
  const queryFn = async (): Promise<MinimalBuyerDTO[]> => {
    if (searchQuery.length > 1) {
      return getBuyersByName(searchQuery);
    }
    return [];
  };

  const {
    data: buyers = [],
    isError,
    error,
    isLoading,
  } = useQuery<MinimalBuyerDTO[], Error>({
    queryKey,
    queryFn,
  });

  if (isError) {
    throw error;
  }

  return { buyers, isLoading };
}
