import { Box, Group, useMantineTheme } from '@mantine/core';

import {
  IconThumbDown,
  IconThumbDownOff,
  IconThumbUp,
  IconThumbUpOff,
} from '@tabler/icons-react';

import { AiRelevanceFeedbackStatus } from '../../../shared/entities/InstantAnalysisAnswer';
import { AiRelevanceFeedbackButton } from './AiRelevanceFeedbackButton';

type AiRelevanceFeedbackProps = {
  feedback: AiRelevanceFeedbackStatus | null;
  handleFeedback: (buttonType: AiRelevanceFeedbackStatus) => void;
};

export const AiRelevanceFeedback = ({
  feedback,
  handleFeedback,
}: AiRelevanceFeedbackProps) => {
  const theme = useMantineTheme();

  return (
    <Group spacing="02">
      <AiRelevanceFeedbackButton
        feedback={feedback}
        handleFeedback={handleFeedback}
        buttonType={AiRelevanceFeedbackStatus.BAD}
        Icon={IconThumbDown}
        HoverIcon={IconThumbDownOff}
        colorConfig={{
          unselected: theme.colors.gray[4],
          unselectedHover: theme.colors.gray[5],
          selected: theme.colors.red[5],
          selectedHover: theme.colors.red[6],
          otherSelected: theme.colors.gray[3],
        }}
        tooltipConfig={{
          unselected: `Evaluer cette réponse de l'I.A comme insatisfaisante`,
          selected: 'Retirer',
        }}
      />
      <Box w={1} h="12px" bg="gray.3" />
      <AiRelevanceFeedbackButton
        feedback={feedback}
        handleFeedback={handleFeedback}
        buttonType={AiRelevanceFeedbackStatus.GOOD}
        Icon={IconThumbUp}
        HoverIcon={IconThumbUpOff}
        colorConfig={{
          unselected: theme.colors.gray[4],
          unselectedHover: theme.colors.gray[5],
          selected: theme.colors.primary[6],
          selectedHover: theme.colors.primary[7],
          otherSelected: theme.colors.gray[3],
        }}
        tooltipConfig={{
          unselected: `Evaluer cette réponse de l'I.A comme satisfaisante`,
          selected: 'Retirer',
        }}
      />
    </Group>
  );
};
