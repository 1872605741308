import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { Box, Group, Loader, MultiSelect, Stack, Text } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import {
  getCpvByCode,
  searchCPV,
} from '../../../../../shared/api/magellan/cpv';
import type { Cpv } from '../../../../../shared/entities/Cpv';
import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';
import { removeTrailingZeros } from '../../../../../shared/utils/strings';
import TooltipWithIcon from './TooltipWithIcon';

interface CPVItemProps extends ComponentPropsWithoutRef<'div'> {
  code: string;
  title: string;
  value: string;
  label: string;
}

const SelectCPVItem = forwardRef<HTMLDivElement, CPVItemProps>(
  (props: CPVItemProps, ref) => {
    const { code, title, ...others } = props;
    return (
      <Box ref={ref} {...others}>
        <Text truncate>
          {removeTrailingZeros(code)} - {title}
        </Text>
      </Box>
    );
  },
);

type CPVMultiselectProps = {
  form: UseFormReturnType<
    StreamFormValues,
    (values: StreamFormValues) => StreamFormValues
  >;
};

export default function CPVMultiselect({ form }: CPVMultiselectProps) {
  const [CPVs, setCPVs] = useState<CPVItemProps[]>([]);
  const [CPVsSelected, setCPVsSelected] = useState<Cpv[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleValueChanges = (values: string[]) => {
    const CPVsFromValues = values
      .map(code => CPVs.find(CPV => CPV.code === code))
      .filter(Boolean) as Cpv[];

    setCPVsSelected(CPVsFromValues);
    setValues(values);
    form.setValues({ ...form.values, cpvList: values });
  };

  useEffect(() => {
    const fetchCPVs = async () => {
      const fetchedCPVs = await searchCPV(searchValue);
      const combinedCPVs = [...fetchedCPVs, ...CPVsSelected];

      const CPVsWithValue = combinedCPVs.map(CPV => ({
        ...CPV,
        label: `${removeTrailingZeros(CPV.code)} - ${CPV.title}`,
        value: CPV.code,
      }));

      setCPVs(CPVsWithValue);
      setIsLoading(false);
    };

    if (searchValue.length > 1) {
      setIsLoading(true);
      fetchCPVs();
    }
  }, [CPVsSelected, searchValue]);

  useEffect(() => {
    const setInitialValues = async (initialCPVCodes: string[]) => {
      const CPVs: Cpv[] = await Promise.all(
        initialCPVCodes.map(async code => {
          return await getCpvByCode(code);
        }),
      );
      const CPVsItem = CPVs.map(CPV => ({
        ...CPV,
        label: `${removeTrailingZeros(CPV.code)} - ${CPV.title}`,
        value: CPV.code,
      }));

      setCPVs(CPVsItem);
      setCPVsSelected(CPVs);
      setValues(initialCPVCodes);
    };
    if (form.values.cpvList?.length) {
      setInitialValues(form.values.cpvList);
    }
  }, [form.values.cpvList]);

  const nothingFound = isLoading ? <Loader size="sm" /> : 'Aucun CPV trouvé';
  return (
    <Stack spacing="md">
      <Group w="100%">
        <Text variant="md" fw="600" c="gray.9">
          Code CPV
        </Text>
        <TooltipWithIcon
          content="
              Le code CPV est une nomenclature européenne pour définir le type d'achat d'un marché
              public. Elle contient plus de 5000 catégories d'achats. Les CPV sont organisés par
              niveaux hiérarchiques. Plus le code CPV se termine par un grand nombre de chiffres 0,
              plus il s'agit d'un CPV d'un niveau élevé (ex: 80000000 regroupe de nombreux CPV dont
              le CPV 80533100 qui est très ciblé)."
        />
      </Group>
      <MultiSelect
        placeholder="90721700, Formation bureautique, ..."
        radius="md"
        searchable
        value={values}
        onChange={handleValueChanges}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        limit={30}
        nothingFound={
          searchValue.length < 2
            ? 'Commencez à taper pour afficher des suggestions'
            : nothingFound
        }
        itemComponent={SelectCPVItem}
        data={CPVs}
        maxDropdownHeight={160}
        filter={(_value, selected) => {
          return !selected;
        }}
      />
    </Stack>
  );
}
