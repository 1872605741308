import { Stack, Text, Title } from '@mantine/core';

type VersionCardHeaderProps = {
  title: string;
  subtitle: string;
};

export const VersionCardHeader = ({
  title,
  subtitle,
}: VersionCardHeaderProps) => {
  return (
    <Stack spacing="00">
      <Title order={4}>{title}</Title>
      <Text variant="sm" fw="400" c="gray.5">
        {subtitle}
      </Text>
    </Stack>
  );
};
