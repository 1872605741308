import { useEffect, useState } from 'react';

import {
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { type FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';

import { IconInfoSquareRounded, IconPaperclip } from '@tabler/icons-react';

import { Button } from '../../../shared/components/UI/Button/Button';
import { TextArea } from '../../../shared/components/UI/TextArea/TextArea';
import { TextInput } from '../../../shared/components/UI/TextInput/TextInput';

import { useUrlHash } from '../../../shared/contexts/UrlHash.provider';
import { ResponseDocType } from '../../../shared/entities/BidResponse';
import type { BidResponse } from '../../../shared/entities/BidResponse';
import { UrlHashBuilder } from '../../../shared/utils/navigation/UrlHashBuilder';
import { useCreateBidResponse } from '../hooks/useCreateBidResponse.hook';
import { DropZoneInput } from './DropZoneInput';

type CreateBidResponseModalProps = {
  opened: boolean;
  closeModal: () => void;
};

export function CreateBidResponseModal({
  opened,
  closeModal,
}: CreateBidResponseModalProps) {
  const { redirectTo } = useUrlHash();
  const [bidResponse, setBidResponse] = useState<BidResponse | null>(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [step, setStep] = useState(0);

  const redirectToBuyerSimulationPage = () => {
    if (!bidResponse) {
      // for now we close and throw. We should handle this case more gracefully later on
      closeModal();
      throw new Error('BidResponse is not defined');
    }
    redirectTo(
      `/reponse/${bidResponse.id}`,
      new UrlHashBuilder().addLocationData().build(),
    );
  };

  const goToNextStep = () => {
    const nextStep = step + 1;
    if (nextStep > steps.length - 1) {
      redirectToBuyerSimulationPage();
      return;
    }
    setStep(nextStep);
  };

  useEffect(() => {
    if (!bidResponse && step) {
      setIsOverlayVisible(true);
    } else {
      setIsOverlayVisible(false);
    }
  }, [bidResponse, step]);

  const steps = [
    <CreateBidResponse
      goToNextStep={goToNextStep}
      closeModal={closeModal}
      setBidResponse={setBidResponse}
    />,
    <AddAdditionnalContext
      goToNextStep={goToNextStep}
      closeModal={closeModal}
      bidResponseId={bidResponse?.id}
    />,
  ];

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      size="lg"
      withCloseButton={false}
      p={0}
      styles={theme => ({
        body: {
          padding: 0,
        },
        content: {
          borderRadius: theme.radius.md,
        },
      })}
    >
      <LoadingOverlay visible={isOverlayVisible} overlayBlur={2} />
      <Stack>{steps[step]}</Stack>
    </Modal>
  );
}

type StepsProps = {
  goToNextStep: () => void;
  closeModal: () => void;
};

const CreateBidResponse = ({
  goToNextStep,
  closeModal,
  setBidResponse,
}: StepsProps & {
  setBidResponse: (bidResponse: BidResponse) => void;
}) => {
  const { createBidResponse } = useCreateBidResponse();
  const [proposalFiles, setProposalFiles] = useState<FileWithPath[]>([]);
  const [complementaryFiles, setComplementaryFiles] = useState<FileWithPath[]>(
    [],
  );
  const [proposalFileError, setProposalFileError] = useState<string | null>(
    null,
  );
  const [complementaryFileError, setComplementaryFileError] = useState<
    string | null
  >(null);

  const handleDrop = (newFile: FileWithPath[], type: ResponseDocType) => {
    if (type === ResponseDocType.SPECIFICATION_RC) {
      setProposalFiles(prev => [...prev, ...newFile]);
      setProposalFileError(null);
    } else {
      setComplementaryFiles(prev => [...prev, ...newFile]);
    }
  };

  const form = useForm({
    initialValues: {
      title: '',
      buyerName: '',
    },
    validate: {
      title: (value: string) =>
        value.trim().length > 0 ? null : 'Champ requis',
      buyerName: (value: string) =>
        value.trim().length > 0 ? null : 'Champ requis',
    },
  });

  const validateProposalFiles = () => {
    if (proposalFiles.length === 0) {
      setProposalFileError('Veuillez ajouter au moins un document');
      return true;
    }
    return false;
  };
  const validateComplementaryFiles = () => {
    if (proposalFiles.length === 0) {
      setComplementaryFileError('Veuillez ajouter au moins un document');
      return true;
    }
    return false;
  };

  return (
    <form>
      <Stack spacing="05" p="05">
        <Title
          order={3}
          pb="03"
          sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[1]}` })}
        >
          Ajouter un appel d'offres
        </Title>
        <TextInput
          label="Nom de l'acheteur"
          required
          {...form.getInputProps('buyerName')}
        />
        <TextInput
          label="Titre de l'appel d'offre"
          required
          {...form.getInputProps('title')}
        />
        <DropZoneInput
          required
          error={proposalFileError}
          files={proposalFiles}
          onFileChange={files =>
            handleDrop(files, ResponseDocType.SPECIFICATION_RC)
          }
          label="Critères de jugement de l’acheteur"
          subtitle="Ajoutez uniquement les documents comportant les critères pondérés de l’acheteur (ex: RC)"
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              <IconPaperclip />
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  les documents comportant les critères de l’acheteur
                </Text>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
        <DropZoneInput
          required
          error={complementaryFileError}
          files={complementaryFiles}
          onFileChange={files =>
            handleDrop(files, ResponseDocType.SPECIFICATION_DCE)
          }
          label="Exigences de l’acheteur"
          subtitle="Ajoutez les autres documents du DCE comportant les exigences de l’acheteur (ex: CCTP, CCAP...)"
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              <IconPaperclip />
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  les autres documents du DCE
                </Text>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
        <Group noWrap position="apart">
          <Button variant="white" size="lg" color="gray" onClick={closeModal}>
            Annuler
          </Button>
          <Button
            onClick={async (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              e.preventDefault();
              const isFormValid = !form.validate().hasErrors;
              const isProposalFilesValid = !validateProposalFiles();
              const isComplementaryFilesValid = !validateComplementaryFiles();

              if (
                isFormValid &&
                isProposalFilesValid &&
                isComplementaryFilesValid
              ) {
                setProposalFileError(null);
                setComplementaryFileError(null);
                createBidResponse({
                  title: form.values.title,
                  buyerName: form.values.buyerName,
                  proposalFiles,
                  complementaryFiles,
                  onSuccessCallback: setBidResponse,
                });
                goToNextStep();
              }
            }}
            size="lg"
            type="submit"
          >
            Ajouter
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

const AddAdditionnalContext = ({
  goToNextStep,
  bidResponseId,
}: StepsProps & { bidResponseId?: number }) => {
  const { updateBidResponse } = useCreateBidResponse();
  const form = useForm({
    initialValues: {
      additionalContext: '',
      companyRelationWithBuyer: '',
      companyResponseStrategy: '',
    },
  });

  return (
    <form>
      <Stack spacing="05" p="05">
        <Stack
          spacing="01"
          pb="03"
          sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[1]}` })}
        >
          <Title order={3}>Ajouter du contexte sur votre appel d’offres</Title>
          <Text variant="xs" fw={400} c="gray.5">
            Notre intelligence artificielle prendra en compte ces informations
            pour évaluer votre réponse
          </Text>
        </Stack>

        <TextArea
          label="Context du client"
          placeholder={`Expliquer le détail du contexte de votre offre
- Attentes connues de l’acheteur venant de conversations commerciales
- Préférences de l’acheteur`}
          {...form.getInputProps('additionalContext')}
        />
        <TextArea
          label="Relation avec l’acheteur"
          placeholder={`Décriver votre relation avec l'acheteur...
- Est-il déjà client ?
- Avez-vous une relation commerciale de longue date ?
- Comment sentez vous cette relation ? (chaleureuse, froide, à risque...)`}
          {...form.getInputProps('companyRelationWithBuyer')}
        />
        <TextArea
          label="Angle de réponse & points de différenciation"
          placeholder={`Décriver comment vous allez vous différencier face à la concurrence...
- Positionnement de votre offre
- Vos avantages uniques`}
          {...form.getInputProps('companyResponseStrategy')}
        />

        <Group noWrap position="apart">
          <Button variant="white" size="lg" color="gray" onClick={goToNextStep}>
            Passer
          </Button>
          <Button
            onClick={async (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              e.preventDefault();
              if (bidResponseId) {
                updateBidResponse({
                  id: bidResponseId,
                  additionalContext: form.values.additionalContext,
                  companyRelationWithBuyer:
                    form.values.companyRelationWithBuyer,
                  companyResponseStrategy: form.values.companyResponseStrategy,
                });
                goToNextStep();
              }
            }}
            size="lg"
            type="submit"
          >
            Enregistrer
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
