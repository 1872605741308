import { Stack } from '@mantine/core';

import type {
  CriteriaExtractionStatus,
  ProposalVersion,
} from '../../../../shared/entities/BidResponse';
import { ContextCards } from './ContextCards';
import { VersionsCard } from './VersionsCard';

type ResponseProjectTabProps = {
  id: number;
  extractionStatus: CriteriaExtractionStatus;
  proposalVersions?: ProposalVersion[];
  additionalContext?: string;
  companyRelationWithBuyer?: string;
  companyResponseStrategy?: string;
  selectedProposalVersionId: number | null;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

export function ResponseProjectTab({
  id: bidResponseId,
  extractionStatus,
  proposalVersions,
  selectedProposalVersionId,
  setSelectedProposalVersion,
  ...rest
}: ResponseProjectTabProps) {
  return (
    <Stack spacing="06" p="06">
      <VersionsCard
        selectedProposalVersionId={selectedProposalVersionId}
        bidResponseId={bidResponseId}
        extractionStatus={extractionStatus}
        proposalVersions={proposalVersions}
        setSelectedProposalVersion={setSelectedProposalVersion}
      />
      <ContextCards {...rest} />
    </Stack>
  );
}
