import { Avatar } from '../../../../../shared/components/UI/Avatar/Avatar';

import { useActiveUser } from '../../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useNoticeContext } from '../../../../notice/contexts/Notice.provider';
import { useThreads } from '../hooks/useThreads.hook';
import { CommentInput } from './CommentInput';

type NewCommentInputProps = {
  threadId: number;
  instantAnalysisAnswerId?: number;
  showBorderOnFocus?: boolean;
};

export function NewCommentInput({
  threadId,
  showBorderOnFocus,
  instantAnalysisAnswerId,
}: NewCommentInputProps) {
  const { activeUser } = useActiveUser();
  const { tender } = useNoticeContext();
  const { createComment } = useThreads(tender.id);

  return (
    <CommentInput
      avatar={
        <Avatar color={activeUser.avatarColor}>
          {activeUser.firstName[0].toUpperCase() +
            activeUser.lastName[0].toUpperCase()}
        </Avatar>
      }
      placeholder="répondre..."
      saveComment={(content: RichTextJson) =>
        createComment({ content, threadId, instantAnalysisAnswerId })
      }
      variant="subtle"
      showBorderOnFocus={showBorderOnFocus}
    />
  );
}
