import { createContext, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { getStreams } from '../../../shared/api/magellan/stream';
import type { GetAllStreamsResponseDTO } from '../../../shared/api/magellan/stream/dto';
import type Stream from '../../../shared/entities/Stream';
import type { StreamSection } from '../../../shared/entities/StreamSection';

interface StreamContextLoading {
  status: 'loading';
  data: {
    streams: undefined;
    isStreamListEmpty: undefined;
    sidebarContent: {
      favorites: undefined;
      sections: undefined;
      streamsWithoutSection: undefined;
    };
  };
}

interface StreamContextSuccess {
  status: 'success';
  data: {
    streams: Stream[];
    isStreamListEmpty: boolean;
    sidebarContent: {
      favorites: Stream[];
      sections: StreamSection[];
      streamsWithoutSection: Stream[];
    };
  };
}

type StreamsContext = StreamContextLoading | StreamContextSuccess;

const StreamsContext = createContext<StreamsContext | undefined>(undefined);

export function StreamsProvider() {
  const {
    data: streamsResponse,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [getStreams.name],
    queryFn: getStreams,
  });

  const isStreamListEmpty =
    location.pathname === '/flux' && streamsResponse?.streams.length === 0;
  let context: StreamsContext;

  if (isLoading || !streamsResponse) {
    context = {
      status: 'loading',
      data: {
        streams: undefined,
        sidebarContent: {
          sections: undefined,
          streamsWithoutSection: undefined,
          favorites: undefined,
        },
        isStreamListEmpty: undefined,
      },
    };
  } else if (isError) {
    throw error;
  } else {
    context = {
      status: 'success',
      data: {
        streams: streamsResponse.streams,
        sidebarContent: getSidebarContent(streamsResponse),
        isStreamListEmpty,
      },
    };
  }

  return (
    <StreamsContext.Provider value={context}>
      {/* Used in react router so rendering outlet instead of children */}
      <Outlet />
    </StreamsContext.Provider>
  );
}

export const useStreamsContext = () => {
  const context = useContext(StreamsContext);
  if (!context) {
    throw new Error('useStreams must be used within a StreamsProvider');
  }
  return context;
};

const getSidebarContent = ({
  streams,
  favoriteIds,
  streamSections,
}: GetAllStreamsResponseDTO): {
  favorites: Stream[];
  sections: StreamSection[];
  streamsWithoutSection: Stream[];
} => {
  const favorites = streams.filter(stream => favoriteIds.includes(stream.id));
  const filledStreamSections: StreamSection[] = streamSections.map(section => ({
    ...section,
    streams: streams.filter(stream => stream.streamSectionId === section.id),
  }));
  const streamsWithoutSection = streams.filter(
    stream => !stream.streamSectionId,
  );

  return {
    favorites,
    sections: filledStreamSections,
    streamsWithoutSection,
  };
};
