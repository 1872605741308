import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './app/App.tsx';
import { initI18next } from './shared/infra/i18next.ts';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://7bdbb4098f3d784dba3dfe6a3c9bbbaf@o4505872635723776.ingest.sentry.io/4505937409212416',
    environment: import.meta.env.PROD ? 'production' : 'development',
    integrations: [new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
  });
}

initI18next();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
