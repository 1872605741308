import type { MantineColor } from '@mantine/core';
import { Box, Group, Stack } from '@mantine/core';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';

import type { JudgementCriterion } from '../../../../shared/entities/BidResponse';
import { createCriteriaTree } from '../../utils/createCriteriaTree';
import { CriteriaTree } from './CriteriaTree';

type CriteriaCardProps = {
  criteria: JudgementCriterion[];
};

export const CriteriaCard = ({ criteria: criteria }: CriteriaCardProps) => {
  if (!criteria.length) {
    return null;
  }

  const sortedCriteria = createCriteriaTree(criteria);

  return (
    <BrandedCard title="Critères de notation">
      <Stack maw="640px">
        <Group noWrap w="100%" spacing={0}>
          {sortedCriteria.map((c, index) => {
            const isFirst = index === 0;
            const isLast = index === sortedCriteria.length - 1;
            return (
              <ParentCriteriaWeight
                key={c.id}
                criteria={c}
                isFirst={isFirst}
                isLast={isLast}
                subCriteria={c.subCriteria}
              />
            );
          })}
        </Group>
        <CriteriaTree criteria={sortedCriteria} />
      </Stack>
    </BrandedCard>
  );
};

type CriteriaWeightProps = {
  criteria: JudgementCriterion;
  isFirst: boolean;
  isLast: boolean;
  subCriteria?: JudgementCriterion[];
};

const ParentCriteriaWeight = ({
  criteria,
  isFirst,
  isLast,
  subCriteria,
}: CriteriaWeightProps) => {
  const color = criteria.color?.color;
  if (!subCriteria?.length) {
    return (
      <Box
        key={criteria.id}
        w={`${criteria.weight}%`}
        h="30px"
        bg={`${color}.5`}
        sx={theme => ({
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 4px 0px rgba(255, 255, 255, 0.20) inset`,
          borderTopLeftRadius: isFirst ? theme.radius.md : 0,
          borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
          borderBottomRightRadius: isLast ? theme.radius.md : 0,
          borderTopRightRadius: isLast ? theme.radius.md : 0,
          marginLeft: !isFirst ? `4px` : 'none',
          marginRight: !isLast ? `4px` : 'none',
        })}
      />
    );
  }

  return (
    <Group
      noWrap
      w={`${criteria.weight}%`}
      spacing={0}
      sx={theme => ({
        marginLeft: !isFirst ? `4px` : 'none',
        marginRight: !isLast ? `4px` : 'none',
        borderTopLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomRightRadius: isLast ? theme.radius.md : 0,
        borderTopRightRadius: isLast ? theme.radius.md : 0,
        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 4px 0px rgba(255, 255, 255, 0.20) inset`,
      })}
    >
      {subCriteria.map((c, index) => {
        const isFirstChild = index === 0 && isFirst;
        const isLastCHild = index === subCriteria.length - 1 && isLast;

        return (
          <ChildCriteria
            key={c.id}
            criteria={c}
            isFirst={isFirstChild}
            isLast={isLastCHild}
            parentColor={color}
            totalWeight={criteria.weight}
          />
        );
      })}
    </Group>
  );
};

type ChildCriteriaWeightProps = CriteriaWeightProps & {
  totalWeight: number;
  parentColor?: MantineColor;
};

const ChildCriteria = ({
  criteria,
  isFirst,
  isLast,
  parentColor,
  totalWeight,
}: ChildCriteriaWeightProps) => {
  const width = (criteria.weight * 100) / totalWeight;
  return (
    <Box
      key={criteria.id}
      w={`${width}%`}
      h="30px"
      bg={`${parentColor}.${criteria.color?.shade}`}
      sx={theme => ({
        borderTopLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomRightRadius: isLast ? theme.radius.md : 0,
        borderTopRightRadius: isLast ? theme.radius.md : 0,
      })}
    />
  );
};
