import { useState } from 'react';

import { Box, Table, Text, useMantineTheme } from '@mantine/core';

import { IconArrowRight, IconPlus } from '@tabler/icons-react';

import { Button } from '../../../shared/components/UI/Button/Button';
import { EmptyPlaceholder } from '../../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';
import { TenderTitle } from '../../../shared/components/UI/TenderTitle/TenderTitle';

import { useUrlHash } from '../../../shared/contexts/UrlHash.provider';
import type { BidResponse } from '../../../shared/entities/BidResponse';
import { UrlHashBuilder } from '../../../shared/utils/navigation/UrlHashBuilder';
import { useBidResponseList } from '../hooks/useBidResponseList';
import { CreateBidResponseModal } from './CreateBidResponseModal';

export const BidResponseList = () => {
  const { bidResponseList } = useBidResponseList();
  const theme = useMantineTheme();

  if (bidResponseList.length === 0) {
    return <EmptyBidResponseList />;
  }

  return (
    <Box
      py="04"
      px="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ borderBottom: `1px solid ${theme.colors.gray[1]}` }}>
              <Text variant="sm" fw="500" c="gray.6">
                Opportunités
              </Text>
            </th>
            <th style={{ borderBottom: `1px solid ${theme.colors.gray[1]}` }}>
              <Text variant="sm" fw="500" c="gray.6">
                Actions
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {bidResponseList.map(bidResponse => (
            <BidResponseRow key={bidResponse.id} bidResponse={bidResponse} />
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

type BidResponseRowProps = {
  bidResponse: BidResponse;
};

const BidResponseRow = ({ bidResponse }: BidResponseRowProps) => {
  const theme = useMantineTheme();
  const { redirectTo } = useUrlHash();
  const tdStyling = {
    width: '90%',
    padding: theme.spacing['02'],
    margin: 0,
    borderTop: `1px solid ${theme.colors.gray[1]}`,
    cursor: 'pointer',
  };

  const handleClick = (e: React.MouseEvent) => {
    redirectTo(
      `/reponse/${bidResponse.id}`,
      new UrlHashBuilder().addLocationData().build(),
      undefined,
      e,
    );
  };

  return (
    <tr onClick={handleClick}>
      <td style={tdStyling}>
        <TenderTitle
          buyerOriginalName={bidResponse.buyerName}
          tenderTitle={bidResponse.title}
          size="md"
        />
      </td>
      <td style={tdStyling}>
        <Button variant="light" size="sm" leftIcon={<IconArrowRight />}>
          Ouvrir
        </Button>
      </td>
    </tr>
  );
};

const EmptyBidResponseList = () => {
  const [createBidResponseModalOpenned, setCreateBidResponseModalOpenned] =
    useState(false);
  const AddBidResponseBtn = (
    <Button
      leftIcon={<IconPlus />}
      onClick={() => setCreateBidResponseModalOpenned(true)}
    >
      Ajouter un appel d'offres
    </Button>
  );
  return (
    <Box
      py="04"
      px="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <CreateBidResponseModal
        opened={createBidResponseModalOpenned}
        closeModal={() => setCreateBidResponseModalOpenned(false)}
      />
      <EmptyPlaceholder
        title="Vous n'avez aucun projet de réponse en cours"
        actionsComponent={AddBidResponseBtn}
      />
    </Box>
  );
};
