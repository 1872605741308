import { Box, Group, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { useHover, useMediaQuery } from '@mantine/hooks';

import { IconArrowRight } from '@tabler/icons-react';

import { Counter } from '../../../shared/components/UI/Counter/Counter';

type DecisionTabButtonProps = {
  label: string;
  icon: React.ReactNode;
  decisionCount?: number;
  isActive: boolean;
  onClick: () => void;
};

export function DecisionTabButton({
  label,
  icon,
  decisionCount,
  isActive,
  onClick,
}: DecisionTabButtonProps) {
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  const inactiveBackground = hovered
    ? theme.colors.gray[0]
    : `linear-gradient(89.29deg, #F7F7F8 26.32%, #FFFFFF 98.86%)`;

  const background = isActive
    ? `linear-gradient(89.29deg, #DAEEFF 55.97%, #FFFFFF 98.86%)`
    : inactiveBackground;

  return (
    <Group
      ref={ref}
      m={0}
      p="01"
      maw="300px"
      onClick={onClick}
      sx={theme => ({
        cursor: 'pointer',
        pointerEvents: isActive ? 'none' : 'all',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        background: 'white',
        border: isActive
          ? `2px solid ${theme.colors.primary[6]}`
          : `1px solid ${theme.colors.gray[2]}`,
        boxShadow: theme.shadows.xs,
        ':hover': {
          boxShadow: theme.shadows.sm,
        },
        flexGrow: 1,
      })}
    >
      <Group
        noWrap
        spacing="02"
        px="02"
        h="06"
        w="100%"
        sx={theme => ({
          borderRadius: theme.radius.md,
          background,
        })}
      >
        <ThemeIcon
          variant="outline"
          color={isActive ? 'primary.7' : 'gray.6'}
          size="sm"
          sx={{ border: 'none' }}
        >
          {icon}
        </ThemeIcon>
        <Text
          variant="sm"
          fw={isActive ? '500' : '400'}
          c="gray."
          miw="70px"
          style={{ flexGrow: 1 }}
        >
          {label}
        </Text>
        {!isSmallScreen && (
          <Box w="16px">
            {hovered && (
              <IconArrowRight
                size={16}
                style={{ marginTop: '6px' }}
                color={theme.colors.gray[6]}
              />
            )}
          </Box>
        )}
        <Counter
          value={decisionCount}
          color={isActive ? 'primary' : 'gray'}
          variant={isActive ? 'filled' : 'subtle'}
        />
      </Group>
    </Group>
  );
}
