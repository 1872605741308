import type { MRT_Cell } from 'mantine-react-table';

import DecisionBadge from '../../../../../../shared/components/UI/DecisionBadge/DecisionBadge';

import type {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../../../../shared/entities/Interaction';

type DecisionCellProps = {
  cell: MRT_Cell<any>;
};

export function DecisionCell({ cell }: DecisionCellProps) {
  const decision = cell.getValue<
    DecisionStatus | DecisionRenewalStatus | undefined
  >();
  if (decision === 'PENDING') return null;
  return <DecisionBadge decision={decision} />;
}
