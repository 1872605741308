import type { Location } from '../../entities/Location';

export function extractDomainFromURL(url: string) {
  const urlObj = new URL(url);
  const parts = urlObj.hostname.split('.');
  const tld = parts.pop();
  const domain = parts.pop();
  return `${domain}.${tld}`;
}

export function goToGmap(location: Location) {
  const url = `https://www.google.com/maps/search/?api=1&query=${location.address} ${location.postalCode} ${location.city}`;
  window.open(url, '_blank');
}
