import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Stack } from '@mantine/core';

import { Loader } from '../../../../shared/components/UI/Loader/Loader';

import { displayDecisionNotification } from '../../../../shared/components/notifications/decisionNotifications';
import { useActiveUser } from '../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import { DecisionRenewalStatus } from '../../../../shared/entities/Interaction';
import { DecisionStatus } from '../../../../shared/entities/Interaction';
import type { Filters } from '../../../../shared/entities/StreamFilterSettings';
import type { StatusType } from '../../../../shared/hooks/useUpsertDecision.hook';
import { useUpsertDecision } from '../../../../shared/hooks/useUpsertDecision.hook';
import { useSearchTenders } from '../forms/hooks/useSearchTenders.hook';
import { useStreamsActions } from '../forms/hooks/useStreamsActions.hook';
import { EmptyStreamList } from './EmptyStreamList';
import { TenderPreviewCard, TenderPreviewSkeletton } from './TenderPreviewCard';

const LENGTH_FROM_BOTTOM_TO_REFETCH = 600;

type ContentProps = {
  streamId: number;
  filters: Filters | undefined;
  withDecision?: boolean;
  decrementPendingDecisionCount: (() => void) | undefined;
  incrementPendingDecisionCount: (() => void) | undefined;
};

export const Content = React.memo(
  ({
    streamId,
    filters,
    decrementPendingDecisionCount,
    incrementPendingDecisionCount,
    withDecision = false,
  }: ContentProps) => {
    const { activeUser } = useActiveUser();
    const upsertDecision = useUpsertDecision();
    const { decrementStreamsTenderCount, incrementStreamsTenderCount } =
      useStreamsActions();
    const {
      tendersWithTransition,
      isLoading,
      isFetching,
      isRefetching,
      fetchNextPage,
      updateTender,
      incrementDecisionsTaken,
    } = useSearchTenders(filters, withDecision);
    const containerRef = useRef<HTMLDivElement>(null);

    // Scroll to top when tabs change
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }, [withDecision]);

    const fetchMoreOnBottomReached = useCallback(
      (containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

          if (
            scrollHeight - scrollTop - clientHeight <
              LENGTH_FROM_BOTTOM_TO_REFETCH &&
            !isFetching
          ) {
            fetchNextPage();
          }
        }
      },
      [fetchNextPage, isFetching],
    );

    const numberOfMountedTenders = tendersWithTransition?.filter(
      t => t.mounted,
    ).length;

    if (
      isLoading ||
      isRefetching ||
      !filters ||
      !decrementPendingDecisionCount ||
      !incrementPendingDecisionCount ||
      tendersWithTransition === null
    ) {
      return (
        <Loader
          title="Chargement des opportunités..."
          subtitle="Nous cherchons des opportunités qui vous correspondent parmi plus de 700 000 appels
          d'offres collectés."
          mt={280}
        />
      );
    }

    if (numberOfMountedTenders === 0) {
      return <EmptyStreamList />;
    }

    /*
     * This function is very tighly coupled with the searchTenders hook and the order of calls matters.
     * See the useSearchTenders hook for more information on theses issues.
     */
    async function handleDecision(
      tenderId: number,
      isRenewal: boolean | null | undefined,
      decisionStatus: DecisionStatus | DecisionRenewalStatus,
      affectedStreamIds?: number[],
      reason?: string,
      previousDecisionStatus?: DecisionStatus | DecisionRenewalStatus,
    ) {
      if (!withDecision) {
        // we only increment the decision taken count if we are looking at pending decisions
        incrementDecisionsTaken();
      }

      const status = {
        type: isRenewal ? 'DecisionRenewalStatus' : 'DecisionStatus',
        value: decisionStatus,
      } as StatusType;

      await upsertDecision(tenderId, status, reason, streamId);
      updateTender(tenderId, status, activeUser);

      const isBackwardDecision =
        (decisionStatus === DecisionStatus.REJECTED &&
          previousDecisionStatus !== DecisionStatus.TO_ANALYZE) ||
        (decisionStatus === DecisionStatus.TO_ANALYZE &&
          previousDecisionStatus !== DecisionStatus.REJECTED);
      const isBackwardRenewalDecision =
        (decisionStatus === DecisionRenewalStatus.REJECTED &&
          previousDecisionStatus !== DecisionRenewalStatus.TO_ANALYZE) ||
        (decisionStatus === DecisionRenewalStatus.TO_ANALYZE &&
          previousDecisionStatus !== DecisionRenewalStatus.REJECTED);

      if (isBackwardDecision || isBackwardRenewalDecision) {
        decrementPendingDecisionCount && decrementPendingDecisionCount();
        affectedStreamIds && decrementStreamsTenderCount(affectedStreamIds);
      } else if (decisionStatus === DecisionStatus.PENDING) {
        incrementPendingDecisionCount && incrementPendingDecisionCount();
        affectedStreamIds && incrementStreamsTenderCount(affectedStreamIds);
      }
      displayDecisionNotification(decisionStatus);
    }

    const tenderList = tendersWithTransition.map(tender => (
      <TenderPreviewCard
        tender={tender}
        key={tender.id}
        onTenderDecision={(
          status: DecisionStatus | DecisionRenewalStatus,
          reason: string | undefined,
        ) =>
          handleDecision(
            tender.id,
            tender.isRenewal,
            status,
            tender.affectedStreamIds,
            reason,
            tender.isRenewal
              ? tender.interaction?.decisionRenewalStatus
              : tender.interaction?.decisionStatus,
          )
        }
      />
    ));

    return (
      <Box
        ref={containerRef}
        onScroll={event =>
          fetchMoreOnBottomReached(event.target as HTMLDivElement)
        }
        p={40}
        h="100%"
        sx={{ overflowY: 'auto' }}
      >
        <Stack spacing={24} maw="1600px">
          {tenderList}
          {isFetching && <TenderPreviewSkeletton />}
        </Stack>
      </Box>
    );
  },
);
