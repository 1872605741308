import { useCallback, useEffect, useRef, useState } from 'react';

import {
  ActionIcon,
  Box,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { IconCheck, IconPencil, IconX } from '@tabler/icons-react';

import {
  getTender,
  upsertInteractionWihNote,
} from '../../../../shared/api/magellan/tender';
import { queryClient } from '../../../../shared/infra/queryClient';

type NotesProps = {
  tenderId: number;
  initialNote?: string;
  onNoteChange?: (note: string, tenderId: number) => void;
  close?: () => void;
  withHeader?: boolean;
};

export function Notes({
  tenderId,
  initialNote = '',
  onNoteChange,
  close,
  withHeader = false,
}: NotesProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useDebouncedState<string | null>(null, 500);
  const [loadingState, setLoadingState] = useState<
    'waiting' | 'loading' | 'success'
  >('waiting');

  useEffect(() => {
    async function saveNotes(value: string) {
      setLoadingState('loading');
      await upsertInteractionWihNote(tenderId, value);
      onNoteChange && onNoteChange(value, tenderId);
      setTimeout(() => {
        setLoadingState('success');
        setTimeout(() => setLoadingState('waiting'), 500);
      }, 1000);
      queryClient.invalidateQueries({ queryKey: [getTender.name, tenderId] });
    }

    if (value !== null && value !== initialNote) {
      saveNotes(value);
    }
  }, [initialNote, onNoteChange, tenderId, value]);

  const handleFocus = useCallback(() => {
    if (textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, []);

  const handleClose = useCallback(() => {
    const value = textareaRef.current?.value || '';
    upsertInteractionWihNote(tenderId, value);
    onNoteChange && onNoteChange(value, tenderId);
    close && close();
  }, [close, onNoteChange, tenderId]);

  return (
    <Stack h="100%">
      {withHeader && (
        <InteractionNotesHeader
          handleClose={close ? handleClose : undefined}
          loadingState={loadingState}
        />
      )}
      <Textarea
        ref={textareaRef}
        variant="unstyled"
        h={initialNote?.length ? 'fit-content' : '200px'}
        autosize
        minRows={4}
        defaultValue={initialNote}
        onFocus={handleFocus}
        placeholder="Saisissez ici toutes les informations pertinentes concernant l’opportunité, l’acheteur, contexte antérieur pour faciliter une collaboration transparente et durable..."
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setValue(event.currentTarget.value)
        }
        styles={{
          input: {
            padding: '0px !important',
          },
        }}
        sx={{
          overflowY: initialNote?.length ? 'auto' : 'hidden',
        }}
      />
      {!withHeader && loadingState !== 'waiting' && (
        <LoaderNotif loadingState={loadingState} />
      )}
    </Stack>
  );
}

type LoaderNotifProps = {
  loadingState: 'waiting' | 'loading' | 'success';
};

const LoaderNotif = ({ loadingState }: LoaderNotifProps) => {
  const loadingIcon =
    loadingState === 'loading' ? <Loader size="xs" /> : <IconCheck size={16} />;
  const loadingText =
    loadingState === 'loading'
      ? 'Enregistrement en cours...'
      : 'Modifications sauvegardées';

  return (
    <Center>
      <Group
        noWrap
        py="02"
        pl="03"
        pr="04"
        spacing="02"
        pos="absolute"
        bottom="24px"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.xl,
          boxShadow: theme.shadows.sm,
        })}
      >
        {loadingIcon}
        <Text variant="xs" fw={500} c="dark.5">
          {loadingText}
        </Text>
      </Group>
    </Center>
  );
};

type InteractionNotesHeaderProps = {
  handleClose?: () => void;
  loadingState: 'waiting' | 'loading' | 'success';
};

function InteractionNotesHeader({
  handleClose,
  loadingState,
}: InteractionNotesHeaderProps) {
  let icon = <IconPencil size={16} />;
  if (loadingState === 'loading') {
    icon = <Loader size={16} />;
  }
  if (loadingState === 'success') {
    icon = <IconCheck size={16} />;
  }
  return (
    <Group position="apart" spacing="02">
      <Group spacing="sm">
        <Box h={32} w={32} bg="gray.1" c="dark.3" sx={{ borderRadius: '6px' }}>
          <Center h="100%">{icon}</Center>
        </Box>
        <Text variant="sm" fw={500} c="dark.9">
          Prise de notes
        </Text>
      </Group>
      {handleClose && (
        <ActionIcon onClick={handleClose}>
          <IconX size={16} />
        </ActionIcon>
      )}
    </Group>
  );
}
