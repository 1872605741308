import { Box, Table } from '@mantine/core';

import { EmptyPlaceholder } from '../../../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';

import type {
  JudgementCriterion,
  requirementEvaluation,
} from '../../../../shared/entities/BidResponse';
import { HeaderRow } from './HeaderRow';
import { RequirementEvaluationRow } from './RequirementEvaluationRow';
import { type RequirementEvaluationFilter } from './SimulationTab';

export type BidRequirementEvaluationGrid = {
  bidResponseId: number;
  proposalVersionId: number;
  requirementEvaluations: requirementEvaluation[];
  judgementCriteria: JudgementCriterion[];
  filterFunction: RequirementEvaluationFilter['callback'][] | null;
};

export const BidRequirementEvaluationGrid = ({
  bidResponseId,
  requirementEvaluations,
  judgementCriteria,
  filterFunction,
  proposalVersionId,
}: BidRequirementEvaluationGrid) => {
  const filteredRequirements = filterFunction
    ? filterFunction.reduce(
        (acc, filter) => acc.filter(filter),
        requirementEvaluations,
      )
    : requirementEvaluations;

  return (
    <Box
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        paddingLeft: theme.spacing['03'],
        paddingRight: theme.spacing['03'],
      })}
    >
      {filteredRequirements.length === 0 ? (
        <EmptyPlaceholder title={'Aucun élements'} />
      ) : (
        <Table>
          <HeaderRow />
          <tbody>
            {filteredRequirements.map((requirementEvaluation, index) => (
              <RequirementEvaluationRow
                key={requirementEvaluation.id}
                bidResponseId={bidResponseId}
                index={index}
                requirementEvaluation={requirementEvaluation}
                judgementCriteria={judgementCriteria}
                proposalVersionId={proposalVersionId}
              />
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
};
