import { Group } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import { RenewalTag } from '../../../../../../shared/components/UI/RenewalTag/RenewalTag';
import TenderStatusBadge from '../../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';

import type { TenderStatus } from '../../../../../../shared/entities/Tender';

type OpportunityCellProps = {
  cell: MRT_Cell<any>;
};

export type StatusCellData = {
  status: TenderStatus;
  isRenewal: boolean;
};

export function StatusCell({ cell }: OpportunityCellProps) {
  const { status, isRenewal } = cell.getValue<StatusCellData>();
  return (
    <Group noWrap spacing="01">
      <TenderStatusBadge status={status} />
      {isRenewal && <RenewalTag size="xs" />}
    </Group>
  );
}
