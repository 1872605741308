import { createContext, useContext } from 'react';

import type { NoticeSidePanelTabValues } from '../components/Header';

type SidePanelActionsContextType = {
  closeSidePanel: () => void;
  setActiveTab: (tab: NoticeSidePanelTabValues | null) => void;
};

const SidePanelActionsContext = createContext<
  SidePanelActionsContextType | undefined
>(undefined);

type SidePanelActionsProviderProps = {
  setActiveTab: (tab: NoticeSidePanelTabValues | null) => void;
  children: React.ReactNode;
};

export const SidePanelActionsProvider = ({
  setActiveTab,
  children,
}: SidePanelActionsProviderProps) => {
  const closeSidePanel = () => {
    setActiveTab(null);
  };

  return (
    <SidePanelActionsContext.Provider value={{ closeSidePanel, setActiveTab }}>
      {children}
    </SidePanelActionsContext.Provider>
  );
};

export const useSidePanelActions = () => {
  const context = useContext(SidePanelActionsContext);
  if (!context) {
    throw new Error(
      'useSidePanelActions must be used within a SidePanelActionsProvider',
    );
  }
  return context;
};
