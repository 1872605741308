import { useState } from 'react';

import { Group, NavLink, Text } from '@mantine/core';

import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import type Stream from '../../../../shared/entities/Stream';
import { SidebarStreamSettingsMenu } from './SidebarStreamSettingsMenu';

type SideBarNavLinkProps = {
  streamWithTenderCount: Stream;
  isActive: boolean;
};

export const SideBarNavLink = ({
  streamWithTenderCount,
  isActive,
}: SideBarNavLinkProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);

  const pendingDecisionCount =
    streamWithTenderCount.pendingDecisionCount < 100
      ? `${streamWithTenderCount.pendingDecisionCount || ''}`
      : '+99';

  const label = (
    <Group
      position="apart"
      dir="rowReverse"
      spacing={2}
      noWrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip content={streamWithTenderCount.name}>
        <Text w={180} truncate>
          {streamWithTenderCount.name}
        </Text>
      </Tooltip>

      <SidebarStreamSettingsMenu
        isParentHovered={isHovered}
        streamId={streamWithTenderCount.id}
      />
      {pendingDecisionCount && (
        <Text variant="sm" fw={600} c="primary.6">
          {pendingDecisionCount}
        </Text>
      )}
    </Group>
  );

  return (
    <NavLink
      label={label}
      noWrap
      active={isActive}
      onClick={() =>
        navigate(`/flux/${streamWithTenderCount.id}${location.search}`)
      }
      fz="14px"
      fw={isActive ? '600' : '400'}
      px="sm"
      py="xxs"
      styles={theme => ({
        label: {
          color: theme.colors.dark[7],
        },
        root: {
          '&[data-active]': {
            background: theme.colors.primary[1],
          },
        },
      })}
      sx={theme => ({
        borderRadius: theme.radius.md,
      })}
    />
  );
};
