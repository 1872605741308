import { Divider, Group, Text } from '@mantine/core';

import { IconFilter } from '@tabler/icons-react';

import {
  MultiCheckbox,
  type MultiCheckboxOption,
} from '../../../../../shared/components/UI/MultiCheckbox/MultiCheckbox';

import type { BuyerTableFilter } from '../../TendersTabContent';
import type { FilterConfig } from './BuyerTenderFilters';
import { BuyerTableFilterIds } from './BuyerTenderFilters';

type BuyerTableFilterInputsProps = {
  rowCount: number | undefined;
  filters: BuyerTableFilter[];
  filterConfig: Record<BuyerTableFilterIds, FilterConfig>;
  onFilterChange: (filterId: BuyerTableFilterIds, value: string[]) => void;
  onResetFilters: () => void;
};

export function BuyerTableFilterInputs({
  rowCount,
  filters,
  filterConfig,
  onFilterChange,
  onResetFilters: resetFilters,
}: BuyerTableFilterInputsProps) {
  const streamConfig = filterConfig[BuyerTableFilterIds.STREAM];

  const getSelectedColumnFilters = (
    filterId: BuyerTableFilterIds,
  ): MultiCheckboxOption[] => {
    const values = filters.find(filter => filter.id === filterId)?.value ?? [];
    const options = filterConfig[filterId].options;
    const selected = options?.filter(option => values.includes(option.value));
    return selected || [];
  };

  return (
    <Group spacing="02">
      <Text variant="sm" fw="400" c="gray.9" miw="110px">
        {rowCount} opportunités
      </Text>
      <Divider orientation="vertical" h="04" my="auto" />
      <Group spacing="01" c="gray.6">
        <IconFilter size={20} />
        <Text variant="sm" fw="400" c="gray.6">
          Filtrer
        </Text>
      </Group>
      <MultiCheckbox
        label={streamConfig.filterLabel}
        icon={streamConfig.icon}
        values={getSelectedColumnFilters(BuyerTableFilterIds.STREAM)}
        options={streamConfig.options}
        h={300}
        onChange={newVal =>
          onFilterChange(
            BuyerTableFilterIds.STREAM,
            newVal.map(({ value }) => `${value}`),
          )
        }
      />

      {filters.length && <ResetButton onClick={resetFilters} />}
    </Group>
  );
}

type ResetButtonProps = {
  onClick: () => void;
};
const ResetButton = ({ onClick: resetFilters }: ResetButtonProps) => {
  return (
    <Text
      variant="sm"
      fw={500}
      c="gray.6"
      onClick={resetFilters}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      Réinitialiser
    </Text>
  );
};
