import type { Comment } from './Comment';
import type { InstantAnalysisAnswer } from './InstantAnalysisAnswer';

export class Thread {
  id: number;
  interactionTenderId?: number;
  interactionCompanyId?: number;
  instantAnalysisAnswerId?: number;
  instantAnalysisAnswer?: InstantAnalysisAnswer;
  comments: Comment[];
}

export enum ThreadElementType {
  INTERACTION = 'interaction',
}
