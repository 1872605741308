import { useCallback, useState } from 'react';

import { Stack } from '@mantine/core';

import { useBuyerTenders } from '../hooks/useBuyerTenders.hook';
import {
  type BuyerTableFilterIds,
  BuyerTableFilters,
} from './tenderTable/components/BuyerTenderFilters';
import { BuyerTenderTable } from './tenderTable/components/BuyerTenderTable';

export type BuyerTableFilter = {
  id: BuyerTableFilterIds;
  value: string[];
};
type TenderTabContentProps = {
  buyerId: number;
};

export function TendersTabContent({ buyerId }: TenderTabContentProps) {
  const [filters, setFilters] = useState<BuyerTableFilter[]>([]);
  const {
    tenders,
    fetchNextPage,
    isFetching,
    totalResults,
    isLoading,
    buyerInfiniteQueryKey,
  } = useBuyerTenders(buyerId, filters);

  const handleFilterChange = useCallback(
    (filterId: BuyerTableFilterIds, value: string[]) => {
      setFilters(prev => {
        const columnFilter = prev.find(filter => filter.id === filterId);
        if (!columnFilter) {
          return [...prev, { id: filterId, value }];
        }
        const newFilters = prev.map(filter => {
          if (filter.id === filterId) {
            return {
              id: filter.id,
              value: value,
            };
          }
          return filter;
        });
        // removing empty filters

        return newFilters.filter(filter => filter.value.length);
      });
    },
    [],
  );

  const resetFilters = () => {
    setFilters([]);
  };

  return (
    <Stack spacing="02" w="100%" h="100%">
      <BuyerTableFilters
        filters={filters}
        rowCount={totalResults}
        onFilterChange={handleFilterChange}
        onFilterReset={resetFilters}
      />
      <BuyerTenderTable
        isLoading={isLoading}
        initialTenders={tenders}
        onScrollPointReached={fetchNextPage}
        isFetching={isFetching}
        buyerInfiniteQueryKey={buyerInfiniteQueryKey}
        onFilterReset={resetFilters}
      />
    </Stack>
  );
}
