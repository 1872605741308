import type { AssessmentStatus } from '../../../entities/AssessmentStatus';
import type { InstantAnalysisAnswer } from '../../../entities/InstantAnalysisAnswer';
import { magellanClient } from '../../../infra/axios';
import type {
  GetAssessmentsRecapResponseDTO,
  GetOnTheFlyInstantAnalysisResponseDTO,
  GetQuestionsSetInstantAnalysisResponseDTO,
} from './dto';

export async function getQuestionsSetsInstantAnalysis(
  tenderId: number,
): Promise<GetQuestionsSetInstantAnalysisResponseDTO> {
  const response =
    await magellanClient.get<GetQuestionsSetInstantAnalysisResponseDTO>(
      `/tenders/${tenderId}/instantAnalysisAnswers/questionsSets`,
    );

  return response.data;
}

export async function getOnTheFlyInstantAnalysis(
  tenderId: number,
): Promise<GetOnTheFlyInstantAnalysisResponseDTO> {
  const response =
    await magellanClient.get<GetOnTheFlyInstantAnalysisResponseDTO>(
      `/tenders/${tenderId}/instantAnalysisAnswers/onTheFlyQuestions`,
    );

  return response.data;
}
export async function assessInstantAnalysisAnswer(
  answerId: number,
  assessment: AssessmentStatus | null,
): Promise<void> {
  await magellanClient.put(`/instantAnalysisAnswers/${answerId}/assessment`, {
    assessment: assessment,
  });
}

export async function getAssessmentsRecap(
  tenderId: number,
): Promise<GetAssessmentsRecapResponseDTO> {
  const response = await magellanClient.get<GetAssessmentsRecapResponseDTO>(
    `/tenders/${tenderId}/instantAnalysisAnswers/assessmentsRecap`,
  );

  return response.data;
}

export async function updateAiRelevanceFeedback(
  answerId: number,
  aiRelevanceFeedback: string | null,
): Promise<void> {
  await magellanClient.put(
    `/instantAnalysisAnswers/${answerId}/aiRelevanceFeedback`,
    {
      feedback: aiRelevanceFeedback,
    },
  );
}

export async function updateManualIaAnswer(
  answerId: number,
  userAnswer: string,
): Promise<InstantAnalysisAnswer> {
  const response = await magellanClient.put<InstantAnalysisAnswer>(
    `/instantAnalysisAnswers/${answerId}/userAnswer`,
    {
      userAnswer: userAnswer.length ? userAnswer : null,
    },
  );
  return response.data;
}
