import React, { useContext } from 'react';

type CheckboxCardContextType = {
  isChecked: (value: string) => boolean;
  isError: boolean;
  onCheckboxChange: (value: string, checked: boolean) => void;
};

const CheckboxCardContext = React.createContext<CheckboxCardContextType | null>(
  null,
);
export const CheckboxCardContextProvider = CheckboxCardContext.Provider;
export const useCheckboxCardContext = () => useContext(CheckboxCardContext);
