import { forwardRef, useEffect, useState } from 'react';

import { Image, Indicator, Select, useMantineTheme } from '@mantine/core';

import { IconChevronDown } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import Crown from '../assets/UI/crown.svg';
import { Avatar } from './UI/Avatar/Avatar';
import { UserWithAvatar } from './UI/UserWithAvatar/UserWithAvatar';

import type { PageParams } from '../../app/pages/Notice.page';
import { updateInteractionOwner } from '../api/magellan/tender';
import type { User } from '../entities/User';

type OwnerSelectProps = {
  users: User[];
  owner: User | null;
};

export const OwnerSelect = ({
  users,
  owner: initialOwner,
}: OwnerSelectProps) => {
  const { id } = useParams<PageParams>() as PageParams;
  const theme = useMantineTheme();
  const ownerMutation = useMutation({
    mutationFn: ({ ownerId }: { ownerId: string }) =>
      updateInteractionOwner(ownerId, Number(id)),
  });
  const [owner, setOwner] = useState<User | null>(initialOwner);

  useEffect(() => {
    setOwner(initialOwner);
  }, [initialOwner]);

  const nonDeletedUsers = users.filter(
    user => !user.disabledAt || user.id === owner?.id,
  );
  const userItems: SelectItemProps[] = nonDeletedUsers.map(user => ({
    value: user.id,
    firstname: user.firstName,
    lastname: user.lastName,
    avatarcolor: user.avatarColor,
    isDisabled: !!user.disabledAt,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const ownerAvatar = owner && (
    <Indicator
      label={<Image src={Crown} />}
      offset={3}
      styles={{
        indicator: {
          padding: 0,
          background: 'transparent',
        },
      }}
    >
      <Avatar
        size="md"
        color={owner.avatarColor}
        isDisabled={!!owner.disabledAt}
      >
        {owner.firstName[0].toUpperCase()}
        {owner.lastName[0].toUpperCase()}
      </Avatar>
    </Indicator>
  );

  const placeholderAvatar = (
    <Indicator
      label={<Image src={Crown} />}
      offset={3}
      styles={{
        indicator: {
          padding: 0,
          background: 'transparent',
        },
      }}
    >
      <Avatar size="md" />
    </Indicator>
  );

  const onOwnerChange = (ownerId: string) => {
    const newOwner = users.find(user => user.id === ownerId);
    if (!newOwner) {
      throw new Error('New owner not found');
    }
    ownerMutation.mutate({ ownerId });
    setOwner(newOwner);
  };

  return (
    <Select
      variant="unstyled"
      rightSection={<IconChevronDown size={14} color={theme.colors.gray[6]} />}
      placeholder="Assigner un responsable"
      w={240}
      h={20}
      data={userItems}
      itemComponent={SelectItem}
      value={owner?.id}
      onChange={onOwnerChange}
      withinPortal
      icon={ownerAvatar || placeholderAvatar}
      styles={theme => ({
        input: {
          marginLeft: theme.spacing['01'],
          minHeight: '20px',
          height: '20px',
          textDecorationLine: owner?.disabledAt ? 'line-through' : 'inherit',
          color: owner?.disabledAt ? theme.colors.gray[3] : 'inherit',
        },
        wrapper: {
          height: '20px',
        },
        rightSection: { pointerEvents: 'none' },
      })}
    />
  );
};

interface SelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  firstname: string;
  lastname: string;
  label: string;
  avatarcolor: string;
  isDisabled: boolean;
  value: string;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (
    { firstname, lastname, avatarcolor, isDisabled, ...props }: SelectItemProps,
    ref,
  ) => {
    return (
      <UserWithAvatar
        ref={ref}
        avatarColor={avatarcolor}
        firstName={firstname}
        lastName={lastname}
        isDisabled={isDisabled}
        {...props}
      />
    );
  },
);
