import { Button, Group, Stack, Text, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

import { DecisionStatus } from '../../entities/Interaction';

type WinLossReasonModalProps = {
  onDecision: (status: DecisionStatus, reason?: string) => void;
  nextStatus: DecisionStatus;
};

export const WinLossReasonModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<WinLossReasonModalProps>) => {
  const { onDecision, nextStatus } = innerProps;
  const form = useForm({
    initialValues: {
      reason: '',
    },
    validate: {
      reason: (value: string) =>
        value.trim().length > 0 ? null : 'Champ requis',
    },
  });

  const winTitle = 'Pourquoi avez-vous gagné ?';
  const lossTitle = 'Pourquoi avez-vous perdu ?';
  const title = nextStatus === DecisionStatus.WIN ? winTitle : lossTitle;

  const winContent =
    "Analyser ces causes de victoire vous permet d'identifier les facteurs ayant contribué à votre succès.";
  const lossContent =
    "Analyser ces causes de défaite vous permet d'identifier les axes de progression pour vos prochaines réponses.";
  const content = nextStatus === DecisionStatus.WIN ? winContent : lossContent;

  return (
    <form>
      <Stack py={16} px={21} spacing={2}>
        <Title order={5} mb="xs">
          {title}
        </Title>
        <Text variant="sm" fw="400" c="gray.6">
          {content}
        </Text>
        <Textarea {...form.getInputProps('reason')} />
        <Group position="right" mt="lg">
          <Button
            id="buttonSkip"
            variant="subtle"
            c="primary.9"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            Annuler
          </Button>
          <Button
            id="buttonSend"
            maw="25%"
            miw="200px"
            radius="md"
            size="md"
            color="primary.9"
            type="submit"
            onClick={async e => {
              e.preventDefault();
              if (!form.validate().hasErrors) {
                await onDecision(nextStatus, form.values.reason);
                context.closeModal(id);
              }
            }}
          >
            Envoyer
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
