import { Group, Text } from '@mantine/core';

import { IconRepeat } from '@tabler/icons-react';

import { Tooltip } from '../Tooltip/Tooltip';

type SizeParams = 'xs' | 'lg';

type RenewalTagProps = {
  size?: SizeParams;
};

export function RenewalTag({ size = 'lg' }: RenewalTagProps) {
  const Tag = (
    <Group
      noWrap
      w="fit-content"
      h={size === 'lg' ? '24px' : '18px'}
      spacing="01"
      px="02"
      bg="primary.1"
      c="primary.6"
      sx={theme => ({
        borderRadius: theme.radius.lg,
        border: `1px solid ${theme.colors.primary[2]}`,
        boxShadow: theme.shadows.sm,
      })}
    >
      <IconRepeat size={16} />
      {size === 'lg' && (
        <Text variant="xs" fw="600" c="gray.9">
          Fin de marché, renouvellement possible
        </Text>
      )}
    </Group>
  );

  if (size === 'lg') {
    return Tag;
  }
  return (
    <Tooltip width="166px" content="Fin de marché, renouvellement potentiel">
      {Tag}
    </Tooltip>
  );
}
