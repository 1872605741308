import { useState } from 'react';

import { Checkbox, Group, Text, Title, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconPencil, IconTrash } from '@tabler/icons-react';

import type { QuestionsSet } from '../../../entities/QuestionsSet';

type CheckboxQuestionsSetProps = {
  questionsSet: QuestionsSet;
  value: boolean;
  switchQuestionsSet: (questionsSetId: number, checked: boolean) => void;
  deleteQuestionsSet: (questionsSetId: number) => void;
  updateQuestionsSet: (questionsSet: QuestionsSet) => void;
};

export function CheckboxQuestionsSet({
  value,
  questionsSet,
  switchQuestionsSet,
  deleteQuestionsSet,
  updateQuestionsSet,
}: CheckboxQuestionsSetProps) {
  const theme = useMantineTheme();
  const [isChecked, setIsChecked] = useState(value);
  const [isHovered, setIsHovered] = useState(false);

  const onClick = () => {
    setIsChecked(!isChecked);
    switchQuestionsSet(questionsSet.id, !isChecked);
  };

  const onEdit = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    modals.openContextModal({
      modal: 'updateQuestionsSets',
      size: 'lg',
      yOffset: '24vh',
      innerProps: {
        questionsSet,
        onSubmit: updateQuestionsSet,
      },
    });
  };

  const onDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    modals.openConfirmModal({
      yOffset: '24vh',
      title: <Title order={4}>Confirmer la suppression</Title>,
      children: (
        <Text variant="sm" fw={400} c="gray.6" mb="xl">
          Êtes-vous sûr(e) de vouloir supprimer ce jeu de question? La
          suppression sera permanente et irréversible. Cette action ne pourra
          pas être annulée.
        </Text>
      ),
      labels: { confirm: 'Oui, supprimer', cancel: 'Non, annuler' },
      cancelProps: { variant: 'subtle', color: 'primary.6' },
      confirmProps: { color: 'red.6' },
      onConfirm: () => deleteQuestionsSet(questionsSet.id),
    });
  };

  return (
    <Group
      px="05"
      py="02"
      sx={theme => ({
        border: `1px solid ${isChecked ? theme.colors.blue[5] : theme.colors.gray[1]}`,
        backgroundColor: isChecked ? theme.colors.blue[0] : 'white',
        justifyContent: 'space-between',
        borderRadius: theme.radius.md,
        ':hover': {
          cursor: 'pointer',
          backgroundColor: theme.colors.gray[0],
        },
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <Group>
        <Checkbox
          checked={isChecked}
          // no-op function because the change is handled by the onClick on the parent
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          sx={{ ':hover': { cursor: 'pointer' } }}
        />
        <Group spacing="xxs" align="center">
          <Text variant="md" fw={400} c={isChecked ? 'blue.8' : 'gray.10'}>
            {questionsSet.title}
          </Text>
          <Text variant="md" fw={400} c={isChecked ? 'blue.8' : 'gray.4'}>
            ({questionsSet.questions.length} questions)
          </Text>
        </Group>
      </Group>
      <Group spacing="xs" sx={{ visibility: isHovered ? 'visible' : 'hidden' }}>
        <IconPencil size={16} color={theme.colors.gray[9]} onClick={onEdit} />
        <IconTrash size={16} color={theme.colors.red[5]} onClick={onDelete} />
      </Group>
    </Group>
  );
}
