import {
  Center,
  Container,
  Paper,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';

import { IconFaceIdError } from '@tabler/icons-react';

type ErrorComponentProps = {
  withBackground?: boolean;
};

export function Error({ withBackground = false }: ErrorComponentProps) {
  const theme = useMantineTheme();

  const content = (
    <Center w="100%" h="100%" p="5%">
      <Container>
        <Stack>
          <>
            <svg height="0" width="0">
              <defs>
                <filter
                  id="outer-glow"
                  x="-50%"
                  y="-50%"
                  width="200%"
                  height="200%"
                >
                  <feGaussianBlur
                    in="SourceAlpha"
                    stdDeviation="1"
                    result="blur"
                  />
                  <feOffset in="blur" dx="1.31" dy="2.62" result="offsetBlur" />
                  {/* Adjust for glow offset */}
                  <feFlood
                    floodColor="rgba(255, 255, 255, 0.8)"
                    result="color"
                  />
                  {/* Adjust color and opacity */}
                  <feComposite
                    in="color"
                    in2="offsetBlur"
                    operator="in"
                    result="colorBlur"
                  />
                  <feMerge>
                    <feMergeNode in="colorBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <filter
                  id="inner-shadow"
                  x="-50%"
                  y="-50%"
                  width="200%"
                  height="200%"
                >
                  <feComponentTransfer in="SourceAlpha">
                    <feFuncA type="table" tableValues="1 0" />
                  </feComponentTransfer>
                  <feGaussianBlur stdDeviation="2" />
                  <feOffset dx="0" dy="3" result="offsetblur" />
                  <feFlood floodColor="rgba(0, 0, 0, 0.3)" result="color" />
                  <feComposite in2="offsetblur" operator="in" />
                  <feComposite in2="SourceAlpha" operator="in" />
                  <feMerge>
                    <feMergeNode in="SourceGraphic" />
                    <feMergeNode />
                  </feMerge>
                </filter>
              </defs>
            </svg>
            <IconFaceIdError
              size={116}
              color={theme.colors.gray[3]}
              style={{ margin: 'auto' }}
            />
          </>
          <Title order={2} c="dark.4" align="center">
            Oups ! Une erreur s'est produite
          </Title>
        </Stack>
      </Container>
    </Center>
  );

  return withBackground ? (
    <Paper shadow="md" radius="md" p="md" w="100%" h="80%">
      {content}
    </Paper>
  ) : (
    content
  );
}
