import * as Sentry from '@sentry/react';

import { Error as ErrorComponent } from '../../../../shared/components/UI/Error/Error.tsx';

import { requestDCE } from '../../../../shared/api/magellan/tender';
import { DceRequestStatus } from '../../../../shared/entities/DceRequestStatus';
import { useQuestionsSetsAnalysis } from '../../../../shared/hooks/useQuestionsSetsAnalysis.hook';
import AnswerCard from '../../../instantAnalysis/components/AnswersCard';
import TriggerCard from '../../../instantAnalysis/components/TriggerCard';
import { useNoticeContext } from '../../contexts/Notice.provider';
import { useIsAnalysable } from '../../hooks/useIsAnalysable.hook';

type InstantAnalysisTabProps = {
  dceRequestStatus: DceRequestStatus;
  setDceRequestStatus: (dceRequestStatus: DceRequestStatus) => void;
};

export default function InstantAnalysisTabWithBoundary(
  props: InstantAnalysisTabProps,
) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      {<InstantAnalysisTab {...props} />}
    </Sentry.ErrorBoundary>
  );
}

function InstantAnalysisTab({
  dceRequestStatus,
  setDceRequestStatus,
}: InstantAnalysisTabProps) {
  const { tender } = useNoticeContext();
  const questionsSetAnalysis = useQuestionsSetsAnalysis(tender.id);
  const isAnalysable = useIsAnalysable(tender.id, questionsSetAnalysis.status);

  const sendDCERequest = () => {
    if (tender.id) {
      requestDCE(tender.id);
      setDceRequestStatus(DceRequestStatus.IN_SEARCH);
    }
  };

  const getContent = () => {
    switch (questionsSetAnalysis.status) {
      case 'NO_SUPPORTED_DOCUMENTS':
      case 'WAITING':
        return (
          <TriggerCard
            tenderStatus={tender.status}
            isAnalysable={isAnalysable}
            triggerInstantAnalysis={
              questionsSetAnalysis.triggerQuestionsSetInstantAnalysis
            }
            dceRequestStatus={dceRequestStatus}
            sendDCERequest={sendDCERequest}
          />
        );
      case 'RUNNING':
      case 'DONE':
        return (
          questionsSetAnalysis.answers && (
            <AnswerCard
              llmStatus={questionsSetAnalysis.status}
              answers={questionsSetAnalysis.answers}
              triggerInstantAnalysis={
                questionsSetAnalysis.triggerQuestionsSetInstantAnalysis
              }
              tenderId={tender.id}
              onRetry={questionsSetAnalysis.retry}
            />
          )
        );
      case 'FAILED':
        throw new Error('Instant analysis failed');
    }
  };
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      {getContent()}
    </Sentry.ErrorBoundary>
  );
}
