import { Group, Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import { IconArrowRight } from '@tabler/icons-react';

import { formatDateAsText } from '../../../../../../shared/utils/dates';

export type DateCellData = {
  publicationDate: string;
  responseDeadline: string;
};

type DateCellProps = {
  cell: MRT_Cell<any>;
};

export function DateCell({ cell }: DateCellProps) {
  const { publicationDate, responseDeadline } = cell.getValue<DateCellData>();

  const formatedPublicationDate = formatDateAsText(
    publicationDate,
    false,
    true,
  );

  const formatedResponseDeadline = responseDeadline
    ? formatDateAsText(responseDeadline, false, true)
    : '-';

  return (
    <Group noWrap spacing={4} c="gray.5">
      <Text variant="md" fw={400} c="gray.9">
        {formatedPublicationDate}
      </Text>
      <IconArrowRight size={16} />
      <Text variant="md" fw={400} c="gray.9">
        {formatedResponseDeadline}
      </Text>
    </Group>
  );
}
