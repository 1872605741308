import type { FileWithPath } from '@mantine/dropzone';

import { useMutation } from '@tanstack/react-query';

import {
  createBidResponse,
  updateBidResponse,
} from '../../../shared/api/magellan/bidResponses';
import { ResponseDocType } from '../../../shared/entities/BidResponse';
import { useBidResponseList } from './useBidResponseList';

type CreateBidResponseProps = {
  title: string;
  buyerName: string;
  proposalFiles: FileWithPath[];
  complementaryFiles: FileWithPath[];
  onSuccessCallback: (data: any) => void;
};

type UpdateBidResponseProps = {
  id: number;
  additionalContext?: string;
  companyRelationWithBuyer?: string;
  companyResponseStrategy?: string;
};

export function useCreateBidResponse() {
  const { refetchBidResponseList } = useBidResponseList();
  const createBidResponseMutation = useMutation({
    mutationFn: async ({
      title,
      buyerName,
      proposalFiles,
      complementaryFiles,
    }: CreateBidResponseProps) => {
      const formData = new FormData();

      Object.entries({
        title,
        buyerName,
      }).forEach(([key, value]: [string, any]) => {
        formData.append(key, value);
      });

      proposalFiles.forEach(file => {
        formData.append(
          ResponseDocType.SPECIFICATION_RC,
          file,
          encodeURIComponent(file.name),
        );
      });
      complementaryFiles.forEach(file => {
        formData.append(
          ResponseDocType.SPECIFICATION_DCE,
          file,
          encodeURIComponent(file.name),
        );
      });

      const res = await createBidResponse(formData);
      return res;
    },
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback(data);
      refetchBidResponseList();
    },
  });

  const updateBidResponseMutation = useMutation({
    mutationFn: async ({
      id,
      ...updateBidResponseProps
    }: UpdateBidResponseProps) => {
      const res = await updateBidResponse(
        id,
        updateBidResponseProps.additionalContext,
        updateBidResponseProps.companyRelationWithBuyer,
        updateBidResponseProps.companyResponseStrategy,
      );
      return res;
    },
  });

  return {
    createBidResponse: createBidResponseMutation.mutate,
    updateBidResponse: updateBidResponseMutation.mutate,
  };
}
