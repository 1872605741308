import { Group, Stack, Text } from '@mantine/core';

import Markdown from 'react-markdown';

import type {
  BidRequirement,
  JudgementCriterion,
} from '../../../../../shared/entities/BidResponse';
import type { DataPointSource } from '../../../../../shared/entities/DataPointSource';
import { createCriteriaTree } from '../../../utils/createCriteriaTree';
import { CriteriaWithMarker } from '../CriteriaWithMarker';
import { SourceButton } from '../SourceButton';
import { CellLoader } from './IACellLoader';

type BidRequirementCellProps = {
  bidRequirement: BidRequirement;
  judgmentCriteria: JudgementCriterion[];
  source: DataPointSource;
};
export const BidRequirementCell = ({
  bidRequirement,
  judgmentCriteria,
  source,
}: BidRequirementCellProps) => {
  if (!bidRequirement) {
    return <CellLoader />;
  }
  const criteriaTree = createCriteriaTree(judgmentCriteria);

  let relatedJudgementCriteria: JudgementCriterion | undefined;

  criteriaTree.map(c => {
    if (c.id === bidRequirement.judgementCriterionId) {
      relatedJudgementCriteria = c;
    } else {
      c.subCriteria.map(sub => {
        if (sub.id === bidRequirement.judgementCriterionId) {
          relatedJudgementCriteria = sub;
        }
      });
    }
  });

  return (
    <Stack spacing="02" pr="09">
      <Group noWrap spacing="02" align="flex-start">
        <Text variant="sm" fw={500} c="gray.9" sx={{ p: { margin: 0 } }}>
          <Markdown>{bidRequirement.title}</Markdown>
        </Text>
        <SourceButton source={source} />
      </Group>
      {relatedJudgementCriteria && relatedJudgementCriteria?.color?.color && (
        <CriteriaWithMarker
          title={relatedJudgementCriteria?.title}
          weight={relatedJudgementCriteria?.weight}
          color={relatedJudgementCriteria?.color?.color}
          shade={relatedJudgementCriteria?.color?.shade || 5}
          maw="400px"
        />
      )}
    </Stack>
  );
};
