import { useEffect, useRef, useState } from 'react';

import {
  Container,
  Divider,
  FocusTrap,
  Grid,
  ScrollArea,
  Stack,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';

import { IconCheck } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '../../shared/components/UI/Loader/Loader';

import { AdvancedFields } from '../../features/streams/components/forms/components/AdvancedFields';
import { BackNavButton } from '../../features/streams/components/forms/components/BackNavButton';
import { BasicFields } from '../../features/streams/components/forms/components/BasicFields';
import { DeleteSection } from '../../features/streams/components/forms/components/DeleteSection';
import { SearchSidebar } from '../../features/streams/components/forms/components/SearchSidebar';
import { useStreamSettings } from '../../features/streams/components/forms/hooks/useStreamSettings.hook';
import { useStreamsActions } from '../../features/streams/components/forms/hooks/useStreamsActions.hook';
import { useStreamsContext } from '../../features/streams/contexts/Streams.provider';
import { searchTendersPreview } from '../../shared/api/magellan/tender';
import Stream from '../../shared/entities/Stream';
import type { StreamFormValues } from '../../shared/entities/StreamFilterSettings';

export default function UpdateStreamPage() {
  const { updateStream, deleteStream } = useStreamsActions();
  const { id } = useParams();
  const stream = useStreamSettings(Number(id));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultsCount, setResultsCount] = useState<number>();
  const streamsContext = useStreamsContext();
  const searchPreviewRequestIdRef = useRef<number>(0);
  const navigate = useNavigate();

  const form = useForm<StreamFormValues>({
    validateInputOnChange: true,
    initialValues: {
      name: stream.name,
      streamSectionId: stream.streamSectionId,
      ...stream.filterSettings,
    },
    initialDirty: {},
    validate: {
      name: isNotEmpty(),
      status: value =>
        value?.length && value?.length > 0
          ? undefined
          : 'Veuillez sélectionner au moins un statut',
    },
  });

  // Using useRef because useCallback, useEffect and useMemo require the form object as a dependency. And form.isValid() mutates the form object, trigering an infinite loop.
  const formRef = useRef(form);

  // tracking form changes be able to use the formRef in the useEffect below without having to pass the entire form object as a dependency
  useEffect(() => {
    formRef.current = form;
  }, [form]);

  // trigger new search preview
  useEffect(() => {
    searchPreviewRequestIdRef.current += 1;
    const requestId = searchPreviewRequestIdRef.current;
    const {
      name: _name,
      streamSectionId: _streamSectionId,
      ...spripedValues
    } = form.values;

    const triggerSearchPreview = async () => {
      setIsLoading(true);
      const resultsCount = await searchTendersPreview({
        ...spripedValues,
      });
      // Only update the results if the request is the latest one
      if (requestId !== searchPreviewRequestIdRef.current) return;
      setResultsCount(resultsCount);
      setIsLoading(false);
    };

    const timerId = setTimeout(() => {
      if (formRef.current.isValid()) {
        triggerSearchPreview();
      } else {
        setResultsCount(0);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [form.values]);

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const handleSubmit = async (values: StreamFormValues) => {
    const stream = new Stream();
    const { name, streamSectionId, ...stripedValues } = values;

    stream.name = name;
    stream.filterSettings = stripedValues;
    stream.streamSectionId =
      streamSectionId === -1 ? undefined : streamSectionId;

    if (!id) {
      return;
    }

    const updatedStream = await updateStream(id, stream);

    if (updatedStream) {
      navigate(`/flux/${updatedStream.id}`);
    }
  };

  return (
    <>
      {stream && (
        <Container h="100%" w="100%" maw="1500px" fluid bg="white">
          <ScrollArea type="never" h="100%">
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Grid h="100%" w="100%" p={40} bg="white">
                <FocusTrap active>
                  {/* Form Column */}
                  <Grid.Col span={7}>
                    <Stack spacing={32} h="100%" w="100%" pb="40px">
                      <BackNavButton
                        isFormDirty={form.isDirty()}
                        isFormValid={form.isValid()}
                        onSubmit={() => form.onSubmit(handleSubmit)()}
                      />
                      <BasicFields form={form} />
                      <Divider size={1} />
                      <AdvancedFields form={form} isOpened={true} />
                      <Divider size={1} />
                      <DeleteSection
                        onDelete={() => id && deleteStream(parseInt(id))}
                      />
                    </Stack>
                  </Grid.Col>
                  {/* Submit Column */}
                  <Grid.Col span={4} offset={1}>
                    <SearchSidebar
                      isValid={form.isValid()}
                      isLoading={isLoading}
                      previewNumberOfResults={resultsCount}
                      buttonIcon={<IconCheck size={18} stroke={3} />}
                      buttonText="Mettre à jour"
                    />
                  </Grid.Col>
                </FocusTrap>
              </Grid>
            </form>
          </ScrollArea>
        </Container>
      )}
    </>
  );
}
