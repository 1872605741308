import { forwardRef } from 'react';

import { Group, Select } from '@mantine/core';

import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import { AssessmentBadge } from './AssessmentBadge';

type AssessmentDropdownProps = {
  selectedAssesment: AssessmentStatus | null;
  onSelect: (assesment: AssessmentStatus | null) => void;
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  assessment: AssessmentStatus | null;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, assessment, ...others }: ItemProps, ref) => {
    return (
      <Group {...others} ref={ref}>
        <AssessmentBadge assessment={assessment} isSelected />
        {label}
      </Group>
    );
  },
);

export function AssessmentDropdown({
  selectedAssesment,
  onSelect,
}: AssessmentDropdownProps) {
  const data = [
    {
      value: AssessmentStatus.BLOCKING_POINT,
      label: 'Point bloquant',
      assessment: AssessmentStatus.BLOCKING_POINT,
    },
    {
      value: AssessmentStatus.ATTENTION_POINT,
      label: "Point d'attention",
      assessment: AssessmentStatus.ATTENTION_POINT,
    },
    {
      value: AssessmentStatus.POSITIVE_POINT,
      label: 'Point positif',
      assessment: AssessmentStatus.POSITIVE_POINT,
    },
    {
      value: AssessmentStatus.NEUTRAL_POINT,
      label: 'Point neutre',
      assessment: AssessmentStatus.NEUTRAL_POINT,
    },
    { value: 'to do', label: 'À traiter', assessment: null },
  ];

  const handleChange = (value: string) => {
    const selected = data.find(item => item.value === value);
    if (selected) {
      onSelect(selected.assessment);
    }
  };

  return (
    <Select
      w={186}
      icon={<AssessmentBadge assessment={selectedAssesment} isSelected />}
      defaultValue={selectedAssesment}
      onChange={handleChange}
      data={data}
      itemComponent={SelectItem}
      styles={theme => ({
        input: {
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.md,
          color: theme.colors.gray[9],
        },
        icon: {
          paddingLeft: '6px',
        },
      })}
    />
  );
}
