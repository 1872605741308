import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useNavigateWithControl } from '../hooks/useNavigateWithControl.hook';
import type {
  UrlHashObject,
  UrlHashTypes,
  UrlHashValues,
} from '../utils/navigation/UrlHashBuilder';
import { decodeObjectFromBase64 } from '../utils/strings';

type ConsumeUrlHash = <T extends UrlHashTypes>(
  hashType: T,
) => UrlHashValues[T] | null;

type UrlHashContextType = {
  redirectTo: (
    url: string,
    urlHash: string,
    target?: string,
    mouseEvent?: React.MouseEvent<any>,
  ) => void;
  getRedirectUrl: (url: string, hash: string) => string;
  getReceivedUrlHashParam: ConsumeUrlHash;
};

const UrlHashContext = createContext<UrlHashContextType | null>(null);

type UrlHashProviderProps = {
  children: React.ReactNode;
};

export const UrlHashProvider = ({ children }: UrlHashProviderProps) => {
  const navigateWithControl = useNavigateWithControl();
  const navigate = useNavigate();
  const location = useLocation();
  const { hash: encodedLocation } = location;

  const url = useMemo(
    () => location.pathname + location.search,
    [location.pathname, location.search],
  );

  const [urlHashParams, setUrlHashParams] = useState<UrlHashObject | null>(
    null,
  );
  const [currentUrl, setCurrentUrl] = useState<string>(url);

  const receivedUrlHashParams = useMemo(() => {
    if (encodedLocation.length) {
      return decodeObjectFromBase64<UrlHashObject>(
        encodedLocation.split('#')[1],
      );
    }
    return null;
  }, [encodedLocation]);

  useEffect(() => {
    if (receivedUrlHashParams) {
      setUrlHashParams(receivedUrlHashParams);
    } else if (url !== currentUrl) {
      setUrlHashParams(null);
    }

    if (urlHashParams && encodedLocation.length) {
      navigate(url, { replace: true });
    }

    setCurrentUrl(url);
  }, [
    receivedUrlHashParams,
    encodedLocation,
    url,
    currentUrl,
    urlHashParams,
    navigate,
  ]);

  /**
   * Redirects to a specified URL with URL hashes encoded and appended.
   * Use when you want to handle the redirection yourself, for exemple in an onClick event.
   * The underlying function navigateWithControl will handle the behavior to provide better UX.
   *
   * @param {string} url - The target URL to redirect to.
   * @param {string} hash - A string containing URLHashObject encoded in base64. Obtained with UrlHashbuilded.
   * @param {string} [target='_self'] - The target where the URL will be opened (default is '_self').
   * @param {React.MouseEvent<any>} [mouseEvent] - The mouse event triggering the redirection(optionnal).
   */
  const redirectTo = (
    url: string,
    hash: string,
    target = '_self',
    mouseEvent?: React.MouseEvent<any>,
  ) => {
    const urlWithHashesData = `${url}#${hash}`;
    if (mouseEvent) {
      navigateWithControl(mouseEvent, urlWithHashesData);
    } else {
      window.open(urlWithHashesData, target);
    }
  };

  /**
   * Formats a URL with encoded URL hash values.
   * Useful for when you don't handle the redirection yourself, and just need the URL formatted with the hashed value.
   * for example, with a Link component from react-router-dom.
   *
   * @param {string} url - The target URL to format.
   * @param {string} hash - A string containing URLHashObject encoded in base64. Obtained with UrlHashbuilded.
   * @returns {string} The formatted URL with encoded hash values.
   */
  const getRedirectUrl = (url: string, hash: string) => {
    return `${url}#${hash}`;
  };

  const getReceivedUrlHashParam: ConsumeUrlHash = <T extends UrlHashTypes>(
    hashType: T,
  ) => {
    if (!urlHashParams || !urlHashParams[hashType]) {
      return null;
    }
    return urlHashParams[hashType];
  };

  const context = {
    redirectTo,
    getRedirectUrl,
    getReceivedUrlHashParam,
  };

  return (
    <UrlHashContext.Provider value={context}>
      {children}
    </UrlHashContext.Provider>
  );
};

export const useUrlHash = () => {
  const context = useContext(UrlHashContext);
  if (!context) {
    throw new Error('useUrlHash must be used within an UrlHashContext');
  }
  return context;
};
