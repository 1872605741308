import React from 'react';

import { Divider, Group, Stack, Text } from '@mantine/core';

import { IconPencil } from '@tabler/icons-react';

import { Avatar } from '../../../../shared/components/UI/Avatar/Avatar';
import { Button } from '../../../../shared/components/UI/Button/Button';

import type { User } from '../../../../shared/entities/User';
import { formatDateAsText } from '../../../../shared/utils/dates';
import { AnswerLeftBorder } from './AnswerLeftBorder';

type UserAnswerProps = {
  answer: string;
  answeredBy: Omit<User, 'email' | 'id'> | null;
  answeredAt: string | null;
  onEdit: () => void;
};
export const UserAnswer = ({
  answer,
  answeredBy,
  answeredAt,
  onEdit: openEditMode,
}: UserAnswerProps) => {
  const isUserDeleted = !!answeredBy?.disabledAt;

  return (
    <Stack spacing="md">
      <Stack spacing={4}>
        <Text variant="xs" fw={500} c="gray.6">
          Réponse manuelle
        </Text>
        <Group spacing="04" noWrap h="100%" sx={{ alignItems: 'stretch' }}>
          <AnswerLeftBorder variant="user" />
          <Stack spacing="02">
            <Text variant="md" fw={400} c="gray.8">
              {answer.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Text>
            {answeredBy && answeredAt && (
              <Group spacing="03">
                <Group spacing="02" noWrap>
                  <Avatar
                    size="md"
                    radius="lg"
                    color={answeredBy.avatarColor}
                    isDisabled={isUserDeleted}
                  >
                    {answeredBy.firstName[0]}
                    {answeredBy.lastName[0]}
                  </Avatar>
                  <Text
                    variant="xs"
                    fw={500}
                    c={isUserDeleted ? 'gray.3' : 'gray.9'}
                    strikethrough={isUserDeleted}
                  >
                    {answeredBy.firstName} {answeredBy.lastName}
                  </Text>
                  <Text variant="xs" fw={400} c="gray.6">
                    {formatDateAsText(answeredAt)}
                  </Text>
                </Group>
                <Divider
                  color="gray.2"
                  orientation="vertical"
                  h="04"
                  my="auto"
                />
                <Button
                  variant="default"
                  size="xs"
                  leftIcon={<IconPencil />}
                  onClick={openEditMode}
                >
                  Editer
                </Button>
              </Group>
            )}
          </Stack>
        </Group>
      </Stack>
    </Stack>
  );
};
