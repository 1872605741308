import { useEffect, useRef, useState } from 'react';

import { HoverCard, Skeleton, Stack, Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import type Tender from '../../../../../../shared/entities/Tender';
import type { OpportunityCellData } from '../columnDefinitions';

type OpportunityCellProps = {
  cell: MRT_Cell<Tender>;
};

export function OpportunityCell({ cell }: OpportunityCellProps) {
  const { tenderTitle, cpvs } = cell.getValue<OpportunityCellData>();
  const cpvsString = cpvs?.map(cpv => cpv.title).join(', ');
  const titleRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      const element = titleRef.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [tenderTitle]);

  if (!tenderTitle) {
    return <Skeleton height={20} />;
  }
  return (
    <HoverCard withinPortal width={620} position="bottom" openDelay={300}>
      <HoverCard.Target>
        <Stack spacing={0} w="100%" mih="40px">
          <Text ref={titleRef} variant="sm" fw="500" c="gray.9" truncate>
            {tenderTitle}
          </Text>
          <Text variant="sm" fw="400" c="gray.5" truncate>
            {cpvsString}
          </Text>
        </Stack>
      </HoverCard.Target>
      <HoverCard.Dropdown
        bg="gray.9"
        c="white"
        sx={theme => ({
          visibility: isTruncated ? 'visible' : 'hidden',
          borderRadius: theme.radius.sm,
        })}
      >
        {tenderTitle}
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
