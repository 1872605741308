import React from 'react';

import {
  ActionIcon,
  Box,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside, useWindowEvent } from '@mantine/hooks';

import { IconChevronsRight } from '@tabler/icons-react';

import { NAVBAR_HEIGHT } from '../Navbar/Navbar';

export type SlideInPanelProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
};

export function SlideInPanel({
  isOpen,
  onClose,
  children,
  title,
  headerRight,
}: SlideInPanelProps) {
  const panelRef = useClickOutside(() => onClose());

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useWindowEvent('keydown', handleKeyDown);

  return (
    <Stack
      ref={panelRef}
      pos="fixed"
      right={0}
      top={NAVBAR_HEIGHT}
      h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      w={'66vw'}
      sx={theme => ({
        backgroundColor: 'white',
        boxShadow: theme.shadows.xs,
        zIndex: 1000,
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'auto' : 'none',
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Header title={title} onClose={onClose} headerRight={headerRight} />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>{children}</Box>;
    </Stack>
  );
}

function Header({
  title,
  onClose,
  headerRight,
}: {
  title: string;
  onClose: () => void;
  headerRight: React.ReactNode;
}) {
  return (
    <Group px={'04'} pt={'03'} spacing={'02'} position="apart">
      <Title title={title} onClose={onClose} />
      {headerRight}
    </Group>
  );
}

function Title({ title, onClose }: { title: string; onClose: () => void }) {
  const theme = useMantineTheme();
  return (
    <Group spacing={theme.spacing['01']} p={0}>
      <ActionIcon color={theme.colors.gray[6]} onClick={onClose}>
        <IconChevronsRight stroke={2} color={theme.colors.gray[6]} size={20} />
      </ActionIcon>
      <Text variant="sm" fw={400} color={theme.colors.gray[9]}>
        {title}
      </Text>
    </Group>
  );
}
