import type { DataPointSource } from './DataPointSource';

export type BidResponse = {
  id: number;
  title: string;
  buyerName: string;
  extractionStatus: CriteriaExtractionStatus;

  additionalContext?: string;
  compagnyRelationWithBuyer?: string;
  companyResponseStrategy?: string;

  proposalVersions?: ProposalVersion[];
  judgementCriteria: JudgementCriterion[];

  createdAt: string;
  updatedAt: string;
};

export type CriteraColor = {
  color?: 'teal' | 'blue' | 'orange' | 'violet';
  shade?: 1 | 3 | 6 | 9;
};

export type JudgementCriterion = {
  id: number;
  title: string;
  weight: number;
  color?: CriteraColor;

  bidResponseId: number;
  parentId?: number | null;
};

export enum CriteriaExtractionStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export type ProposalVersion = {
  id: number;
  version: string;
  bidResponseId: number;
  proposalDocs: ResponseDocument[];
  requirementEvaluations?: requirementEvaluation[];
  extractionStatus: CriteriaExtractionStatus;
};

type ResponseDocument = {
  id: number;
  originalName: string;
  fileURL: string;
  type: ResponseDocType;
};

export enum ResponseDocType {
  'SPECIFICATION_RC' = 'SPECIFICATION_RC',
  'SPECIFICATION_DCE' = 'SPECIFICATION_DCE',
  'PROPOSAL_TECHNICAL_SUBMISSION' = 'PROPOSAL_TECHNICAL_SUBMISSION',
  'PROPOSAL_APPENDICES' = 'PROPOSAL_APPENDICES',
}

export type requirementEvaluation = {
  id: number;
  retrievedAnswerFromProposal?: string | null;
  sources?: DataPointSource[];
  extractionStatus: CriteriaExtractionStatus;
  bidRequirement: BidRequirement;
};

export type BidRequirement = {
  id: number;
  title: string;
  source: string;
  chunkIndex?: number | null;
  pageNumber?: number | null;
  llmRephrasedQuestion: string;
  originalChunkExtract: string;
  judgementCriterionId?: number | null;

  bidResponseId: number;
};

export type MinimalBidResponse = Omit<
  BidResponse,
  'additionalContext' | 'compagnyRelationWithBuyer' | 'companyResponseStrategy'
>;
