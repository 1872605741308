import { Group, Stack, Text, Timeline } from '@mantine/core';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';

import { formatDateInParisTZ } from '../../../../shared/utils/dates';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function TimelineCard() {
  const { t } = useTranslation();
  const { tender } = useNoticeContext();

  const timeLineLabels: TimeLineLabelProps[] = [
    {
      label: t('tenderPage.timeLineLabels.publicationDate'),
      date: tender.publicationDate,
      displayTime: false,
    },
    {
      label: t('tenderPage.timeLineLabels.responseDeadline'),
      date: tender.responseDeadline,
      displayTime: isTimeProvided(tender.responseDeadline),
    },
  ];

  const today = new Date();
  const numberOfDaysBeforeDeadline =
    tender.responseDeadline &&
    new Date(tender.responseDeadline).getTime() - today.getTime();
  let deadlineTitle = '';
  if (numberOfDaysBeforeDeadline && numberOfDaysBeforeDeadline > 0) {
    const numberOfDays = Math.floor(
      numberOfDaysBeforeDeadline / (1000 * 3600 * 24),
    );
    deadlineTitle = `, ${t('tenderPage.daysBeforeDeadLine', { numberOfDays })}`;
  }
  return (
    <BrandedCard
      title={t('tenderPage.brandedCards.calendar') + deadlineTitle}
      w="100%"
    >
      <Stack spacing="05">
        <Timeline lineWidth={2} color="green">
          {timeLineLabels.map(data => (
            <Timeline.Item key={data.label}>
              <TimeLineLabel
                label={data.label}
                date={data.date}
                displayTime={data.displayTime}
                key={data.label}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Stack>
    </BrandedCard>
  );
}

type TimeLineLabelProps = {
  label: string;
  date?: string;
  displayTime?: boolean;
};
const TimeLineLabel = ({ label, date, displayTime }: TimeLineLabelProps) => {
  const { t } = useTranslation();
  const formatedDate = date
    ? formatDateInParisTZ(date, { withTime: displayTime })
    : '-';

  return (
    <Stack spacing={5}>
      <Text variant="sm" fw="500" color="gray.9">
        {label}
      </Text>
      <Group noWrap spacing="01">
        <Text variant="xs" color="gray.9">
          {formatedDate}
        </Text>
        {displayTime && date && (
          <Text variant="xs" color="gray.6">
            {t('dates.timeInParis')}
          </Text>
        )}
      </Group>
    </Stack>
  );
};

const isTimeProvided = (date?: string) => {
  if (!date) {
    return false;
  }
  const dateTime = DateTime.fromISO(date, { zone: 'Europe/Paris' });

  // If the time is 00:00 or 23:00, we consider that the time is not provided.
  // When parsing a tender, when the time is provided, it is never midnight, at least from ou observation.
  // If the provided time is midnight, it is a rare case we are willing to sacrifice.
  //
  // 23 is also used as during tests it seems that the dateTime uncorrectly parsed the winter time.
  return !(
    (dateTime.hour === 0 || dateTime.hour === 23) &&
    dateTime.minute === 0
  );
};
