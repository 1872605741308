import { Image } from '@mantine/core';

import TengoLogoWithoutText from '../../../assets/UI/logo/logo_without_text.svg';

export function LogoWithoutText() {
  return (
    <Image
      src={TengoLogoWithoutText}
      alt={`Tengo logo without text`}
      maw={100}
    />
  );
}
