import type { ReactElement } from 'react';

import { Group, Stack, Text } from '@mantine/core';

type BrandingPointProps = {
  icon: ReactElement;
  title: string;
  subtitle: string;
};
export const BrandingPoint = ({
  icon,
  title,
  subtitle,
}: BrandingPointProps) => {
  return (
    <Group spacing="md" c="primary.6" align="flex-start">
      {icon}
      <Stack spacing={0} maw="80%">
        <Text variant="sm" fw={600} c="dark.4">
          {title}
        </Text>
        <Text c="dark.4" fw={400}>
          {subtitle}
        </Text>
      </Stack>
    </Group>
  );
};
