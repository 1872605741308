import type { ReactElement } from 'react';

import {
  Box,
  Center,
  Image,
  Loader,
  type MantineTheme,
  Paper,
  Stack,
  Text,
  Title,
  keyframes,
  useMantineTheme,
} from '@mantine/core';

import { IconExternalLink, IconStar } from '@tabler/icons-react';

import { Button } from '../../../../../shared/components/UI/Button/Button';

import { formatNumberAsText } from '../../../../../shared/utils/numbers';
import BuyerLogo1 from '../assets/buyerLogos/Mutuelle.png';
import BuyerLogo2 from '../assets/buyerLogos/Paris.png';
import BuyerLogo3 from '../assets/buyerLogos/Reunion.png';
import BuyerLogo4 from '../assets/buyerLogos/Y.png';
import { BrandingPoint } from './BrandingPoint';

const PaperWithLogos = ({ children }: { children: ReactElement }) => {
  const float = keyframes({
    '0%': { transform: 'rotate(-8deg) translateY(0)' },
    '50%': { transform: 'rotate(-8deg) translateY(4px)' },
    '100%': { transform: 'rotate(-8deg) translateY(0px)' },
  });

  const appear1 = keyframes({
    '0%': { transform: 'rotate(-8deg) translateY(-100%)' },
    '100%': { transform: 'rotate(-8deg) translateY(0)' },
  });

  const appear2 = keyframes({
    '0%': { transform: 'rotate(-8deg) translateX(100%)' },
    '100%': { transform: 'rotate(-8deg) translateX(0)' },
  });

  const appear3 = keyframes({
    '0%': { transform: 'rotate(-8deg) translateX(-300%)' },
    '100%': { transform: 'rotate(-8deg) translateX(0)' },
  });

  const appear4 = keyframes({
    '0%': { transform: 'rotate(-8deg) translateY(-100%)' },
    '100%': { transform: 'rotate(-8deg) translateY(0)' },
  });

  return (
    <Paper
      w="100%"
      h="142px"
      radius="md"
      pos="relative"
      sx={{ overflow: 'hidden' }}
    >
      <Image
        src={BuyerLogo1}
        width={60}
        height={60}
        pos="absolute"
        left="10%"
        top={-22}
        sx={{
          animation: `${appear1} 2s forwards, ${float} 1.9s 2s infinite  ease-in-out`,
        }}
      />
      <Image
        src={BuyerLogo2}
        width={48}
        height={48}
        pos="absolute"
        right="-10px"
        top="40%"
        sx={{
          animation: `${appear2} 2s forwards, ${float} 2.2s 2s infinite  ease-in-out`,
        }}
      />
      <Image
        src={BuyerLogo3}
        width={30}
        height={30}
        pos="absolute"
        left="4%"
        top="60%"
        sx={{
          animation: `${appear3} 2.1s forwards, ${float} 1.9s 2.1s infinite  ease-in-out`,
        }}
      />
      <Image
        src={BuyerLogo4}
        width={30}
        height={30}
        pos="absolute"
        right="20%"
        top={-10}
        sx={{
          animation: `${appear4} 2s forwards, ${float} 2s 2s infinite  ease-in-out`,
        }}
      />
      {children}
    </Paper>
  );
};

type SubmitPanelProps = {
  isValid: boolean;
  previewNumberOfResults?: number;
  buttonIcon: ReactElement;
  buttonText: string;
  theme: MantineTheme;
  isLoading: boolean;
};

const SubmitPanel = ({
  isValid,
  previewNumberOfResults,
  buttonIcon,
  buttonText,
  theme,
  isLoading,
}: SubmitPanelProps) => {
  return (
    <Stack
      w="100%"
      h="200px"
      p="md"
      align="center"
      spacing={0}
      bg={theme.fn.linearGradient(75, '#EDF2FF', 'rgba(237, 242, 255, 0.40)')}
      sx={{ borderRadius: '12px', border: '1px solid rgba(16, 17, 19, 0.04)' }}
    >
      <PaperWithLogos>
        {isLoading ? (
          <Center m="auto" h="100%">
            <Loader />
          </Center>
        ) : (
          <Stack justify="center" h="100%" spacing={0}>
            <Title order={1} align="center">
              {formatNumberAsText(previewNumberOfResults || 0)}
            </Title>
            <Text variant="sm" color="gray.6" fw={400} align="center">
              opportunités identifiées
            </Text>
          </Stack>
        )}
      </PaperWithLogos>
      <Button
        type="submit"
        disabled={!isValid}
        w="80%"
        mt={-20}
        size="lg"
        leftIcon={buttonIcon}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

type BrandingStackProps = {
  theme: MantineTheme;
  isFakeStack?: boolean;
};

const BrandingStack = ({ theme, isFakeStack = false }: BrandingStackProps) => {
  let sx;
  if (isFakeStack) {
    sx = {
      border: `1px solid ${theme.colors.gray[2]}`,
      borderRadius: '12px',
      transform: 'rotate(-4deg)',
      zIndex: 0,
    };
  } else {
    sx = {
      border: `1px solid ${theme.colors.gray[2]}`,
      borderRadius: '12px',
      zIndex: 10,
    };
  }

  return (
    <Stack bg="white" spacing="md" p={32} pos="absolute" sx={sx}>
      <BrandingPoint
        icon={<IconStar />}
        title="Réalisez des flux parfaits"
        subtitle="Nous avons créé un guide pour vous aider à paramétrer vos flux de manière optimale."
      />
      <Button
        size="md"
        variant="subtle"
        rightIcon={<IconExternalLink />}
        fullWidth
        onClick={() => {
          window.open(
            'https://tengo-cc.notion.site/Guide-d-utilisation-de-Tengo-f76d4525e2b64e569bd9e82286b59f8e',
            '_blank',
          );
        }}
      >
        Ouvrir le guide
      </Button>
    </Stack>
  );
};

type BrandingStacksProps = {
  theme: MantineTheme;
};

const GuideContainer = ({ theme }: BrandingStacksProps) => {
  return (
    <Box pos="relative">
      <BrandingStack theme={theme} />
      {/* Placing a rotated fake stack behind the 1srt one for design purposes, with the same content to garanty the same size */}
      <BrandingStack theme={theme} isFakeStack />
    </Box>
  );
};

type SearchSidebarProps = {
  isValid: boolean;
  previewNumberOfResults?: number;
  buttonIcon: ReactElement;
  buttonText: string;
  isLoading: boolean;
};

export const SearchSidebar = ({
  isValid,
  previewNumberOfResults,
  buttonIcon,
  buttonText,
  isLoading,
}: SearchSidebarProps) => {
  const theme = useMantineTheme();

  return (
    <Stack spacing={32} pos="fixed" w="30%">
      <SubmitPanel
        isValid={isValid}
        isLoading={isLoading}
        previewNumberOfResults={previewNumberOfResults}
        buttonIcon={buttonIcon}
        buttonText={buttonText}
        theme={theme}
      />
      <GuideContainer theme={theme} />
    </Stack>
  );
};
