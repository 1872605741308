import { useCallback } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { getQuestionsSetsInstantAnalysis } from '../api/magellan/instantAnalysis/answers';
import { triggerInstantAnalysisWithQuestionsSets } from '../api/magellan/tender';
import { queryClient } from '../infra/queryClient';
import { useInstantAnalysis } from './useInstantAnalysis.hook';

export function useQuestionsSetsAnalysis(tenderId: number) {
  const queryKey = [getQuestionsSetsInstantAnalysis.name, tenderId];

  const queryFn = async () => getQuestionsSetsInstantAnalysis(tenderId);
  const { data: instantAnalysis } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetch = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const { status, retry, onTriggerInstantAnalysis } = useInstantAnalysis(
    refetch,
    instantAnalysis.status,
  );

  const triggerQuestionsSetInstantAnalysis = useCallback(
    async (questionsSetIds: number[]) => {
      const res = await triggerInstantAnalysisWithQuestionsSets(
        tenderId,
        questionsSetIds,
      );
      if (res === 201) {
        onTriggerInstantAnalysis();
      }
    },
    [onTriggerInstantAnalysis, tenderId],
  );

  return {
    status,
    answers: instantAnalysis.answersGroupedByQuestionsSetTitle,
    triggerQuestionsSetInstantAnalysis,
    retry,
    refetch,
  };
}
