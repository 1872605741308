import { notifications } from '@mantine/notifications';

import { IconX } from '@tabler/icons-react';

export const displayErrorNotification = (title: string, message: string) => {
  notifications.show({
    title: title,
    message: message,
    radius: 'md',
    autoClose: 2000,
    withBorder: true,
    color: 'red.6',
    icon: <IconX size="18" />,
  });
};
