import { Stack } from '@mantine/core';

import { useNoticeContext } from '../../../notice/contexts/Notice.provider';
import { ActivityList } from '../activities/components/ActivityList';
import { EmptyActivitiesPlaceholder } from '../activities/components/EmptyActivitiesPlaceholder';
import { useActivities } from '../activities/hooks/useActivities.hook';

export function ActivitiesTab() {
  const { tender } = useNoticeContext();
  const { activities } = useActivities(tender.id);

  return (
    <Stack spacing={0} h="100%" sx={{ overflowY: 'hidden' }}>
      {activities.length === 0 ? (
        <EmptyActivitiesPlaceholder />
      ) : (
        <ActivityList {...{ activities }} />
      )}
    </Stack>
  );
}
