import { useState } from 'react';

import { Badge, Box, Divider, Group, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconEye } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../shared/components/UI/Button/Button';
import { Tooltip } from '../../../../../shared/components/UI/Tooltip/Tooltip';

import type { Document } from '../../../../../shared/entities/Document';
import CadFileIcon from '../assets/cad_file.svg?react';
import DbFileIcon from '../assets/db_file.svg?react';
import GantFileIcon from '../assets/gant_file.svg?react';
import ImageFileIcon from '../assets/image_file.svg?react';
import MiscFileIcon from '../assets/misc_file.svg?react';
import MovieFileIcon from '../assets/movie_file.svg?react';
import PdfFileIcon from '../assets/pdf_file.svg?react';
import SlideFileIcon from '../assets/slide_file.svg?react';
import SpreadsheetFileIcon from '../assets/spreadsheet_file.svg?react';
import TextFileIcon from '../assets/text_file.svg?react';
import ZipFileIcon from '../assets/zip_file.svg?react';
import { DocumentPreviewPanel } from './DocumentPreviewPanel';
import { DownloadFileButton } from './DownloadFileButton';

export function DocumentComponent({ document }: { document: Document }) {
  const { ref, hovered } = useHover();
  const [isPanelOpen, setPanelOpen] = useState(false);

  const icon = getIcon(document.originalName);

  return (
    <>
      <Group
        ref={ref}
        noWrap
        spacing={0}
        px="03"
        py="02"
        sx={theme => ({
          borderRadius: theme.radius.md,
          ':hover': {
            backgroundColor: theme.colors.gray[0],
            cursor: 'pointer',
          },
          width: '100%',
        })}
        onClick={() => setPanelOpen(true)}
      >
        <Group noWrap spacing="04" mr={'04'}>
          <Box>{icon}</Box>
          <Badge
            bg="gray.1"
            c="gray.8"
            variant="filled"
            size="sm"
            ml="-8px"
            sx={{ flexShrink: 0 }}
          >
            {document.type}
          </Badge>
          <Tooltip
            withArrow
            arrowSize={8}
            arrowRadius={2}
            position="bottom-start"
            arrowPosition="center"
            radius="md"
            openDelay={500}
            content={
              <Text variant="xs" fw={600}>
                {document.prettyName}
              </Text>
            }
          >
            <Text variant="sm" fw={400} c="gray.9" lineClamp={1}>
              {document.prettyName}
            </Text>
          </Tooltip>
        </Group>
        {hovered && (
          <Group noWrap spacing="04" position="apart" sx={{ flexGrow: 1 }}>
            <Group noWrap spacing={'01'}>
              <Divider color="gray.3" orientation="vertical" h="04" my="auto" />
              <PreviewButton />
            </Group>
            <DownloadFileButton
              fileURL={document.fileURL}
              prettyName={document.prettyName}
            />
          </Group>
        )}
      </Group>
      <DocumentPreviewPanel
        document={document}
        isPanelOpen={isPanelOpen}
        onClose={() => setPanelOpen(false)}
      />
    </>
  );
}

const getIcon = (title: string) => {
  const fileExtension = title.split('.').pop();
  switch (fileExtension) {
    case 'pdf':
      return <PdfFileIcon style={{ height: '36px' }} />;
    case 'dotx':
    case 'doc':
    case 'docx':
    case 'txt':
    case 'rtf':
    case 'odt':
      return <TextFileIcon style={{ height: '36px' }} />;
    case 'xls':
    case 'xlsx':
    case 'ods':
    case 'csv':
    case 'xml':
      return <SpreadsheetFileIcon style={{ height: '36px' }} />;
    case 'ppt':
    case 'pptx':
    case 'odp':
    case 'pps':
      return <SlideFileIcon style={{ height: '36px' }} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'tiff':
    case 'tif':
    case 'svg':
      return <ImageFileIcon style={{ height: '36px' }} />;
    case 'cad':
    case 'dwg':
    case 'dxf':
    case 'dwf':
    case 'pat':
      return <CadFileIcon style={{ height: '36px' }} />;
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
      return <ZipFileIcon style={{ height: '36px' }} />;
    case 'mpp':
    case 'mpx':
    case 'xer':
      return <GantFileIcon style={{ height: '36px' }} />;
    case 'mdb':
    case 'accdb':
    case 'sql':
      return <DbFileIcon style={{ height: '36px' }} />;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mp3':
    case 'wav':
    case 'aac':
    case 'flac':
      return <MovieFileIcon style={{ height: '36px' }} />;
    default:
      return <MiscFileIcon style={{ height: '36px' }} />;
  }
};

function PreviewButton({ onClick }: { onClick?: () => void }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="white"
      color="primary"
      size="xs"
      onClick={onClick}
      leftIcon={<IconEye size={16} />}
    >
      {t('preview')}
    </Button>
  );
}
