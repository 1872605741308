import React, { useCallback } from 'react';

import { Group, Stack, Text } from '@mantine/core';

import type CheckboxCard from './CheckboxCard';
import { CheckboxCardContextProvider } from './CheckboxCard.provider';

type CheckboxCardGroupProps = {
  children: React.ReactElement<typeof CheckboxCard>[];
  value?: string[];
  onChange: (value: string[]) => void;
  error?: string;
  w?: string | number;
};

export default function CheckboxCardGroup({
  children,
  value = [],
  onChange,
  error,
  w = '100%',
}: CheckboxCardGroupProps) {
  const handleCheckboxChange = useCallback(
    (newValue: string, checked: boolean) => {
      let newValues = [...value];
      if (checked && !newValues.includes(newValue)) {
        onChange([...value, newValue]);
      } else if (!checked && newValues.includes(newValue)) {
        newValues = newValues.filter(v => v !== newValue);
        onChange(newValues);
      }
    },
    [onChange, value],
  );

  return (
    <CheckboxCardContextProvider
      value={{
        isChecked: newValue => value.includes(newValue),
        isError: !!error,
        onCheckboxChange: handleCheckboxChange,
      }}
    >
      <Stack spacing="xxs" w={w}>
        <Group grow={true} align="stretch">
          {children}
        </Group>
        {error && (
          <Text variant="sm" fw={400} c="red.9">
            {error}
          </Text>
        )}
      </Stack>
    </CheckboxCardContextProvider>
  );
}
