import type { ProposalVersion } from '../../../../shared/entities/BidResponse';
import { CriteriaExtractionStatus } from '../../../../shared/entities/BidResponse';
import { DoneVersionCard } from './DoneVersionCard';
import { PendingVersionCard } from './PendingVersionCard';

type VersionsCardProps = {
  selectedProposalVersionId: number | null;
  extractionStatus: CriteriaExtractionStatus;
  bidResponseId: number;
  proposalVersions?: ProposalVersion[];
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

export const VersionsCard = ({
  selectedProposalVersionId,
  extractionStatus,
  proposalVersions,
  bidResponseId,
  setSelectedProposalVersion,
}: VersionsCardProps) => {
  switch (extractionStatus) {
    case CriteriaExtractionStatus.PENDING:
    case CriteriaExtractionStatus.RUNNING:
      return <PendingVersionCard />;
    case CriteriaExtractionStatus.DONE:
      return (
        <DoneVersionCard
          selectedProposalVersionId={selectedProposalVersionId}
          proposalVersions={proposalVersions}
          bidResponseId={bidResponseId}
          setSelectedProposalVersion={setSelectedProposalVersion}
        />
      );
    case CriteriaExtractionStatus.FAILED:
      throw new Error('Failed to extract criteria');
    default:
      throw new Error('Unknown extraction status');
  }
};
