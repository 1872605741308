import { useState } from 'react';

import { ActionIcon, Text, Tooltip } from '@mantine/core';

import type { AiRelevanceFeedbackStatus } from '../../../shared/entities/InstantAnalysisAnswer';

type IconType = React.ElementType<{ size: number; color: string }>;

type AiRelevanceFeedbackButtonProps = {
  feedback: AiRelevanceFeedbackStatus | null;
  handleFeedback: (buttonType: AiRelevanceFeedbackStatus) => void;
  buttonType: AiRelevanceFeedbackStatus;
  Icon: IconType;
  HoverIcon: IconType;
  colorConfig: {
    unselected: string;
    unselectedHover: string;
    selected: string;
    selectedHover: string;
    otherSelected: string;
  };
  tooltipConfig: {
    unselected: string;
    selected: string;
  };
};

export const AiRelevanceFeedbackButton = ({
  feedback,
  handleFeedback,
  buttonType,
  Icon,
  HoverIcon,
  colorConfig,
  tooltipConfig,
}: AiRelevanceFeedbackButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = feedback === buttonType;
  const tooltipText = isSelected
    ? tooltipConfig.selected
    : tooltipConfig.unselected;
  const getIconColor = () => {
    if (isSelected) {
      return isHovered ? colorConfig.selectedHover : colorConfig.selected;
    }
    if (feedback === null) {
      return isHovered ? colorConfig.unselectedHover : colorConfig.unselected;
    }
    return colorConfig.otherSelected;
  };
  const renderIcon = () => {
    const IconComponent = isHovered && isSelected ? HoverIcon : Icon;
    return <IconComponent size={16} color={getIconColor()} />;
  };
  return (
    <ActionIcon
      variant="transparent"
      onClick={() => handleFeedback(buttonType)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip
        multiline
        color="dark.9"
        position="bottom"
        radius="md"
        openDelay={700}
        w={isSelected ? '60px' : '196px'}
        label={
          <Text variant="xs" ta="center" fw={600}>
            {tooltipText}
          </Text>
        }
      >
        {renderIcon()}
      </Tooltip>
    </ActionIcon>
  );
};
