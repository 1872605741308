import { useState } from 'react';

import { Stack, Text } from '@mantine/core';

import type { QuestionsSet } from '../../../entities/QuestionsSet';
import { CheckboxQuestionsSet } from './CheckboxQuestionsSet';

type CheckboxQuestionsSetsGroupProps = {
  questionsSets: QuestionsSet[];
  value?: number[];
  onChange: (value: number[]) => void;
  error?: string;
  deleteQuestionsSet: (questionsSetId: number) => void;
  updateQuestionsSet: (questionsSet: QuestionsSet) => void;
};

export default function CheckboxQuestionsSetsGroup({
  questionsSets,
  value,
  onChange,
  error,
  deleteQuestionsSet,
  updateQuestionsSet,
}: CheckboxQuestionsSetsGroupProps) {
  const [questionsSetsIds, setQuestionsSetsIds] = useState<number[]>(
    value || [],
  );

  const handleCheckboxChange = (questionsSetId: number, checked: boolean) => {
    let updatedQuestionsSetsIds: number[];
    if (checked) {
      updatedQuestionsSetsIds = [...questionsSetsIds, questionsSetId];
    } else {
      updatedQuestionsSetsIds = questionsSetsIds.filter(
        id => id !== questionsSetId,
      );
    }
    setQuestionsSetsIds(updatedQuestionsSetsIds);
    onChange(updatedQuestionsSetsIds);
  };

  const isChecked = (questionsSetId: number) => {
    return questionsSetsIds.includes(questionsSetId);
  };

  return (
    <Stack spacing="04">
      {questionsSets.map((questionsSet, i) => (
        <CheckboxQuestionsSet
          key={i}
          value={isChecked(questionsSet.id)}
          questionsSet={questionsSet}
          switchQuestionsSet={handleCheckboxChange}
          deleteQuestionsSet={questionsSetId => {
            deleteQuestionsSet(questionsSetId);
            setQuestionsSetsIds(prev =>
              prev.filter(id => id !== questionsSetId),
            );
          }}
          updateQuestionsSet={updateQuestionsSet}
        />
      ))}
      {error && (
        <Text variant="sm" fw={400} c="red.9">
          {error}
        </Text>
      )}
    </Stack>
  );
}
