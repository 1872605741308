import { useSuspenseQuery } from '@tanstack/react-query';

import { getBidResponse } from '../../../shared/api/magellan/bidResponses';
import { queryClient } from '../../../shared/infra/queryClient';

export function useBidRespone(id: number) {
  const queryKey = [getBidResponse.name, id];
  const queryFn = async () => getBidResponse(id);
  const { data: bidResponse } = useSuspenseQuery({
    queryKey,
    queryFn,
    refetchInterval: query => {
      if (query.state.data?.extractionStatus !== 'DONE') {
        return 5000; // 5 seconds in milliseconds
      }
      return false; // stop polling when status is "DONE"
    },
  });

  const refetchBidResponse = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return { bidResponse, refetchBidResponse };
}
