import { useSuspenseQuery } from '@tanstack/react-query';

import { getContactsForBuyer } from '../../../shared/api/magellan/buyer';

export function useBuyerContacts(buyerId: number) {
  const { data: contacts } = useSuspenseQuery({
    queryKey: [getContactsForBuyer.name, buyerId],
    queryFn: () => getContactsForBuyer(buyerId),
    staleTime: 1000 * 60 * 60 * 4, // 4 hours
  });

  return contacts;
}
