import posthog from 'posthog-js';

// below code is from Posthog documentation, to allow tracking pageviews
// with react router
// see more : https://posthog.com/tutorials/single-page-app-pageviews
if (typeof window !== 'undefined') {
  posthog.init('phc_Jkjmllnfn9sefiOnr8pvgDuekk8QJ5qVbmU0V8BXSc5', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false, // // We'll capture pageviews manually
  });
}

export { posthog as posthogClient };
