import { useState } from 'react';

import { Menu, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';

import {
  IconCopy,
  IconSettings,
  IconStar,
  IconStarOff,
  IconTrash,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../../../shared/components/UI/Loader/Loader';

import { useStreamsContext } from '../../contexts/Streams.provider';
import { useFavorites } from '../forms/hooks/useFavorites.hook';
import { useStreamsActions } from '../forms/hooks/useStreamsActions.hook';

type SideBarStreamMenuProps = {
  isParentHovered: boolean;
  streamId: number;
};

export const SidebarStreamSettingsMenu = ({
  isParentHovered,
  streamId,
}: SideBarStreamMenuProps) => {
  const { deleteStream, duplicateStream } = useStreamsActions();
  const { isFavorite, toggleFavorite } = useFavorites();
  const [opened, setOpened] = useState(false);
  const streamsContext = useStreamsContext();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { t } = useTranslation();

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const openModal = () =>
    modals.openContextModal({
      modal: 'streamDelete',
      innerProps: { onDelete: () => deleteStream(streamId) },
    });

  const handleEditClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    navigate(`/flux/${streamId}/edit`);
  };

  const handleDuplicateClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    await duplicateStream(streamId);
  };

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    openModal();
  };

  if (!isParentHovered && !opened) {
    return null;
  }

  const addToFavoriteItem = (
    <Menu.Item
      c="gray.9"
      icon={<IconStar size={14} stroke={1.5} color={theme.colors.gray[6]} />}
      onClick={e => {
        e.stopPropagation();
        toggleFavorite(streamId);
      }}
    >
      Ajouter aux favoris
    </Menu.Item>
  );

  const removeToFavoriteItem = (
    <Menu.Item
      c="gray.9"
      icon={<IconStarOff size={14} stroke={1.5} color={theme.colors.gray[6]} />}
      onClick={e => {
        e.stopPropagation();
        toggleFavorite(streamId);
      }}
    >
      Retirer des favoris
    </Menu.Item>
  );

  return (
    <Menu
      shadow="md"
      width={200}
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      withinPortal
    >
      <Menu.Target>
        <IconSettings
          size={16}
          stroke={1.5}
          color={theme.colors.gray[6]}
          onClick={e => e.stopPropagation()}
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          c="gray.9"
          icon={
            <IconSettings size={14} stroke={1.5} color={theme.colors.gray[6]} />
          }
          onClick={handleEditClick}
        >
          Éditer le flux
        </Menu.Item>
        <Menu.Item
          c="gray.9"
          icon={
            <IconCopy size={14} stroke={1.5} color={theme.colors.gray[6]} />
          }
          onClick={handleDuplicateClick}
        >
          Dupliquer
        </Menu.Item>
        {isFavorite(streamId) ? removeToFavoriteItem : addToFavoriteItem}
        <Menu.Item
          c="red.6"
          icon={<IconTrash size={14} stroke={1.5} />}
          onClick={handleDeleteClick}
        >
          {t('delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
