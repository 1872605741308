import { ScrollArea, Stack } from '@mantine/core';

import type { DecisionLog } from '../../../../../shared/entities/DecisionLog';
import { Activity } from './Activity';

type ActivitiesListProps = {
  activities: DecisionLog[];
};

export function ActivityList({ activities }: ActivitiesListProps) {
  return (
    <ScrollArea h="100%" pb="03" sx={{ flexGrow: 1 }}>
      <Stack spacing="02" px="04" h="100%" sx={{ overflowY: 'hidden' }}>
        {activities.map((activity, index) => (
          <Activity
            key={activity.id}
            {...activity}
            withOutDivider={index === activities.length - 1}
          />
        ))}
      </Stack>
    </ScrollArea>
  );
}
