import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useStreamsContext } from '../../../contexts/Streams.provider';

export function useRedirectToFirstStream() {
  const navigate = useNavigate();
  const {
    data: {
      streams,
      sidebarContent: { favorites },
    },
  } = useStreamsContext();

  // redirecting to first stream in the list if no stream ID was provided
  useEffect(() => {
    const firstFavorite = favorites && favorites[0];
    const firstUnsectionnedStream = streams && streams[0];
    const firstStream = firstFavorite || firstUnsectionnedStream;

    if (location.pathname === `/flux` && firstStream) {
      navigate(`/flux/${firstStream.id}`, { replace: true });
    }
  }, [favorites, navigate, streams]);
}
