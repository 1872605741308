import React from 'react';

import { Stack } from '@mantine/core';

import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { EmptyPlaceholder } from '../../../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';
import { Error } from '../../../../shared/components/UI/Error/Error';
import { TabsComponents } from '../../../../shared/components/UI/Tabs/Tabs';

import { useFeatureFlags } from '../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import { FeatureFlag } from '../../../../shared/entities/FeatureFlags';
import { useStream } from '../forms/hooks/useStream.hook';
import { Content } from './Content';
import { Header, STREAM_LIST_OF_RESULTS_HEADER_HEIGHT } from './Header';

type ListOfResultsProps = {
  streamId: number;
};

type TabsValue = 'pending' | 'all';

export const ListOfResults = React.memo(({ streamId }: ListOfResultsProps) => {
  const defaultToAllTenders = useFeatureFlags(
    FeatureFlag.DISPLAY_STREAM_WITH_TAKEN_DECISIONS,
  );
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = queryParams.get('tab') as TabsValue | null;
  const defaultTab = defaultToAllTenders ? 'all' : 'pending';
  const [tabValue, setTabValue] = React.useState<TabsValue>(
    urlTabsValue || defaultTab,
  );
  const {
    stream,
    decrementPendingDecisionCount,
    incrementPendingDecisionCount,
  } = useStream(streamId);

  const Tabs = TabsComponents<TabsValue>();

  if (stream === null) {
    return (
      <EmptyPlaceholder
        title="Desolé, ce flux n'existe pas."
        subtitle="Il semble que le lien que vous avez suivi soit cassé ou que le flux ait été supprimé"
      />
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <Stack spacing={0} h="100%">
        <Header streamId={stream?.id} streamTitle={stream?.name} />
        <Tabs
          defaultTab={defaultTab}
          value={tabValue}
          onTabChange={(value: TabsValue) => setTabValue(value)}
          mah={`calc(100% - ${STREAM_LIST_OF_RESULTS_HEADER_HEIGHT}px)`}
          sx={{ zIndex: 1 }}
          h={undefined}
        >
          <Tabs.Button
            value="pending"
            label="À traiter"
            counter={stream?.pendingDecisionCount}
          />
          <Tabs.Button value="all" label="Tous" />
        </Tabs>

        <Content
          streamId={streamId}
          filters={stream?.filterSettings}
          withDecision={tabValue === 'all'}
          decrementPendingDecisionCount={decrementPendingDecisionCount}
          incrementPendingDecisionCount={incrementPendingDecisionCount}
        />
      </Stack>
    </Sentry.ErrorBoundary>
  );
});
