import { Center, Image, Stack, Text, Title } from '@mantine/core';

import EmptyThreadIllustration from '../assets/empty_threads.png';

export function EmptyThreadPlaceholder() {
  return (
    <Center
      h="100%"
      mx="03"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md, flexGrow: 2 })}
    >
      <Stack align="center" spacing="05">
        <Image
          src={EmptyThreadIllustration}
          alt="Illustration de liste de fils de commentaire vide"
          maw="116px"
          mah="116px"
        />
        <Stack spacing="02" align="center">
          <Title align="center" order={4} c="gray.9">
            Collaborez avec des commentaires
          </Title>
          <Text variant="sm" fw={400} c="gray.5" align="center">
            Écrivez votre premier commentaire
          </Text>
        </Stack>
      </Stack>
    </Center>
  );
}
