import { IconPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { Button } from '../../../../shared/components/UI/Button/Button';
import { EmptyPlaceholder } from '../../../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';

export const EmptyStreamPlaceholder = () => (
  <EmptyPlaceholder
    title="Créez votre premier flux d'opportunités"
    subtitle="Créez votre premier flux pour voir apparaître vos opportunités d'appels d'offres."
    actionsComponent={
      <Link to="/flux/create">
        <Button
          leftIcon={<IconPlus />}
          w="268px"
          radius="md"
          color="primary"
          mt={18}
          fz="14px"
          lh="34px"
          fw="600"
        >
          Nouveau Flux
        </Button>
      </Link>
    }
  />
);
