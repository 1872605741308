import { Stack } from '@mantine/core';

import { AnalysisLoader } from './AnalysisLoader';
import { VersionCardHeader } from './VersionCardHeader';

export const PendingVersionCard = () => {
  return (
    <Stack
      px="06"
      py="05"
      spacing="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      })}
    >
      <VersionCardHeader
        title="Analyse de l’appel d’offres en cours..."
        subtitle="Veuillez patienter pendant que  notre intelligence artificielle étudie le DCE, vous allez bientôt pouvoir télécharger votre mémoire technique pour l’analyser."
      />
      <AnalysisLoader />
    </Stack>
  );
};
