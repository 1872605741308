export enum TenderCategory {
  WORKS = 'WORKS',
  SERVICES = 'SERVICES',
  SUPPLIES = 'SUPPLIES',
}

export const TenderCategoryMap = new Map<TenderCategory, string>([
  [TenderCategory.WORKS, 'Travaux'],
  [TenderCategory.SERVICES, 'Services'],
  [TenderCategory.SUPPLIES, 'Fournitures'],
]);
