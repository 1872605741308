import i18next from 'i18next';
import { DateTime } from 'luxon';

export function formatDateInParisTZ(
  date: string | undefined,
  options?: {
    withTime?: boolean;
  },
) {
  if (!date) {
    return null;
  }

  const dateWithTimeFormat = i18next.t('dates.dateWithTimeFormat');
  const dateFormat = i18next.t('dates.dateFormat');
  const format = options?.withTime ? dateWithTimeFormat : dateFormat;

  const dateTime = DateTime.fromISO(date)
    .setZone('Europe/Paris')
    .toFormat(format);

  return dateTime;
}

export function formatDateAsText(
  date: string,
  dayOnly = false,
  truncateMonth = true,
) {
  return DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: truncateMonth ? 'short' : 'long',
    year: dayOnly ? undefined : 'numeric',
  });
}

export function parseFrLocaleDateStringToDate(dateString: string): Date {
  const [day, month, year] = dateString.split('/').map(Number);

  const isDateInvalid =
    isNaN(year) ||
    isNaN(month) ||
    isNaN(day) ||
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31;

  if (isDateInvalid) throw new Error('Cannot parse invalid Date');

  return new Date(year, month - 1, day);
}

export function parseDateToFrLocaleDateString(date: Date): string {
  if (isNaN(date.getTime())) {
    throw new Error('Cannot parse invalid Date');
  }

  return date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}
