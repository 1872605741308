import { useState } from 'react';

import { Group, Stack, Text } from '@mantine/core';

import { useMutation } from '@tanstack/react-query';

import { Avatar } from '../../../shared/components/UI/Avatar/Avatar';

import {
  assessInstantAnalysisAnswer,
  getAssessmentsRecap,
} from '../../../shared/api/magellan/instantAnalysis/answers';
import { useActiveUser } from '../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import { useUsers } from '../../../shared/hooks/useUsers.hook';
import { queryClient } from '../../../shared/infra/queryClient';
import { formatDateAsText } from '../../../shared/utils/dates';
import { AssessmentButton } from './AssessmentButton';
import { AssessmentDropdown } from './AssessmentDropdown';

type AssessmentContentProps = {
  answerId: number;
  assessment?: AssessmentStatus | null;
  assesedAt?: string;
  assessorId?: number;
  onAssessmentUpdate: (assessment: AssessmentStatus | null) => void;
};

export const AssessmentContent = ({
  answerId,
  assessment: initialAssessment,
  assesedAt,
  assessorId,
  onAssessmentUpdate,
}: AssessmentContentProps) => {
  const { users } = useUsers();
  const mutation = useMutation({
    mutationFn: ({
      answerId,
      newAssessment,
    }: {
      answerId: number;
      newAssessment: AssessmentStatus | null;
    }) => assessInstantAnalysisAnswer(answerId, newAssessment),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getAssessmentsRecap.name] });
    },
  });

  const [assessment, setAssessment] = useState<AssessmentStatus | null>(
    initialAssessment || null,
  );
  const { activeUser } = useActiveUser();

  const handleNewAssessment = (newAssessment: AssessmentStatus | null) => {
    setAssessment(newAssessment);
    mutation.mutate({ answerId, newAssessment });
    onAssessmentUpdate(newAssessment);
  };

  const assesedBy = users.find(user => Number(user.id) === assessorId);

  const userToDisplay = assesedBy || activeUser;

  const isDeleted = !!userToDisplay.disabledAt;
  return (
    <Stack spacing="xxs" align="flex-start">
      <Text variant="xs" fw={500} c="gray.4">
        Évaluation
      </Text>
      {assessment ? (
        <Stack spacing="xxs">
          <AssessmentDropdown
            selectedAssesment={assessment}
            onSelect={handleNewAssessment}
          />
          <Group spacing="xxs" align="flex-start" noWrap>
            <Avatar
              size="lg"
              radius="xl"
              color={userToDisplay.avatarColor}
              isDisabled={isDeleted}
            >
              {userToDisplay.firstName[0].toUpperCase()}
              {userToDisplay.lastName[0].toUpperCase()}
            </Avatar>
            <Stack spacing={0}>
              <Text
                variant="sm"
                fw={400}
                c={isDeleted ? 'gray.3' : 'gray.9'}
                truncate
                strikethrough={isDeleted}
              >
                {userToDisplay.firstName} {userToDisplay.lastName}
              </Text>
              <Text variant="sm" fw={400} c="gray.6">
                {assesedAt
                  ? formatDateAsText(assesedAt)
                  : formatDateAsText(new Date().toISOString())}
              </Text>
            </Stack>
          </Group>
        </Stack>
      ) : (
        <>
          <AssessmentButton
            assessment={AssessmentStatus.BLOCKING_POINT}
            onClick={handleNewAssessment}
          />
          <AssessmentButton
            assessment={AssessmentStatus.ATTENTION_POINT}
            onClick={handleNewAssessment}
          />
          <AssessmentButton
            assessment={AssessmentStatus.NEUTRAL_POINT}
            onClick={handleNewAssessment}
          />
          <AssessmentButton
            assessment={AssessmentStatus.POSITIVE_POINT}
            onClick={handleNewAssessment}
          />
        </>
      )}
    </Stack>
  );
};
