import { Box, Center, Image, Stack, Title } from '@mantine/core';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useTranslation } from 'react-i18next';

import { SlideInPanel } from '../../../../../shared/components/UI/SlideInPanel/SlideInPanel';

import type { Document } from '../../../../../shared/entities/Document';
import { DownloadFileButton } from './DownloadFileButton';

const SUPPORTED_OFFICE_EXTENSIONS = [
  'ppt',
  'pptx',
  'doc',
  'docx',
  'xls',
  'xlsx',
];
const SUPPORTED_IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif', 'webp'];

type DocumentPreviewPanelProps = {
  document: Document;
  isPanelOpen: boolean;
  onClose: () => void;
};

export function DocumentPreviewPanel({
  document,
  isPanelOpen,
  onClose,
}: DocumentPreviewPanelProps) {
  const extension = document.fileURL.split('.').pop()?.toLowerCase() || '';
  let fileType = 'other';

  if (extension === 'pdf') {
    fileType = 'pdf';
  } else if (SUPPORTED_OFFICE_EXTENSIONS.includes(extension)) {
    fileType = 'office';
  } else if (SUPPORTED_IMAGE_EXTENSIONS.includes(extension)) {
    fileType = 'image';
  }

  return (
    <SlideInPanel
      isOpen={isPanelOpen}
      onClose={onClose}
      title={document.prettyName}
      headerRight={
        <DownloadFileButton
          fileURL={document.fileURL}
          prettyName={document.prettyName}
        />
      }
    >
      {fileType === 'pdf' || fileType === 'office' || fileType === 'image' ? (
        <SupportedFilePreview fileType={fileType} fileURL={document.fileURL} />
      ) : (
        <NotSupportedFilePreview
          fileURL={document.fileURL}
          prettyName={document.prettyName}
        />
      )}
    </SlideInPanel>
  );
}

function SupportedFilePreview({
  fileType,
  fileURL,
}: {
  fileType: 'pdf' | 'office' | 'image';
  fileURL: string;
}) {
  return (
    <Box
      p={'04'}
      mx={'04'}
      h="100%"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      {fileType === 'pdf' ? (
        <PDFViewer fileUrl={fileURL} />
      ) : fileType === 'office' ? (
        <OfficeDocumentViewer fileUrl={fileURL} />
      ) : (
        <ImageViewer fileUrl={fileURL} />
      )}
    </Box>
  );
}

function PDFViewer({ fileUrl }: { fileUrl: string }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
      </Box>
    </Worker>
  );
}

function OfficeDocumentViewer({ fileUrl }: { fileUrl: string }) {
  const embedUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;

  return (
    <iframe
      src={embedUrl}
      width="100%"
      height="100%"
      title="Office Document Viewer"
    />
  );
}

function ImageViewer({ fileUrl }: { fileUrl: string }) {
  return (
    <Image src={fileUrl} alt="Document preview" fit="contain" height="100%" />
  );
}

function NotSupportedFilePreview({
  fileURL,
  prettyName,
}: {
  fileURL: string;
  prettyName: string;
}) {
  const { t } = useTranslation();

  return (
    <Center
      h="100%"
      mx="03"
      p="08"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md, flexGrow: 2 })}
    >
      <Stack align="center" spacing="05">
        <Title align="center" order={4} c="gray.9">
          {t('documents.noPreviewAvailable')}
        </Title>
        <DownloadFileButton fileURL={fileURL} prettyName={prettyName} />
      </Stack>
    </Center>
  );
}
