import { Box, Image, Stack, Text, Title } from '@mantine/core';

import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { Button } from '../../../shared/components/UI/Button/Button';

import { DecisionStatus } from '../../../shared/entities/Interaction';
import EmptyAnsweredIllustration from '../assets/empty_answered_illustration.svg';
import EmptyGoIllustration from '../assets/empty_go_illustration.svg';
import EmptyLossIllustration from '../assets/empty_loss_illustration.svg';
import EmptyNogoIllustration from '../assets/empty_nogo_illustration.svg';
import EmptyToAnalyseIllustration from '../assets/empty_to_analyse_illustration.svg';
import EmptyWinIllustration from '../assets/empty_win_illustration.svg';
import type { DecisionStatusTab } from '../hooks/useTabs.hook';

type EmptyDecisionListProps = {
  decisionStatus: DecisionStatusTab;
};
export const EmptyDecisionList = ({
  decisionStatus,
}: EmptyDecisionListProps) => {
  const config = placeholderConfig[decisionStatus];

  return (
    <Box
      h="100%"
      mx="05"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md })}
    >
      <Stack align="center" spacing="05" m="10%">
        <Image
          src={config.illustration}
          alt="Illustration de liste d'appels d'offre vide"
          maw="116px"
          mah="116px"
        />
        <Stack spacing="02" align="center">
          <Title align="center" order={4} c="gray.9" maw="480px">
            {config.title}
          </Title>
          <Text variant="sm" fw={400} c="gray.5" align="center" maw="80%">
            {config.subtitle}
          </Text>
        </Stack>
        {decisionStatus === DecisionStatus.TO_ANALYZE && (
          <Button
            variant="filled"
            color="primary"
            size="lg"
            rightIcon={<IconArrowRight />}
            component={Link}
            to="/flux"
          >
            Découvrir de nouvelles opportunités
          </Button>
        )}
      </Stack>
    </Box>
  );
};
type PlaceholderParam = {
  title: string;
  subtitle: string;
  illustration: string;
};
const placeholderConfig: {
  [K in DecisionStatusTab]: PlaceholderParam;
} = {
  [DecisionStatus.TO_ANALYZE]: {
    title: "Vous n'avez pas d'opportunités à analyser pour l'instant",
    subtitle:
      "Ajoutez de nouveaux appels d'offres depuis vos flux d'opportunités pour les analyser ici.",
    illustration: EmptyToAnalyseIllustration,
  },
  [DecisionStatus.GO]: {
    title: "Vous n'avez pas d'opportunités en Go",
    subtitle:
      'Analysez de nouvelles opportunités, prenez des décisions pour les retrouver ici',
    illustration: EmptyGoIllustration,
  },
  [DecisionStatus.NOGO]: {
    title: "Vous n'avez pas d'opportunités en No go",
    subtitle:
      'Analysez de nouvelles opportunités, prenez des décisions pour les retrouver ici',
    illustration: EmptyNogoIllustration,
  },
  [DecisionStatus.ANSWERED]: {
    title: "Aucun appel d'offres déposé pour l'instant",
    subtitle: `Pour voir des appels d'offres ici, marquez-les comme déposés après les avoir sélectionnés pour suivi.`,
    illustration: EmptyAnsweredIllustration,
  },
  [DecisionStatus.WIN]: {
    title: `Vous n'avez pas d'appel d'offres gagnés pour l'instant.
Ne lâchez rien !`,
    subtitle: `Pour voir des appels d'offres ici, marquez-les comme déposés après les avoir sélectionnés pour suivi.`,
    illustration: EmptyWinIllustration,
  },
  [DecisionStatus.LOSS]: {
    title: `Vous n'avez pas d'appel d'offres perdu pour l'instant.
Beau travail !`,
    subtitle: `N'oubliez pas de notifier les résultats depuis la page de chaque opportunité pour bénéficier d'un suivi détaillé.`,
    illustration: EmptyLossIllustration,
  },
};
