import { useDeferredValue, useEffect, useState } from 'react';

import { Box, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';

import { IconFilter } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import { Header } from '../../features/pipeline/components/Header';
import { DecisionTables } from '../../features/pipeline/components/decisionTables/components/DecisionTables';
import { PipelineFilters } from '../../features/pipeline/components/decisionTables/components/PipelineFilters';
import { RenewalDecisionTables } from '../../features/pipeline/components/decisionTables/components/RenewalDecisionTables';
import type { TableColumnWithFilter } from '../../features/pipeline/components/decisionTables/utils/columnDefinitions';
import { useDecisionCount } from '../../features/pipeline/hooks/useDecisionCount.hook';
import { useTabsStatus } from '../../features/pipeline/hooks/useTabs.hook';
import { useTenderDecisionList } from '../../features/pipeline/hooks/useTenderDecisionsList.hook';
import { useTenderDecisionRenewalList } from '../../features/pipeline/hooks/useTenderDecisionsRenewalsList.hook';
import type { Interaction } from '../../shared/entities/Interaction';
import { DecisionRenewalStatus } from '../../shared/entities/Interaction';
import type Tender from '../../shared/entities/Tender';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';

export type TableFilter = {
  id: TableColumnWithFilter;
  value: string[];
};

export type TenderWithListData = Omit<Tender, 'interaction'> & {
  interaction?: Omit<Interaction, 'dceRequestStatus'>;
  mounted?: boolean;
  transitionStyle?: 'slide-left' | 'slide-right';
  analyzableData: {
    isAnalyzable: boolean;
    error?: 'NO_DOCUMENTS' | 'NO_SUPPORTED_DOCUMENTS';
  };
  numberOfComments: number;
};

type TabsValue = 'opportunity' | 'renewal';

export default function PipelinePage() {
  useSetPageTitle('Pipeline');
  const { activeTabStatus, onTabChange } = useTabsStatus();
  const { decisionCounts, updateDecisionCounts } = useDecisionCount();
  const { renewalTenders, isRenewalLoading } = useTenderDecisionRenewalList(
    DecisionRenewalStatus.TO_ANALYZE,
  );
  const { tenders, isLoading, onTenderDecision, onNoteChange } =
    useTenderDecisionList(activeTabStatus, updateDecisionCounts);

  const [rowCount, setRowCount] = useState<number | undefined>(tenders.length);
  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [globalFilter, setGlobalFilters] = useState<string | undefined>();
  // defering filters to decouple rendering of the filters from the table that can take a few hundreds of ms to render
  // see https://react.dev/reference/react/useDeferredValue#deferring-re-rendering-for-a-part-of-the-ui
  const deferredFilters = useDeferredValue(filters);
  const defferedGlobalFilters = useDeferredValue(globalFilter);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = queryParams.get('tab') as TabsValue | null;
  const defaultTab = 'opportunity';
  const [tabValue, setTabValue] = useState<TabsValue>(
    urlTabsValue || defaultTab,
  );
  const Tabs = TabsComponents<TabsValue>();

  useEffect(() => {
    if (tabValue === 'renewal') {
      navigate('/pipeline?tab=renewal');
    }
  }, [navigate, tabValue]);

  const handleRemoveFilter = (columnId: TableColumnWithFilter) => {
    setFilters(prev => {
      const newFilters = prev.filter(filter => filter.id !== columnId);
      return newFilters;
    });
  };

  const updateFiltersValue = (
    columnId: TableColumnWithFilter,
    value: string[],
  ) => {
    setFilters(prev => {
      const columnFilter = prev.find(filter => filter.id === columnId);
      if (!columnFilter) {
        return [...prev, { id: columnId, value }];
      }
      const newFilters = prev.map(filter => {
        if (filter.id === columnId) {
          return {
            id: filter.id,
            value: value,
          };
        }
        return filter;
      });
      // removing empty filters
      return newFilters.filter(filter => filter.value.length);
    });
  };

  const resetFilters = () => {
    setFilters([]);
  };
  const tableComponent =
    tabValue === 'opportunity' ? (
      <DecisionTables
        decisionStatus={activeTabStatus}
        initialTenders={tenders}
        onTenderDecision={onTenderDecision}
        onNoteChange={onNoteChange}
        onTableChange={setRowCount}
        filters={deferredFilters}
        globalFilter={defferedGlobalFilters}
      />
    ) : (
      <RenewalDecisionTables
        decisionRenewalStatus={DecisionRenewalStatus.TO_ANALYZE}
        initialTenders={renewalTenders}
        onNoteChange={onNoteChange}
        onTableChange={setRowCount}
        filters={deferredFilters}
        globalFilter={defferedGlobalFilters}
      />
    );

  return (
    <Stack
      w="100%"
      h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      p={0}
      spacing={0}
      sx={{
        background: 'linear-gradient(180deg, #EFF8FF -2.79%, #FFFFFF 2.09%)',
      }}
    >
      <Tabs
        py="03"
        defaultTab={defaultTab}
        value={tabValue}
        onTabChange={(value: TabsValue) => setTabValue(value)}
        sx={{ zIndex: 1 }}
        h={undefined}
      >
        <Tabs.Button value="opportunity" label="Opportunités" />
        <Tabs.Button
          value="renewal"
          label={
            <Group spacing="xs">
              <Text
                variant="sm"
                fw={tabValue === 'renewal' ? '500' : '400'}
                c={tabValue === 'renewal' ? 'gray.9' : 'gray.6'}
              >
                Renouvellements
              </Text>
              <Text
                variant="sm"
                fw={600}
                c="primary.8"
                px="02"
                sx={theme => ({
                  background: theme.colors.primary[1],
                  borderRadius: theme.radius.lg,
                })}
              >
                Beta
              </Text>
            </Group>
          }
        />
      </Tabs>
      {tabValue === 'opportunity' && (
        <Header
          decisionCounts={decisionCounts}
          activeTabStatus={activeTabStatus}
          onTabChange={onTabChange}
        />
      )}
      <PipelineFilters
        rowCount={rowCount}
        filters={filters}
        onFilterChange={updateFiltersValue}
        onGlobalFilterChange={setGlobalFilters}
        handleRemoveFilter={handleRemoveFilter}
        resetFilters={resetFilters}
        isRenewal={tabValue === 'renewal'}
      />
      {isLoading || isRenewalLoading ? <DecisionListLoader /> : tableComponent}
    </Stack>
  );
}

type DecisionListLoaderProps = {
  withHeader?: boolean;
};

export const DecisionListLoader = ({
  withHeader = false,
}: DecisionListLoaderProps) => {
  const itemSkeleton = (
    <Group
      noWrap
      sx={theme => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Skeleton circle width={32} height={32} />
      <Stack w="100%" h="09" spacing="02" justify="center" m={0}>
        <Skeleton height={10} width={200} radius="sm" />
        <Skeleton height={10} width={100} radius="sm" />
      </Stack>
    </Group>
  );

  const filterBarSkeleton = (
    <Group spacing="02" mb="08">
      <Skeleton height={20} width={110} radius="sm" />
      <Divider orientation="vertical" h="04" my="auto" />
      <Group spacing="01" c="gray.6">
        <IconFilter size={20} />
        <Text variant="sm" fw="400" c="gray.6">
          Filtrer
        </Text>
      </Group>
      <Skeleton height={20} width={110} radius="sm" />
      <Skeleton height={20} width={136} radius="sm" />
      <Skeleton height={20} width={152} radius="sm" />
    </Group>
  );
  return (
    <Stack spacing={0} w="100%" h="100%">
      {withHeader && (
        <>
          <Header />
          <Box px="05" mt="02">
            {filterBarSkeleton}
          </Box>
        </>
      )}
      <Stack px="05" w="100%" spacing={0}>
        {itemSkeleton}
        {itemSkeleton}
        {itemSkeleton}
        {itemSkeleton}
        {itemSkeleton}
      </Stack>
    </Stack>
  );
};
