import { Button, Group, Stack, Text, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

import { DecisionStatus } from '../../entities/Interaction';

type NoGoReasonModalProps = {
  onDecision: (status: DecisionStatus, reason?: string) => void;
};

export const NoGoReasonModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<NoGoReasonModalProps>) => {
  const { onDecision } = innerProps;
  const form = useForm({
    initialValues: {
      reason: '',
    },
    validate: {
      reason: (value: string) =>
        value.trim().length > 0 ? null : 'Champ requis',
    },
  });

  return (
    <form>
      <Stack py={16} px={21} spacing={2}>
        <Title order={5} mb="xs">
          Quelles sont les raisons de votre No go ?
        </Title>
        <Text variant="sm" fw="400" c="gray.6">
          Analyser ces causes de No go vous permet d'identifier les facteurs
          limitant votre participation aux appels d'offres publics
        </Text>
        <Textarea {...form.getInputProps('reason')} />
        <Group position="right" mt="lg">
          <Button
            id="buttonSkip"
            variant="subtle"
            c="primary.9"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            Annuler
          </Button>
          <Button
            id="buttonSend"
            maw="25%"
            miw="200px"
            radius="md"
            size="md"
            color="primary.9"
            type="submit"
            onClick={async e => {
              e.preventDefault();
              if (!form.validate().hasErrors) {
                await onDecision(DecisionStatus.NOGO, form.values.reason);
                context.closeModal(id);
              }
            }}
          >
            Envoyer
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
