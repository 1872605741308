import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { DecisionStatus } from '../../../shared/entities/Interaction';

type TabValues = 'interested' | 'go' | 'nogo' | 'answered' | 'won' | 'lost';

export type DecisionStatusTab = Exclude<
  DecisionStatus,
  DecisionStatus.PENDING | DecisionStatus.REJECTED
>;

type PageParams = {
  tabParam?: TabValues;
};

export function useTabsStatus() {
  const navigate = useNavigate();
  const { tabParam } = useParams<PageParams>() as PageParams;
  const [activeTabStatus, setActiveTabStatus] = useState<DecisionStatusTab>(
    tabParam ? tabValueToStatus(tabParam) : DecisionStatus.TO_ANALYZE,
  );

  const onTabChange = (status: DecisionStatusTab) => {
    navigate(`/pipeline/${statusToTabValue(status)}`);
  };

  useEffect(() => {
    if (tabParam) {
      setActiveTabStatus(tabValueToStatus(tabParam));
    }
  }, [tabParam]);

  return { activeTabStatus, onTabChange };
}

const statusToTabValue = (status: DecisionStatusTab) => {
  switch (status) {
    case DecisionStatus.TO_ANALYZE:
      return 'interested';
    case DecisionStatus.GO:
      return 'go';
    case DecisionStatus.NOGO:
      return 'nogo';
    case DecisionStatus.ANSWERED:
      return 'answered';
    case DecisionStatus.WIN:
      return 'won';
    case DecisionStatus.LOSS:
      return 'lost';
    default:
      throw new Error(`Invalid status: ${status}`);
  }
};

const tabValueToStatus = (tabValue: TabValues) => {
  switch (tabValue) {
    case 'interested':
      return DecisionStatus.TO_ANALYZE;
    case 'go':
      return DecisionStatus.GO;
    case 'nogo':
      return DecisionStatus.NOGO;
    case 'answered':
      return DecisionStatus.ANSWERED;
    case 'won':
      return DecisionStatus.WIN;
    case 'lost':
      return DecisionStatus.LOSS;
    default:
      throw new Error(`Invalid tab value: ${tabValue}`);
  }
};
