import { ActionIcon, Group, Text, ThemeIcon } from '@mantine/core';

import { IconX } from '@tabler/icons-react';

import type { BuyerTableFilter } from '../../TendersTabContent';
import type { BuyerTableFilterIds, FilterConfig } from './BuyerTenderFilters';

type BuyerTableFilterTagsProps = {
  filters: BuyerTableFilter[];
  filterConfig: Record<BuyerTableFilterIds, FilterConfig>;
  handleRemoveFilter: (filterId: BuyerTableFilterIds) => void;
};

export function BuyerTableFilterTags({
  filters,
  filterConfig,
  handleRemoveFilter,
}: BuyerTableFilterTagsProps) {
  const tags = filters.map(filter => {
    const filterId = filter.id;
    const config = filterConfig[filterId];
    const icon = config.icon;
    const filterLabel = config.filterLabel;
    const valueLabels = filter.value.map(value => {
      const option = config.options.find(option => option.value === value);
      return option?.valueLabel || value;
    });
    return { filterId, icon, filterLabel, valueLabels };
  });

  return (
    <Group>
      {tags.map(tag => (
        <FilterTag
          key={`${tag.filterId}-${tag.valueLabels[0]}`}
          icon={tag.icon}
          filterLabel={tag.filterLabel}
          valueLabels={tag.valueLabels}
          onClick={() => handleRemoveFilter(tag.filterId)}
          filterId={tag.filterId}
        />
      ))}
    </Group>
  );
}

type FilterTagProps = {
  filterId: BuyerTableFilterIds;
  icon: React.ReactElement;
  filterLabel: string;
  valueLabels: string[];
  onClick: (value: string) => void;
};

const FilterTag = ({
  filterId,
  icon,
  filterLabel,
  valueLabels,
  onClick: removeFilter,
}: FilterTagProps) => {
  return (
    <Group
      noWrap
      spacing="01"
      px="02"
      py="00"
      maw={400}
      sx={theme => ({
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.primary[1],
      })}
    >
      <ThemeIcon variant="light" size="sm" bg="primary.1">
        {icon}
      </ThemeIcon>
      <Text variant="sm" fw="500">
        {filterLabel}
      </Text>
      <Text variant="sm" fw="400" truncate>
        {valueLabels.join(', ')}
      </Text>
      <ActionIcon
        variant="transparent"
        size={16}
        onClick={() => removeFilter(filterId)}
      >
        <IconX size={16} />
      </ActionIcon>
    </Group>
  );
};
