import { ScrollArea } from '@mantine/core';

import { useNoticeContext } from '../../../notice/contexts/Notice.provider';
import { Notes } from '../notes/Notes';

export function NoteTab() {
  const { tender } = useNoticeContext();
  return (
    <ScrollArea px="04" sx={{ flexGrow: 1 }} pos="relative">
      <Notes tenderId={tender.id} initialNote={tender.interaction?.note} />
    </ScrollArea>
  );
}
