import { Text, useMantineTheme } from '@mantine/core';

export const HeaderRow = () => {
  const theme = useMantineTheme();
  const thStyle = {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
    background: 'white',
  };

  return (
    <thead>
      <tr>
        <th style={thStyle}>
          <Text variant="xs" fw={500} c="gray.6" pl="01">
            #
          </Text>
        </th>
        <th style={thStyle}>
          <Text variant="xs" fw={500} c="gray.6">
            Exigences de l'acheteur
          </Text>
        </th>
        <th style={thStyle}>
          <Text variant="xs" fw={500} c="gray.6">
            Élements de la réponse
          </Text>
        </th>
      </tr>
    </thead>
  );
};
