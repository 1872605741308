import { Suspense } from 'react';

import * as Sentry from '@sentry/react';

import { Error } from '../../../shared/components/UI/Error/Error';
import { Loader } from '../../../shared/components/UI/Loader/Loader';

import type { NoticeSidePanelTabValues } from './Header';
import { ActivitiesTab } from './tabs/ActivitiesTab';
import { CommentsTab } from './tabs/CommentsTab';
import { NoteTab } from './tabs/NoteTab';

type ContentProps = {
  activeTab: NoticeSidePanelTabValues | null;
};

export function Content({ activeTab }: ContentProps) {
  const getContent = (activeTab: NoticeSidePanelTabValues) => {
    switch (activeTab) {
      case 'note':
        return <NoteTab />;
      case 'comments':
        return <CommentsTab />;
      case 'activity':
        return <ActivitiesTab />;
      default:
        return null;
    }
  };

  if (!activeTab) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <Suspense fallback={<Loader />}>{getContent(activeTab)}</Suspense>
    </Sentry.ErrorBoundary>
  );
}
