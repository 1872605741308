import type { Cpv } from '../../../entities/Cpv';
import { magellanClient } from '../../../infra/axios';

export async function getCpvByCode(code: string): Promise<Cpv> {
  const response = await magellanClient.get<Cpv>(`/cpv/${code}`);
  return response.data;
}

export async function searchCPV(search: string): Promise<Cpv[]> {
  const response = await magellanClient.get<Cpv[]>(
    `/cpv?search=${search}&take=100`,
  );
  return response.data;
}
