import { Stack } from '@mantine/core';

import { useNoticeContext } from '../../../notice/contexts/Notice.provider';
import { EmptyThreadPlaceholder } from '../comments/components/EmptyThreadPlaceholder';
import { NewThreadInput } from '../comments/components/NewThreadInput';
import { ThreadList } from '../comments/components/ThreadList';
import { useThreads } from '../comments/hooks/useThreads.hook';

export function CommentsTab() {
  const { tender } = useNoticeContext();
  const { threads } = useThreads(tender.id);

  return (
    <Stack spacing={0} h="100%" sx={{ overflowY: 'hidden' }}>
      {threads.length ? (
        <ThreadList threads={threads} />
      ) : (
        <EmptyThreadPlaceholder />
      )}
      <NewThreadInput />
    </Stack>
  );
}
