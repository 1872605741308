import {
  upsertInteractionWithDecisionStatus,
  upsertInteractionWithRenewalDecisionStatus,
} from '../api/magellan/interaction';
import {
  upsertInteractionWithNogo,
  upsertInteractionWithResults,
} from '../api/magellan/tender';
import type { DecisionRenewalStatus } from '../entities/Interaction';
import { DecisionStatus } from '../entities/Interaction';

export type StatusType =
  | {
      type: 'DecisionStatus';
      value: DecisionStatus;
    }
  | {
      type: 'DecisionRenewalStatus';
      value: DecisionRenewalStatus;
    };

export function useUpsertDecision() {
  return (
    tenderId: number,
    status: StatusType,
    reason?: string,
    streamId?: number,
  ) => {
    if (status.type !== 'DecisionStatus')
      return upsertInteractionWithRenewalDecisionStatus(tenderId, status.value);

    switch (status.value) {
      case DecisionStatus.NOGO:
        if (!reason) {
          throw new Error('No reason provided for nogo');
        }
        return upsertInteractionWithNogo(tenderId, reason);
      case DecisionStatus.WIN:
      case DecisionStatus.LOSS:
        if (!reason) {
          throw new Error('No reason provided for a result');
        }
        return upsertInteractionWithResults(tenderId, reason, status.value);

      default:
        return upsertInteractionWithDecisionStatus(
          tenderId,
          status.value,
          streamId,
        );
    }
  };
}
