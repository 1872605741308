import type { ReactNode } from 'react';

import { useFeatureFlags } from '../contexts/FeatureFlagsAndActiveUser.provider';
import type { FeatureFlag } from '../entities/FeatureFlags';

type FeatureFlagGuardProps = {
  children: ReactNode;
  feature: FeatureFlag;
};

export function FeatureFlagGuard({ children, feature }: FeatureFlagGuardProps) {
  const isFeatureEnabled = useFeatureFlags(feature);
  if (!isFeatureEnabled) {
    return null;
  }
  return children;
}

export const withFeatureFlag = (
  children: React.ReactNode,
  featureFlag?: FeatureFlag,
) => {
  return featureFlag ? (
    <FeatureFlagGuard feature={featureFlag}>{children}</FeatureFlagGuard>
  ) : (
    children
  );
};
