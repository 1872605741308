import { magellanClient } from '../../../infra/axios';

export async function downloadAllDocumentsAsZip(
  tenderId: number,
): Promise<Blob> {
  const response = await magellanClient.get<Blob>(
    `/tenders/${tenderId}/documents/download-zip`,
    {
      responseType: 'blob',
    },
  );

  return response.data;
}
