import { useNavigate, useParams } from 'react-router-dom';

import {
  createStream,
  deleteStream,
  duplicateStream,
  getStreams,
  updateStream,
} from '../../../../../shared/api/magellan/stream';
import type { GetAllStreamsResponseDTO } from '../../../../../shared/api/magellan/stream/dto';
import { deleteStreamSection } from '../../../../../shared/api/magellan/streamSections';
import type Stream from '../../../../../shared/entities/Stream';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { useStreamsContext } from '../../../contexts/Streams.provider';

export function useStreamsActions() {
  // get url query params
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: { streams },
  } = useStreamsContext();

  const deleteStreamAction = async (streamId: number) => {
    await deleteStream(streamId);
    refetchStreams();
    if (Number(id) === streamId) {
      navigate('/');
    }
  };

  const deleteSection = async (sectionId: number) => {
    await deleteStreamSection(sectionId);
    refetchStreams();
  };

  const createStreamAction = async (stream: Stream) => {
    const createdStream = await createStream(stream);
    refetchStreams();

    return createdStream;
  };

  const updateStreamAction = async (id: string, stream: Stream) => {
    const updatedStream = await updateStream(id, stream);
    refetchStreams();

    return updatedStream;
  };

  const duplicateStreamAction = async (streamId: number) => {
    await duplicateStream(streamId);
    refetchStreams();
  };

  const getStreamCount = (streamId: number) => {
    return (
      streams?.find(stream => stream.id === streamId)?.pendingDecisionCount || 0
    );
  };

  const decrementStreamsTenderCount = (streamIds: number[]) => {
    const newStreams = streams?.map(s => {
      if (streamIds.includes(s.id)) {
        return { ...s, pendingDecisionCount: s.pendingDecisionCount - 1 };
      }
      return s;
    });

    queryClient.setQueryData(
      [getStreams.name],
      (prev: GetAllStreamsResponseDTO) => ({
        ...prev,
        streams: newStreams,
      }),
    );
  };

  const incrementStreamsTenderCount = (streamIds: number[]) => {
    const newStreams = streams?.map(s => {
      if (streamIds.includes(s.id)) {
        return { ...s, pendingDecisionCount: s.pendingDecisionCount + 1 };
      }
      return s;
    });

    queryClient.setQueryData(
      [getStreams.name],
      (prev: GetAllStreamsResponseDTO) => ({
        ...prev,
        streams: newStreams,
      }),
    );
  };

  const refetchStreams = () => {
    queryClient.invalidateQueries({ queryKey: [getStreams.name] });
  };

  return {
    createStream: createStreamAction,
    deleteStream: deleteStreamAction,
    deleteSection,
    duplicateStream: duplicateStreamAction,
    getStreamCount,
    decrementStreamsTenderCount,
    incrementStreamsTenderCount,
    refetchStreams,
    updateStream: updateStreamAction,
  };
}
