import { Box, useMantineTheme } from '@mantine/core';

import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';

type AssessmentBadgeProps = {
  assessment: AssessmentStatus | null;
  isSelected?: boolean;
};

export function AssessmentBadge({
  assessment,
  isSelected = false,
}: AssessmentBadgeProps) {
  const theme = useMantineTheme();
  let color: { center: string; border: string };
  const isAssessed = assessment && assessment !== AssessmentStatus.UNASSESSED;

  switch (assessment) {
    case AssessmentStatus.BLOCKING_POINT:
      color = {
        center: theme.colors.red[4],
        border: theme.colors.red[4],
      };
      break;
    case AssessmentStatus.ATTENTION_POINT:
      color = {
        center: theme.colors.yellow[4],
        border: theme.colors.orange[2],
      };
      break;
    case AssessmentStatus.POSITIVE_POINT:
      color = {
        center: theme.colors.teal[5],
        border: theme.colors.green[3],
      };
      break;
    case AssessmentStatus.NEUTRAL_POINT:
      color = {
        center: theme.colors.indigo[5],
        border: theme.colors.indigo[2],
      };
      break;
    default:
      color = {
        center: theme.colors.gray[2],
        border: theme.colors.gray[4],
      };
      break;
  }
  return (
    <Box
      sx={{
        borderRadius: 100,
        borderColor: color.border,
        borderWidth: isAssessed ? 2 : 1,
        borderStyle: isAssessed ? 'solid' : 'dotted',
        width: 16,
        height: 16,
      }}
    >
      {isSelected && (
        <Box
          sx={{
            borderRadius: 100,
            backgroundColor: color.center,
            width: 8,
            height: 8,
            marginTop: isAssessed ? 2 : 3,
            marginLeft: isAssessed ? 2 : 3,
          }}
        />
      )}
    </Box>
  );
}
