import { forwardRef, useEffect, useState } from 'react';

import { Accordion, Group, Image, Text } from '@mantine/core';

import IAIConAlertCircle from '../../../shared/assets/ai_icons/alert_circle.svg';
import type { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import type {
  AiRelevanceFeedbackStatus,
  Answer,
} from '../../../shared/entities/InstantAnalysisAnswer';
import { LoaderComponent } from './AnswersCard';
import { AssessmentBadge } from './AssessmentBadge';
import { AnswerElement } from './answers/AnswerElement';

type AnswerAccordionProps = {
  answer: Answer;
  onNewAnswer: (newAnswer: Answer) => void;
  onNewAssessment: (
    newAssessment: AssessmentStatus | null,
    answerId: number,
  ) => void;
  onNewAiRelevanceFeedback: (
    newAiRelevanceFeedback: AiRelevanceFeedbackStatus | null,
    answerId: number,
  ) => void;
};

export const AnswerAccordion = forwardRef<HTMLDivElement, AnswerAccordionProps>(
  (
    {
      answer,
      onNewAnswer,
      onNewAssessment,
      onNewAiRelevanceFeedback,
    }: AnswerAccordionProps,
    ref,
  ) => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = Number(searchParams.get('scrollTo'));

    const defaultValue =
      answer.assessment && scrollTo !== answer.id ? null : `${answer.id}`;

    const [accordionValue, setAccordionValue] = useState<string | null>(
      defaultValue,
    );

    const handleNewAssessment = (newAssessment: AssessmentStatus | null) => {
      setAccordionValue(null);
      onNewAssessment(newAssessment, answer.id);
    };

    const handleNewAiRelevanceFeedback = (
      newAiRelevanceFeedback: AiRelevanceFeedbackStatus | null,
    ) => {
      onNewAiRelevanceFeedback(newAiRelevanceFeedback, answer.id);
    };

    useEffect(() => {
      if (scrollTo === answer.id) {
        setAccordionValue(`${answer.id}`);
      }
    }, [answer.id, scrollTo]);

    const isLoading =
      answer.instantAnalysisStatus === 'PENDING' ||
      answer.instantAnalysisStatus === 'RUNNING';

    return (
      <Accordion
        chevronPosition="left"
        value={accordionValue}
        onChange={setAccordionValue}
      >
        <Accordion.Item value={`${answer.id}`} sx={{ border: 'none' }}>
          <Accordion.Control>
            <Group ref={ref} noWrap spacing="xxs" position="left">
              <AssessmentBadge
                assessment={answer.assessment || null}
                isSelected
              />
              {!accordionValue && !answer.llmAnswer && !answer.userAnswer && (
                <Image src={IAIConAlertCircle} mah={16} maw={16} />
              )}
              <Text variant="sm" fw={600} c="gray.8">
                {answer.userQuestion}
              </Text>
              {isLoading && <LoaderComponent />}
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            {answer.instantAnalysisStatus === 'DONE' && (
              <AnswerElement
                answer={answer}
                updateAnswer={onNewAnswer}
                setAssessment={handleNewAssessment}
                setAiRelevanceFeedback={handleNewAiRelevanceFeedback}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  },
);
