import { Group, Stack, Text, Title } from '@mantine/core';
import type { ContextModalProps } from '@mantine/modals';

import { IconTrash } from '@tabler/icons-react';

import { Button } from '../UI/Button/Button';

type DeleteModalProps = {
  onDelete: () => void;
};

export const DeleteModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<DeleteModalProps>) => {
  const { onDelete } = innerProps;

  return (
    <Stack p="04" spacing="00">
      <Title order={5}>Confirmer la suppression</Title>
      <Text variant="sm" fw="400" c="gray.6">
        Êtes-vous sûr(e) de vouloir supprimer cet élément ? La suppression sera
        permanente et irréversible. Cette action ne pourra pas être annulée.
      </Text>
      <Group position="right" mt="04">
        <Button
          variant="subtle"
          color="dark"
          onClick={() => context.closeModal(id)}
        >
          Non, annuler
        </Button>
        <Button
          onClick={() => {
            onDelete();
            context.closeModal(id);
          }}
          leftIcon={<IconTrash size={18} />}
          size="md"
          color="red"
        >
          Oui, supprimer
        </Button>
      </Group>
    </Stack>
  );
};
