import { Group, Image, Stack } from '@mantine/core';

import {
  IconArrowRight,
  IconEye,
  IconSend,
  IconTrophy,
  IconTrophyOff,
  IconX,
} from '@tabler/icons-react';

import { DecisionStatus } from '../../../shared/entities/Interaction';
import DualPathSVG from '../assets/dual_path.svg';
import SinglePathSVG from '../assets/single_path.svg';
import { type DecisionStatusTab, useTabsStatus } from '../hooks/useTabs.hook';
import { DecisionTabButton } from './PipelineButton';

type ButtonTabsGroupProps = {
  activeTabStatus?: DecisionStatusTab;
  onTabChange?: (status: DecisionStatusTab) => void;
  decisionCounts?: {
    [K in DecisionStatus]: number;
  };
};

export const ButtonTabsGroup = ({
  decisionCounts,
  activeTabStatus,
  onTabChange,
}: ButtonTabsGroupProps) => {
  const { activeTabStatus: defaultTabStatus, onTabChange: defaultOnTabChange } =
    useTabsStatus();
  const buttonTabConfig = getButtonTabConfig(
    activeTabStatus ?? defaultTabStatus,
    onTabChange ?? defaultOnTabChange,
    decisionCounts,
  );
  return (
    <Group noWrap spacing={0} align="flex-start">
      <DecisionTabButton {...buttonTabConfig[DecisionStatus.TO_ANALYZE]} />
      <Separator type="dual" />
      <Stack maw="300px" sx={{ flexGrow: 1 }}>
        <DecisionTabButton {...buttonTabConfig[DecisionStatus.GO]} />
        <DecisionTabButton {...buttonTabConfig[DecisionStatus.NOGO]} />
      </Stack>
      <Separator type="single" />
      <DecisionTabButton {...buttonTabConfig[DecisionStatus.ANSWERED]} />
      <Separator type="dual" />
      <Stack maw="300px" sx={{ flexGrow: 1 }}>
        <DecisionTabButton {...buttonTabConfig[DecisionStatus.WIN]} />
        <DecisionTabButton {...buttonTabConfig[DecisionStatus.LOSS]} />
      </Stack>
    </Group>
  );
};

type ButtonTabConfig = {
  value: string;
  icon: JSX.Element;
  label: string;
  isActive: boolean;
  decisionCount?: number;
  onClick: () => void;
};

const getButtonTabConfig = (
  activeTabStatus: DecisionStatusTab,
  onTabChange: (status: DecisionStatusTab) => void,
  decisionCounts?: {
    [K in DecisionStatus]: number;
  },
): {
  [K in DecisionStatusTab]: ButtonTabConfig;
} => ({
  [DecisionStatus.TO_ANALYZE]: {
    value: 'interested',
    icon: <IconEye size="14" />,
    label: 'À analyser',
    isActive: activeTabStatus === DecisionStatus.TO_ANALYZE,
    decisionCount: decisionCounts?.[DecisionStatus.TO_ANALYZE],
    onClick: () => onTabChange(DecisionStatus.TO_ANALYZE),
  },
  [DecisionStatus.GO]: {
    value: 'go',
    icon: <IconArrowRight size="14" />,
    label: 'Go',
    isActive: activeTabStatus === DecisionStatus.GO,
    decisionCount: decisionCounts?.[DecisionStatus.GO],
    onClick: () => onTabChange(DecisionStatus.GO),
  },
  [DecisionStatus.NOGO]: {
    value: 'nogo',
    icon: <IconX size="14" />,
    label: 'No go',
    isActive: activeTabStatus === DecisionStatus.NOGO,
    decisionCount: decisionCounts?.[DecisionStatus.NOGO],
    onClick: () => onTabChange(DecisionStatus.NOGO),
  },
  [DecisionStatus.ANSWERED]: {
    value: 'answered',
    icon: <IconSend size="14" />,
    label: 'Déposées',
    isActive: activeTabStatus === DecisionStatus.ANSWERED,
    decisionCount: decisionCounts?.[DecisionStatus.ANSWERED],
    onClick: () => onTabChange(DecisionStatus.ANSWERED),
  },
  [DecisionStatus.WIN]: {
    value: 'won',
    icon: <IconTrophy size="14" />,
    label: 'Gagnés',
    isActive: activeTabStatus === DecisionStatus.WIN,
    decisionCount: decisionCounts?.[DecisionStatus.WIN],
    onClick: () => onTabChange(DecisionStatus.WIN),
  },
  [DecisionStatus.LOSS]: {
    value: 'lost',
    icon: <IconTrophyOff size="14" />,
    label: 'Perdus',
    isActive: activeTabStatus === DecisionStatus.LOSS,
    decisionCount: decisionCounts?.[DecisionStatus.LOSS],
    onClick: () => onTabChange(DecisionStatus.LOSS),
  },
});

type SeparatorProps = {
  type: 'single' | 'dual';
};

const Separator = ({ type }: SeparatorProps) => {
  return type === 'single' ? (
    <Image src={SinglePathSVG} alt="SinglePathSVG" maw={32} miw={32} />
  ) : (
    <Image src={DualPathSVG} alt="DualPathSVG" maw={64} miw={64} mt="03" />
  );
};
